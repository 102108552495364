import { render, staticRenderFns } from "./PhonebookTab.vue?vue&type=template&id=3a190a65&scoped=true&"
import script from "./PhonebookTab.vue?vue&type=script&lang=js&"
export * from "./PhonebookTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a190a65",
  null
  
)

export default component.exports