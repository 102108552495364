<template>
    <call-settings-button
        @click="$emit('click')"
        :label="this.$t('buttons-group.buttons-group-manage-call.mute')"
        :active-label="$t('buttons-group.buttons-group-manage-call.unmute')"
        :disabled="disabled"
        :active="active"
        icon="icon-mic"
        active-icon="icon-mic-off"
    ></call-settings-button>
</template>

<script>
import CallSettingsButton from "./CallSettingsButton.vue";
import callSettingsButtonMixin from "../../../../../mixins/softphone/callSettingsButtonMixin";

export default {
    name: "CallSettingsMuteButton",
    components: {CallSettingsButton},
    mixins: [callSettingsButtonMixin]
}
</script>

<style scoped>

</style>