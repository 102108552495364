<template>
    <base-popup
        @close="close"
        :width="width"
        :height="height"
        :mount-to="mountTo"
        :position="position"
        :blackout="blackout"
        popup-class="integrations-popup"
        title="integrations-popup.title"
        title-position="center"
        hide-default-actions
    >
        <template v-slot:headerPrepend>
            <div v-show="contacts.length > 1" class="call-contacts">
                <base-tooltip
                    v-show="existEnabledCrms()"
                    v-for="contact in contacts"
                    :key="contact.number"
                    :disabled="!isTwoUnknownContacts()"
                    :text="contact.number"
                    position="top"
                    text-align="center"
                >
                    <template v-slot:activator>
                        <div class="call-contact" :class="{'active': isActiveContact(contact.number), 'contact-speaks': isContactSpeaks(contact.number) && isTwoUnknownContacts()}" @click="setActiveContact(contact.number)">
                            <avatar :size="'medium'" :src="contact.avatar" :text="contact.name" :color="contact.color"></avatar>
                        </div>
                    </template>
                </base-tooltip>
            </div>
        </template>

        <base-tooltip
            v-if="canShowCallLogAllBtn()"
            text="integrations-popup.call-log-hint"
            position="top"
            style="width: fit-content"
        >
            <template v-slot:activator>
                <base-button
                    @click="callLogAllClickHandler()"
                    :color="'blue'"
                    :disabled="isCallLogAllBtnBlocked"
                    :text="'integrations-popup.call-log-all-btn'"
                    class="log-call-btn"
                ></base-button>
            </template>
        </base-tooltip>

        <crm-info
            @settings-click="settingsClickHandler"
            @input-note="inputNote"
            :number="activeContactNumber"
            :custom-loading="zapierContactInfoLoading"
            :show-call-log-btn="allowCallLogging"
            :callStartTimestamp="callStartTimestamps[activeContactNumber]"
            crm-info-spinner-size="big"
        >
            <template v-slot:logCallBtn="{contact, crmType}">
                <base-tooltip text="integrations-popup.call-log-hint" position="top">
                    <template v-slot:activator>
                        <base-button
                            @click="callLogClickHandler(crmType, contact.owner, contact.crm_object_id, contact.crm_object_type, contact.name)"
                            :color="'blue'"
                            :text="'integrations-popup.call-log-btn'"
                            :disabled="isCallLogBtnBlocked(contact.crm_object_id, crmType)"
                            class="log-call-btn"
                        ></base-button>
                    </template>
                </base-tooltip>
            </template>

            <template v-if="zapierContactInfoExist" v-slot:prepend>
                <div v-if="zapierContactInfoBlink" class="zapier-info-blink"></div>

                <minimized-contact-info
                    @contact-click="toggleZapierExpandedInfo"
                    :crm-type="ZAPIER_TYPE"
                    :contact-name="zapierContactInfo(activeContactNumber).name"
                    :expanded="zapierInfoExpanded"
                    :inline="false"
                ></minimized-contact-info>

                <div v-show="zapierInfoExpanded" class="zapier-info-wrapper">
                    <base-textarea
                        :value="zapierContactInfo(activeContactNumber).description"
                        :max-height="127"
                        disabled
                    ></base-textarea>
                </div>
            </template>
        </crm-info>
    </base-popup>
</template>

<script>
import Avatar from "../defaults/Avatar.vue";
import ExpandedContactInfo from "./base/ExpandedContactInfo.vue";
import CreateContact from "./base/CreateContact.vue";
import {mapActions, mapGetters} from 'vuex';
import BaseButton from "../defaults/BaseButton.vue";
import MinimizedContactInfo from "./base/MinimizedContactInfo.vue";
import CrmInfo from "./base/CrmInfo.vue";
import {existEnabledCrms, onlyZapierEnabled, isIntegrationEnabled} from "../../utils/integrations";
import BaseTooltip from "../defaults/BaseTooltip.vue";
import BasePopup from "../defaults/BasePopup.vue";
import {ZAPIER_TYPE} from "../../helpers/integrations/crms";
import BaseTextarea from "../defaults/BaseTextarea.vue";

export default {
    name: "IntegrationsPopup",
    components: {
        BaseTextarea,
        BasePopup,
        BaseTooltip, CrmInfo, MinimizedContactInfo, BaseButton, Avatar, ExpandedContactInfo, CreateContact},
    props: {
        position: {
            type: String
        },
        width: {
            type: Number
        },
        height: {
            type: [Number, String],
            default: "100%"
        },
        mountTo: {
            type: String
        },
        blackout: {
            type: Boolean
        },
        numbers: {
            type: Array,
            default: []
        },
        callStartTimestamps: {
            type: Object,
            default() {
                return {}
            }
        },
        allowCallLogging: {
            type: Boolean,
            default: true
        },
        keepCrmInfo: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contacts: [],
            activeContactNumber: '',
            contactCreateFailed: false,
            windowWidth: window.innerWidth,
            zapierInfoExpanded: true,
            zapierContactInfoLoading: false,
            zapierContactInfoInterval: null,
            zapierContactInfoBlink: false,
            ZAPIER_TYPE
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    created() {
        if (this.numbers.length) {
            this.contacts = this.getContacts(this.numbers)
            this.activeContactNumber = this.numbers[0]
        }
    },
    beforeDestroy() {
        if (this.numbers && !this.keepCrmInfo) {
            this.resetCallInfo(this.numbers)
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        clearInterval(this.zapierContactInfoInterval)
        this.resetZapierContactInfo(this.numbers)
    },
    watch: {
        numbers(numbers) {
            this.contacts = this.getContacts(numbers)
            this.activeContactNumber = numbers[0]
        },
        activeContactNumber(number) {
            if (!this.zapierEnabled) {
                return
            }

            clearInterval(this.zapierContactInfoInterval)
            if (!this.zapierContactInfoExist) {
                this.zapierContactInfoLoading = true
                this.getZapierContactInfo(number)
                    .then((response) => {
                        if (!Object.keys(response).length) {
                            this.startFetchingZapierContactInfo(number)
                        }
                    })
                    .catch(() => {})
                    .finally(() => this.zapierContactInfoLoading = false)
            }
        }
    },
    computed: {
        ...mapGetters('details', ['getContactByNumber']),
        ...mapGetters('integrations', ['callInfo', 'callLogBuffer', 'zapierContactInfo', 'zapierEnabled', 'isLoadingCallInfo']),
        ...mapGetters('softphone', ['getActiveSession']),

        zapierContactInfoExist: function () {
            return Boolean(Object.keys(this.zapierContactInfo(this.activeContactNumber)).length)
        },
        isCallLogAllBtnBlocked() {
            if (!existEnabledCrms() || onlyZapierEnabled()) {
                return true
            }

            const callInfo = this.callInfo(this.activeContactNumber)
            let contactsCount = 0
            callInfo.forEach((crm) => {
                if (!isIntegrationEnabled(crm.crm_type)) {
                    return
                }
                contactsCount += crm.contacts.length
            })
            return Boolean(contactsCount === this.callLogBuffer(this.activeContactNumber).length)
        }
    },
    methods: {
        ...mapActions('integrations', [
            'resetCallInfo',
            'addDataToCallLogBuffer',
            'resetCallLogBuffer',
            'getZapierContactInfo',
            'resetZapierContactInfo'
        ]),

        setActiveContact: function (number) {
            this.activeContactNumber = number
        },

        isActiveContact: function (number) {
            return this.activeContactNumber === number
        },

        isContactSpeaks: function (number) {
            const activeSession = this.getActiveSession
            return activeSession && activeSession.number === number
        },

        isTwoUnknownContacts: function () {
            const unknownContacts = this.contacts.filter((contact) => !contact.id)
            return unknownContacts.length === 2
        },

        getContacts: function (numbers) {
            return numbers.map((number) => {
                let contact = Object.assign({}, this.getContactByNumber(number))
                contact.number = number // set number if contact not exist in phonebook
                return contact
            })
        },

        canShowCallLogAllBtn: function () {
            const callInfo = this.callInfo(this.activeContactNumber)
            const isCallInfoExists = callInfo.length && callInfo.filter((crm) => crm.contacts.length).length
            return Boolean(isCallInfoExists && !this.isLoadingCallInfo(this.activeContactNumber))
        },

        isCallLogBtnBlocked: function (id, crmType) {
            return Boolean(this.callLogBuffer(this.activeContactNumber).find((item) => item.contactId === id)) || !isIntegrationEnabled(crmType)
        },

        close: function() {
            this.$emit('close');
        },

        callLogClickHandler: function(crmType, ownerName, contactId, contactType, contactName) {
            if (!isIntegrationEnabled(crmType)) {
                return
            }

            this.addDataToCallLogBuffer({
                number: this.activeContactNumber,
                data: {ownerName, contactType, contactId, crmType, contactName}
            })
        },

        callLogAllClickHandler: function() {
            this.resetCallLogBuffer({number: this.activeContactNumber}) // clear call log buffer to delete duplicates

            this.callInfo(this.activeContactNumber).forEach((info) => {
                if (!isIntegrationEnabled(info.crm_type)) {
                    return
                }

                info.contacts.forEach((contact) => {
                    this.addDataToCallLogBuffer({
                        number: this.activeContactNumber,
                        data: {
                            ownerName: contact.owner,
                            contactType: contact.crm_object_type,
                            contactId: contact.crm_object_id,
                            crmType: info.crm_type,
                            contactName: contact.name,
                        }
                    })
                })
            })
        },

        inputNote: function ({value, crmType, contact}) {
            if (value) {
                const callLogBufferData = this.callLogBuffer(this.activeContactNumber)
                const callLogDataExist = callLogBufferData.find((data) => data.contactId === contact.crm_object_id)

                if (!callLogDataExist) {
                    this.callLogClickHandler(crmType, contact.owner, contact.crm_object_id, contact.crm_object_type, contact.name)
                }
            } else {
                this.resetCallLogBuffer({number: this.activeContactNumber, contactId: contact.crm_object_id})
            }
        },

        settingsClickHandler: function() {
            this.close()
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        },

        toggleZapierExpandedInfo: function() {
            this.zapierInfoExpanded = !this.zapierInfoExpanded
        },

        startFetchingZapierContactInfo: function (number) {
            this.zapierContactInfoInterval = setInterval(() => {
                if (!this.zapierEnabled) {
                    clearInterval(this.zapierContactInfoInterval)
                    return
                }

                this.getZapierContactInfo(number)
                    .then((response) => {
                        if (Object.keys(response).length) {
                            clearInterval(this.zapierContactInfoInterval)
                            this.zapierContactInfoBlink = true
                        }
                    })
                    .catch(() => {
                        clearInterval(this.zapierContactInfoInterval)
                    })
            }, 10000)
        },

        existEnabledCrms,

        isIntegrationEnabled
    }
}
</script>

<style scoped>

</style>