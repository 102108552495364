<template>
    <call-controls-button
        @click="$emit('click')"
        :label="$t('buttons-group.buttons-group-incoming-second-line.hang-up-and-accept')"
        color="orange"
        icon="icon-call"
    ></call-controls-button>
</template>

<script>
import CallControlsButton from "./CallControlsButton.vue";

export default {
    name: "CallControlsHangupAndAcceptButton",
    components: {CallControlsButton}
}
</script>

<style scoped>

</style>