<template>
    <softphone-state-default>
        <template v-slot:top>
            <call-status-label animated>
                {{ $t("softphone-state-incoming.call-status") }}
            </call-status-label>
        </template>

        <template v-slot:center>
            <person :session="getPrimarySession"></person>
        </template>

        <template v-slot:call_control_button_col-1>
            <call-controls-cancel-button
                @click="hangUp(getPrimarySession.id)"
                :label="$t('softphone-state-incoming.buttons.decline')"
            ></call-controls-cancel-button>
        </template>

        <template v-slot:call_control_button_col-3>
            <call-controls-call-button
                @click="acceptCall(getPrimarySession.id)"
                :label="$t('softphone-state-incoming.buttons.accept')"
            ></call-controls-call-button>
        </template>
    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import CallControlsCallButton from "../../softphoneButtons/callControls/CallControlsCallButton.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallStatusLabel from "../../CallStatusLabel.vue";
import Person from "../../person/Person.vue";

export default {
    name: "SoftphoneStateIncoming",
    components: {
        Person,
        CallStatusLabel,
        CallControlsCancelButton,
        CallControlsCallButton,
        SoftphoneStateDefault
    },
    computed: {
        ...mapGetters('softphone', ['getPrimarySession']),
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'acceptCall'
        ])
    }
}
</script>

<style scoped>

</style>