import App from "../../models/App";

export default {
    namespaced: true,
    state: {
        apps: []
    },
    getters: {
        apps(state) {
            return state.apps
        },
        desktopApps(state) {
            return state.apps.filter((app) => app.isDesktopApp)
        },
        mobileApps(state) {
            return state.apps.filter((app) => app.isMobileApp)
        }
    },
    actions: {
        setApps: (context, apps) => {
            context.commit('setApps', apps)
        }
    },
    mutations: {
        setApps: (state, apps) => {
            state.apps = apps
        }
    }
}