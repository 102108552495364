<template>
    <div class="caller-id-select" ref="select">
        <div class="caller-id-select-value-container">
            <template v-if="value">
                <span class="caller-id-select-value-name" :title="value.name">{{ value.name }}</span>
                <span class="caller-id-select-value-num">{{ value.number }}</span>
            </template>

            <span v-else class="caller-id-select-value-anonymous">{{ $t("softphone.caller-id-anonymous") }}</span>
        </div>

        <caller-id-select-dropdown
            @select="onSelect"
            :selected-item="value"
            :options="options"
            :offset="19"
            :offsetX="dropdownOffsetX"
            :search-placeholder="$t('caller-id-select.search-placeholder-callerid')"
            :width="DROPDOWN_WIDTH"
            align="right"
            dropdown-position="bottom"
        >
            <base-button text="Change" color="white" text-button></base-button>
        </caller-id-select-dropdown>
    </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import CallerIdSelectDropdown from "./CallerIdSelectDropdown.vue";

const DROPDOWN_WIDTH = 300

export default {
    name: "CallerIdSelect",
    components: {CallerIdSelectDropdown, BaseButton},
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        options: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            selectResizeObserver: null,
            selectWidth: 0,
            DROPDOWN_WIDTH
        }
    },
    mounted() {
        this.selectResizeObserver = new ResizeObserver(() => {
            if (this.$refs.select) {
                const rect = this.$refs.select.getBoundingClientRect()

                this.selectWidth = rect.width
            }
        })

        this.$nextTick(() => {
            this.selectResizeObserver.observe(this.$refs.select)
        })
    },
    computed: {
        dropdownOffsetX() {
            // place dropdown in center of select
            return Math.ceil((DROPDOWN_WIDTH - this.selectWidth) / 2 + 12) // 12 is padding of select
        }
    },
    methods: {
        onSelect(item) {
            this.$emit("input", item)
        }
    }
}
</script>

<style scoped>

</style>