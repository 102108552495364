<template>
    <div
        :class="{'call-status-label-animated': animated}"
        :style="style"
        class="call-status-label"
    >
        <slot></slot>
    </div>
</template>

<script>
import breakpoints from "../../../plugins/breakpoints";

export default {
    name: "CallStatusLabel",
    props: {
        animated: {
            type: Boolean,
            default: false
        },
        topOffset: {
            type: Number,
            default: 12
        }
    },
    computed: {
        style() {
            return {
                marginTop: `${breakpoints.isTabletOrSmMobile ? 0 : this.topOffset}px`
            }
        }
    }
}
</script>

<style scoped>

</style>