<template>
    <base-button
        @click="$emit('click')"
        :text="text"
        :class="{'mute-ring-button-active': active}"
        icon="icon-volume_off"
        class="mute-ring-button"
    ></base-button>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";

export default {
    name: "MuteRingButton",
    components: {BaseButton},
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        text() {
            return this.active ? this.$t("softphone-state-incoming.mute-ring-btn.muted") : this.$t("softphone-state-incoming.mute-ring-btn.mute")
        }
    }
}
</script>

<style scoped>

</style>