<template>
    <button type="button" class="base-button" :disabled="disabled" :class="classes" :style="styles" @click="onClick" ref="button">
        <span v-if="icon && !loading" :class="icon" :style="iconStyles" class="icon"></span>

        <span v-if="$te(text)" class="text">{{ $t(text) }}</span>
        <span v-else class="text">{{ text }}</span>

        <base-spinner v-if="loading" :size="'small'" :color="'blue'"></base-spinner>

        <slot name="append">

        </slot>
    </button>
</template>

<script>
import BaseSpinner from "./BaseSpinner.vue";

const BUTTON_SIZE_BIG = 'big' // height 40px
const BUTTON_SIZE_DEFAULT = 'default' // height 28px
const BUTTON_SIZE_MEDIUM = 'medium' // height 26px
const BUTTON_SIZE_SMALL = 'small' // height 18px

const BUTTON_COLOR_DEFAULT = 'default' // text color - dark gray, background color - white
const BUTTON_COLOR_BLUE = 'blue' // text color - white, background color - blue
const BUTTON_COLOR_WHITE = 'white' // text color - blue, background color - white
const BUTTON_COLOR_GRAY = 'gray' // text color - gray, background color - light gray (initial background color - white)
const BUTTON_COLOR_TABLE = 'table' // text color - dark gray, background color - light gray (initial background color - gray)
const BUTTON_COLOR_DARK_GRAY = 'dark-gray' // text color - dark gray, background color - dark gray
const BUTTON_COLOR_ORANGE = 'orange' // text color - orange, background color - orange
const BUTTON_COLOR_TEXT = 'text' // text color - gray, background color - transparent
const BUTTON_COLOR_GREEN = 'green' // text color - white, background color - green
const BUTTON_COLOR_LIGHT_GREEN = 'light-green' // text color - green, background color - green
const BUTTON_COLOR_DELETE = 'delete' // text color - white, background color - red, in disabled state background color - white
const BUTTON_COLOR_HUBSPOT = 'hubspot-color'
const BUTTON_COLOR_ZENDESK = 'zendesk-color'
const BUTTON_COLOR_ZOHO = 'zoho-color'

export default {
    name: "BaseButton",
    components: {BaseSpinner},
    props: {
        size: {
            type: String,
            default: BUTTON_SIZE_DEFAULT
        },
        color: {
            type: String,
            default: BUTTON_COLOR_DEFAULT
        },
        icon: {
            type: String,
            default: ''
        },
        iconSize: {
            type: Number,
            default: 18
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        },
        radius: {
            type: String,
            default: ''
        },
        disableBorders: {
            type: Boolean,
            default: false
        },
        textOverIcon: {
            type: Boolean,
            default: false
        },
        textButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    computed: {
        classes() {
            return {
                'base-button-size-big': this.size === BUTTON_SIZE_BIG,
                'base-button-size-default': this.size === BUTTON_SIZE_DEFAULT,
                'base-button-size-medium': this.size === BUTTON_SIZE_MEDIUM,
                'base-button-size-small': this.size === BUTTON_SIZE_SMALL,

                'base-button-color-default': this.color === BUTTON_COLOR_DEFAULT,
                'base-button-color-blue': this.color === BUTTON_COLOR_BLUE,
                'base-button-color-gray': this.color === BUTTON_COLOR_GRAY,
                'base-button-color-white': this.color === BUTTON_COLOR_WHITE,
                'base-button-color-table': this.color === BUTTON_COLOR_TABLE,
                'base-button-color-dark-gray': this.color === BUTTON_COLOR_DARK_GRAY,
                'base-button-color-orange': this.color === BUTTON_COLOR_ORANGE,
                'base-button-color-text': this.color === BUTTON_COLOR_TEXT,
                'base-button-color-green': this.color === BUTTON_COLOR_GREEN,
                'base-button-color-delete': this.color === BUTTON_COLOR_DELETE,
                'base-button-color-light-green': this.color === BUTTON_COLOR_LIGHT_GREEN,
                'base-button-color-zoho': this.color === BUTTON_COLOR_ZOHO,
                'base-button-color-zendesk': this.color === BUTTON_COLOR_ZENDESK,
                'base-button-color-hubspot': this.color === BUTTON_COLOR_HUBSPOT,

                'base-button-text-and-icon': this.icon && this.text && !this.textOverIcon,
                'base-button-only-icon': this.icon && !this.text,
                'base-button-loading': this.loading,
                'base-button-disable-borders': this.disableBorders || this.textButton,
                'base-button-text-over-icon': this.icon && this.text && this.textOverIcon,
                'base-button-text-button': this.textButton
            }
        },

        styles() {
            const result = {
                borderRadius: this.radius
            }

            const hexRegex = /^#[0-9A-Fa-f]{6}$/g
            if (hexRegex.test(this.color)) {
                result.backgroundColor = this.color
            }

            return result
        },

        iconStyles() {
            const size = `${this.iconSize}px`

            return {
                fontSize: size,
                lineHeight: size
            }
        }
    },
    methods: {
        onClick() {
            this.$emit('click')
            this.$refs.button.blur()
        },
        click() {
            this.$refs.button.click()
        }
    }
}
</script>

<style scoped>

</style>