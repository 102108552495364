<template>
    <div class="top-bar-dropdown">
        <base-dropdown
            v-model="dropdownOpen"
            :width="212"
            position="bottom"
            open-by="click"
            align="right"
            content-class="top-bar-dropdown-content"
        >
            <template v-slot:activator>
                <div class="top-bar-dropdown-toggle">
                    <Avatar
                        :size="'medium'"
                        :src="user.avatar"
                        :text="user.name"
                        :color="user.color"
                        :status="userStatus"
                        :showStatus="breakpoints.width <= 1300"
                    ></Avatar>
                    <div
                        class="top-bar-dropdown-toggle-icon"
                        :class="{'icon-expand-down': !dropdownOpen, 'icon-expand-up': dropdownOpen}"
                    ></div>
                </div>
            </template>

            <base-list>
                <base-list-item v-if="breakpoints.width <= 1300">
                    <d-n-d-switcher :in-dropdown="true"></d-n-d-switcher>
                </base-list-item>

                <base-list-item
                    v-if="isElectronExist()"
                    @click="reloadPage"
                >
                    <base-list-item-icon :icon="'icon-refresh'"></base-list-item-icon>
                    <base-list-item-title>{{ $t("top-bar.reload-button") }}</base-list-item-title>
                </base-list-item>

                <base-list-item @click="unAuth">
                    <base-list-item-icon :icon="'icon-logout'"></base-list-item-icon>
                    <base-list-item-title>{{ $t("top-bar.logout-button") }}</base-list-item-title>
                </base-list-item>
            </base-list>
        </base-dropdown>
    </div>
</template>

<script>
import BaseDropdown from "../defaults/BaseDropdown.vue";
import Avatar from "../defaults/Avatar.vue";
import BaseList from "../defaults/baseList/BaseList.vue";
import BaseListItem from "../defaults/baseList/BaseListItem.vue";
import BaseTooltip from "../defaults/BaseTooltip.vue";
import BaseButton from "../defaults/BaseButton.vue";
import BaseListItemTitle from "../defaults/baseList/BaseListItemTitle.vue";
import BaseListItemIcon from "../defaults/baseList/BaseListItemIcon.vue";
import {mapActions, mapGetters} from "vuex";
import breakpoints from "../../plugins/breakpoints";
import {authLogOut} from "../../utils/auth";
import {reloadPage} from "../../utils/DOM";
import {sendToElectron, isElectronExist} from "../../utils/electron";
import DNDSwitcher from "./DNDSwitcher.vue";

export default {
    name: "TopBarDropdown",
    components: {
        DNDSwitcher,
        BaseListItemIcon,
        BaseListItemTitle,
        BaseButton,
        BaseTooltip,
        BaseListItem,
        BaseList,
        Avatar,
        BaseDropdown
    },
    data() {
        return {
            dropdownOpen: false,
            breakpoints
        }
    },
    computed: {
        ...mapGetters(["isUniversalWebphone"]),
        ...mapGetters("user", ["user", "isOnline"]),
        ...mapGetters('softphone', ['getIsRegistered']),

        userStatus() {
            if (!this.getIsRegistered) {
                return 'user-not-registered'
            }

            if (!this.isOnline) {
                return 'user-offline'
            }

            if (this.isOnline) {
                return 'user-online'
            }
        },
    },
    methods: {
        ...mapActions(['updateLogoUrl', 'updateFaviconLogoUrl']),
        ...mapActions('softphone', ['ctxSipStop']),
        ...mapActions('user', ['logOut']),

        unAuth() {
            if (this.isUniversalWebphone) {
                const logo = require('../../images/logo.svg')
                this.updateLogoUrl(logo)
                this.updateFaviconLogoUrl(logo)
            }

            this.ctxSipStop()
            this.logOut()
                .catch(() => {})
                .finally(() => {
                    authLogOut()
                })
        },
        reloadPage,
        isElectronExist
    }
}
</script>

<style scoped>

</style>