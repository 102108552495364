<template>
    <softphone-state-default
        main-container-class="softphone-state-incoming-incoming-container"
        class="softphone-state-incoming-incoming"
    >
        <div class="softphone-state-incoming-incoming-status-container">
            <call-status-label animated>
                {{ 'Incoming calls...' }}
            </call-status-label>
        </div>

        <div class="softphone-state-incoming-incoming-sessions-container">
            <div
                v-for="session in getSessions"
                :key="session.id"
                class="softphone-state-incoming-incoming-session"
            >
                <person
                    :session="session"
                    size="small"
                ></person>

                <div class="softphone-state-incoming-incoming-session-buttons">
                    <call-controls-cancel-button
                        @click="rejectIncomingCall(session.id)"
                        size="small"
                    ></call-controls-cancel-button>

                    <call-controls-call-button
                        @click="acceptIncomingCall(session.id)"
                        size="small"
                    ></call-controls-call-button>
                </div>
            </div>
        </div>
    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import CallStatusLabel from "../../CallStatusLabel.vue";
import Person from "../../person/Person.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallControlsCallButton from "../../softphoneButtons/callControls/CallControlsCallButton.vue";

export default {
    name: "SoftphoneStateIncomingIncoming",
    components: {
        CallControlsCallButton,
        CallControlsCancelButton,
        Person,
        CallStatusLabel,
        SoftphoneStateDefault
    },
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession',
            'getSessions'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'acceptCall',
            'hangUp'
        ]),

        rejectIncomingCall(sessionId) {
            this.hangUp(sessionId)
        },

        acceptIncomingCall(sessionId) {
            const rejectSessionId = this.getPrimarySession.id === sessionId ? this.getSecondSession.id : this.getPrimarySession.id

            this.acceptCall(sessionId)
            this.hangUp(rejectSessionId)
        }
    }
}
</script>

<style scoped>

</style>