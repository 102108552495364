import Api from '../../utils/api';
import {sendToElectron} from "../../utils/electron";
import {getColor} from "../../utils/colors";
import SocketIOHelper from "../../helpers/SocketIOHelper";
import i18n from "../../locales";
import {localStorageHelper} from "../../helpers/LocalStorageHelper";

const COUNTRIES_CODES = {
    "AU": "AU",
    "NZ": "NZ",
    "UK": "GB",
    "IE": "IE",
    "US": "US",
    "CA": "CA",
    "SG": "SG"
}

const getDefaultState = () => {
    return {
        isLogged: false,
        isOnline: true,
        avatar: "",
        calleridId: "",
        callerids: [],
        email: "",
        extension: "",
        languageCode: "en",
        name: "",
        crmTenantName: '',
        pbxUrl: '',
        crmServiceUrl: '',
        isIntegrationsEnabled: false,
        crmAccessToken: '',
        country: "",
        color: "",
        defaultPhonebookViewId: null,
        isTestFeaturesEnabled: false,
        isCallRecordingStorageEnabled: false,
        realtimeToken: '',
        smsNumbers: [],
        voicemails: [],
        isUnreadVoicemailMessagesExists: false,
        timezone: "",
        extensionLength: 5,
        defaultServicesPage: "",
        defaultPage: "",
        followMes: [],
        max_external_numbers: 0,
        call_confirm: false,
        ring_mode: "hunt",
        twinning_time: 15,
        ringtones: [],
        ringtoneId: null,
        defaultSmsFromNumberId: '',
        isLogOutLoading: false
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        user: (state) => {
            return state;
        },
        isOnline: (state) => {
            return state.isOnline;
        },
        isLoggedIn: (state) => {
            return state.isLogged;
        },
        languageCode: (state) => {
            return state.languageCode;
        },
        calleridId: (state) => {
            return state.calleridId;
        },
        callerIds: (state) => {
            return state.callerids;
        },
        crmTenantName: (state) => {
            return state.crmTenantName;
        },
        email: (state) => {
            return state.email
        },
        pbxUrl: (state) => {
            return state.pbxUrl
        },
        isIntegrationsEnabled: (state) => {
            return state.isIntegrationsEnabled
        },
        crmAccessToken: (state) => {
            return state.crmAccessToken
        },
        crmServiceUrl: (state) => {
            return state.crmServiceUrl
        },
        country: (state) => {
            return state.country
        },
        color: (state) => {
            return state.color
        },
        defaultPhonebookViewId: (state) => {
            return state.defaultPhonebookViewId
        },
        userNameWithExtension: (state) => {
            return `${state.extension} - ${state.name}`
        },
        isTestFeaturesEnabled: (state) => {
            return state.isTestFeaturesEnabled
        },
        userName: (state) => {
            return state.name
        },
        realtimeToken: (state) => {
            return state.realtimeToken
        },
        smsNumbers: (state) => {
            return state.smsNumbers
        },
        timezone: (state) => {
            return state.timezone
        },
        voicemails: (state) => {
            return state.voicemails
        },
        isUnreadVoicemailMessagesExists: (state) => {
            return state.isUnreadVoicemailMessagesExists
        },
        extensionLength: (state) => {
            return state.extensionLength
        },
        defaultServicesPage: (state) => {
            return state.defaultServicesPage
        },
        defaultPage: (state) => {
            return state.defaultPage
        },
        followMes: (state) => {
            return state.followMes
        },
        maxExternalNumbers: (state) => {
            return state.max_external_numbers
        },
        callConfirm: (state) => {
            return state.call_confirm
        },
        ringMode: (state) => {
            return state.ring_mode
        },
        twinningTime: (state) => {
            return state.twinning_time
        },
        ringtones: (state) => {
            return state.ringtones
        },
        ringtoneId: (state) => {
            return state.ringtoneId
        },
        extension: (state) => {
            return state.extension
        },
        defaultSmsFromNumberId: (state) => {
            return state.defaultSmsFromNumberId
        },
        isLogOutLoading: (state) => {
            return state.isLogOutLoading
        }
    },
    actions: {
        logIn: (context, {email, password, captcha, partnerId}) => {
            return new Promise((resolve, reject) => {
                Api().post('/auth/login', {email: email, password: password, captcha: captcha, partner_id: partnerId})
                    .then(response => {
                        localStorageHelper.clear()
                        context.commit('updateIsLogged', true);
                        sendToElectron("login")
                        resolve(response);
                    })
                    .catch(err => {
                        context.commit('updateIsLogged', false);
                        reject(err);
                    })
            })
        },
        logOut: (context) => {
            context.commit("updateIsLogOutLoading", true)
            return new Promise((resolve, reject) => {
                Api().post('/logout')
                    .catch(() => reject())
                    .finally(response => {
                        context.commit("updateIsLogOutLoading", false)
                        resolve(response);
                    })
            })
        },
        resetState: (context) => {
            context.commit('resetState')
        },
        changeIsLogged: (context, isLogged) => {
            context.commit('updateIsLogged', isLogged);
        },
        setUser: (context, userSettings) => {
            userSettings.color = getColor(userSettings.name)
            context.commit('setUser', userSettings)
        },
        changeCallerId: (context, callerIdId) => {
            Api().post('/update_user_callerid', {callerid_id: callerIdId})
                .then(response => {
                    localStorageHelper.setItem('calleridId', callerIdId);
                    context.commit('updateDefaultCallerId', callerIdId);
                })
                .catch(() => {})
        },
        sendResetLink: (context, {email, captcha, partnerId}) => {
            return new Promise((resolve, reject) => {
                Api().post('/auth/send_reset_link', {email: email, captcha: captcha, partner_id: partnerId})
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        resetPassword: (context) => {
            return new Promise((resolve, reject) => {
                Api().post('/reset_password', )
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        saveNewPassword: (context, {password, token, captcha, partnerId}) => {
            return new Promise((resolve, reject) => {
                Api().post('/auth/save_new_password', {password: password, token: token, captcha: captcha, partner_id: partnerId})
                    .then(response => {
                        if (response.susccess) {
                            context.dispatch('changeIsLogged', false);
                        }
                        resolve(response)
                    })
                    .catch(() => reject())
            });
        },
        editPhoto: (context, {image, blob}) => {
            if (image) {
                var formData = new FormData();
                formData.append('file', image);
                return new Promise((resolve, reject) => {
                    Api().post('/update_user_avatar', formData)
                        .then(response => {
                            context.commit('updatePhoto', blob);
                            resolve(response);
                        })
                        .catch(() => reject())
                })

            } else {
                return new Promise((resolve, reject) => {
                    Api().post('/delete_user_avatar')
                        .then(response => {
                            context.commit('updatePhoto', "");
                            resolve(response);
                        })
                        .catch(() => reject())
                })
            }

        },
        changeLanguage: (context, langCode) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_user_language', {language_code: langCode})
                    .then(response => {
                        context.commit('updateLanguage', langCode);
                        resolve(response);
                    })
                    .catch(() => reject())
            })
        },
        changeIsOnline: (context, isOnline) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_is_online', {is_online: Number(isOnline)})
                    .then(response => {
                        context.commit('updateIsOnline', isOnline)
                        resolve()
                    })
                    .catch(() => reject())
            })
        },
        setIsOnline: (context, isOnline) => {
            context.commit('updateIsOnline', isOnline)
        },
        changeDefaultPhonebookView: (context, phonebookId) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_default_phonebook_view', {phonebook_id: phonebookId === "all" ? 0 : phonebookId})
                    .then(response => {
                        context.commit('updateDefaultPhonebookView', phonebookId)
                        resolve(response)
                    })
                    .catch(() => reject())
            })
        },
        integrationsLogIn: (context, {email, password, captcha, clientId, state, redirectUri}) => {
            return new Promise((resolve, reject) => {
                Api().post('/auth/integrations_auth', {
                    email: email,
                    password: password,
                    captcha: captcha,
                    client_id: clientId,
                    state: state,
                    redirect_uri: redirectUri
                }).then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updateIsUnreadVoicemailMessagesExists: (context, status) => {
            context.commit("updateIsUnreadVoicemailMessagesExists", status)
        },
        changeDefaultServicesPage: (context, page) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_default_services_page', {
                    services_page: page
                }).then(response => {
                    context.commit('updateDefaultServicesPage', page)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        changeDefaultPage: (context, page) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_default_page', {
                    default_page: page
                }).then(response => {
                    context.commit('updateDefaultPage', page)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updateFollowMes: (context, followMes) => {
            context.commit("updateFollowMes", followMes)
        },
        changeRingtone: (context, id) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_ringtone', {
                    ringtone_id: id
                }).then(response => {
                    context.commit('updateRingtone', id)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updateIsIntegrationsEnabled: (context, status) => {
            context.commit("updateIsIntegrationsEnabled", status)
        }
    },
    mutations: {
        updateIsLogged: (state, isLogged) => {
            state.isLogged = isLogged;
        },
        setUser: (state, user) => {
            state.avatar = user.avatar;
            state.calleridId = localStorageHelper.getItem('calleridId') ? +localStorageHelper.getItem('calleridId') : user.callerid_id;
            state.callerids = user.callerids;
            state.email = user.email;
            state.extension = user.extension;
            state.languageCode = user.language_code;
            state.name = user.name;
            state.isOnline = user.is_online;
            state.crmTenantName = user.crm_service_settings.tenant_name;
            state.pbxUrl = user.pbx_url;
            state.isIntegrationsEnabled = user.is_integrations_enabled;
            state.crmAccessToken = user.crm_service_settings.access_token;
            state.crmServiceUrl = user.crm_service_settings.url;
            state.country = COUNTRIES_CODES[user.country];
            state.color = user.color;
            state.defaultPhonebookViewId = user.default_phonebook_view_id !== 0 ? user.default_phonebook_view_id : "all";
            state.isTestFeaturesEnabled = user.is_test_features_enabled
            state.realtimeToken = user.realtime_token;
            state.smsNumbers = user.sms_numbers;
            state.timezone = user.timezone;
            state.voicemails = user.voicemails;
            state.isUnreadVoicemailMessagesExists = user.is_unread_voicemail_messages_exists
            state.extensionLength = user.extension_length
            state.defaultServicesPage = user.default_services_page
            state.defaultPage = (!user.is_test_features_enabled && user.default_page === 'sms') ? 'dialpad' : user.default_page
            state.ringtones = user.ringtones
            state.ringtoneId = user.ringtone_id
            state.defaultSmsFromNumberId = user.default_sms_from_number_id

            state.max_external_numbers = 3
            state.call_confirm = false
            state.ring_mode = "hunt"
            state.twinning_time = 15
            state.followMes = [
                {
                    display_name: 'test_test@voipcloud.dev',
                    ring_time: 30,
                    is_enabled: true,
                    id: 123,
                    type: 'Teams'
                },
                {
                    display_name: 'device 893162',
                    ring_time: 30,
                    is_enabled: false,
                    id: 456,
                    type: 'Device'
                },
                {
                    display_name: '+611900654321',
                    ring_time: 10,
                    is_enabled: true,
                    id: 789,
                    type: 'External_number'
                },
                {
                    display_name: 'test_test222@voipcloud.dev',
                    ring_time: 30,
                    is_enabled: true,
                    id: 1011,
                    type: 'Teams'
                },
                {
                    display_name: 'device 669050',
                    ring_time: 30,
                    is_enabled: false,
                    id: 1213,
                    type: 'Device'
                },
                {
                    display_name: 'webphone 48568',
                    ring_time: 10,
                    is_enabled: true,
                    id: 8888,
                    type: 'Device',
                    webphone_type: 'browser',
                    user_agent: "",
                    last_ping_at: "14.04.2023 14:37",
                    ip: "121.149.152.116"
                },
                {
                    display_name: 'desktop app 878022',
                    ring_time: 30,
                    is_enabled: true,
                    id: 101111,
                    type: 'Device',
                    webphone_type: 'desktop',
                    user_agent: "Windows 10/11",
                    last_ping_at: "14.04.2023 14:37",
                    ip: "121.149.152.116"
                },
                {
                    display_name: 'mobile app 649456',
                    ring_time: 30,
                    is_enabled: false,
                    id: 121322,
                    type: 'Device',
                    webphone_type: 'mobile',
                    user_agent: "Android",
                    last_ping_at: "14.04.2023 14:37",
                    ip: "121.149.152.116"
                }
            ]
        },
        updateDefaultCallerId: (state, callerIdId) => {
            state.calleridId = callerIdId;
        },
        updatePhoto: (state, avatar) => {
            state.avatar = avatar;
        },
        updateLanguage: (state, langCode) => {
            state.languageCode = langCode;
        },
        updateIsOnline: (state, isOnline) => {
            state.isOnline = isOnline;
        },
        updateDefaultPhonebookView: (state, phonebookId) => {
            state.defaultPhonebookViewId = phonebookId;
        },
        resetState: (state) => {
            Object.assign(state, getDefaultState())
        },
        updateIsUnreadVoicemailMessagesExists: (state, status) => {
            state.isUnreadVoicemailMessagesExists = status
        },
        updateDefaultServicesPage: (state, page) => {
            state.defaultServicesPage = page
        },
        updateDefaultPage: (state, page) => {
            state.defaultPage = page
        },
        updateFollowMes: (state, followMes) => {
            state.followMes = followMes
        },
        updateRingtone: (state, id) => {
            state.ringtoneId = id
        },
        updateIsIntegrationsEnabled: (state, status) => {
            state.isIntegrationsEnabled = status
        },
        updateIsLogOutLoading: (state, status) => {
            state.isLogOutLoading = status
        }
    }
}