<template>
    <service-tab class="call-parking">
        <template v-if="filteredCallParkings.length" v-slot:header>
            <base-select
                v-model="selectedCallParkingNumber"
                :options="callParkingDropdownOptions"
                placeholder="panel.call-history.call-types.all"
                class="select-call-parking"
            ></base-select>

            <base-button
                v-if="!isElectronExist()"
                @click="openWallboard"
                :text="breakpoints.isTabletOrMobile ? '' : 'panel.services.call-parking.open-wallboard-button'"
                :icon="breakpoints.isTabletOrMobile ? 'icon-open-in-new' : ''"
                size="big"
                color="blue"
            ></base-button>
        </template>

        <template v-slot:body>
            <div v-if="!filteredCallParkings.length" class="call-parking-no-available">{{ $t("panel.services.call-parking.no-call-parkings") }}</div>

            <call-parking-item
                v-else
                v-for="callParking in filteredCallParkings"
                :call-parking="callParking"
                :key="callParking.number"
            ></call-parking-item>
        </template>
    </service-tab>
</template>

<script>
import BaseSelect from "../../../defaults/BaseSelect.vue";
import BaseButton from "../../../defaults/BaseButton.vue";
import ServiceTab from "../ServiceTab.vue";
import BaseSpinner from "../../../defaults/BaseSpinner.vue";
import CallParkingItem from "./CallParkingItem.vue";
import {mapGetters, mapActions} from "vuex";
import breakpoints from "../../../../plugins/breakpoints";
import callParkingSelectMixin from "../../../../mixins/callParking/callParkingSelectMixin";
import {isElectronExist} from "../../../../utils/electron";

export default {
    name: "CallParking",
    components: {CallParkingItem, BaseSpinner, ServiceTab, BaseButton, BaseSelect},
    mixins: [callParkingSelectMixin],
    data() {
        return {
            breakpoints
        }
    },
    computed: {
        ...mapGetters("callParkings", ["callParkings", "callParkingDropdownOptions"])
    },
    methods: {
        openWallboard() {
            const route = this.$router.resolve({name: 'callParkingWallboard'})
            window.open(route.href, '_blank')
        },
        isElectronExist
    }
}
</script>

<style scoped>

</style>