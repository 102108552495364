<template>
    <softphone-button
        @click="$emit('click')"
        :label="label"
        :disabled="disabled"
        :size="size"
        :class="classes"
    >
        <span :class="icon" class="call-controls-button-icon icon"></span>
    </softphone-button>
</template>

<script>
import SoftphoneButton from "../SoftphoneButton.vue";
import {CALL_CONTROLS_COLORS} from "./config";

export default {
    name: "CallControlsButton",
    components: {SoftphoneButton},
    props: {
        color: {
            type: String
        },
        icon: {
            type: String,
        },
        size: {
            type: String,
            default: "default"
        },
        label: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                "call-controls-button-color-green": this.color === CALL_CONTROLS_COLORS.GREEN,
                "call-controls-button-color-red": this.color === CALL_CONTROLS_COLORS.RED,
                "call-controls-button-color-blue": this.color === CALL_CONTROLS_COLORS.BLUE,
                "call-controls-button-color-orange": this.color === CALL_CONTROLS_COLORS.ORANGE
            }
        }
    },
    methods: {
        onClick() {
            this.$emit("click")
        }
    }
}
</script>

<style scoped>

</style>