<template>
    <div :style="style" class="softphone-dialpad-skeleton-field"></div>
</template>

<script>
export default {
    name: "SoftphoneDialPadSkeletonField",
    props: {
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        borderRadius: {
            type: [Number, String]
        }
    },
    computed: {
        style() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
                borderRadius: (typeof this.borderRadius === "number") ? `${this.borderRadius}px` : this.borderRadius
            }
        }
    }
}
</script>

<style scoped>

</style>