<template>
    <call-settings-button
        @click="$emit('click')"
        :label="$t('softphone.go-back-button')"
        active
        icon="icon-arrow-left"
    ></call-settings-button>
</template>

<script>
import CallSettingsButton from "./CallSettingsButton.vue"

export default {
    name: "CallSettingsBackButton",
    components: {CallSettingsButton}
}
</script>

<style scoped>

</style>