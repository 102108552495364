import {getPhoneNumberDigitsOnly} from "./phoneNumbers";
 const copyValue = async (value) => {
    await navigator.clipboard.writeText(value).catch((error) => console.error(error.message))
}

const showTooltip = (item, property) => {
    item[property] = true
    setTimeout(() => {
        item[property] = false
    }, 1000)
}

const copyNameWithNumber = (name, number) => {
    number = getPhoneNumberDigitsOnly(number) ? getPhoneNumberDigitsOnly(number) : number

    if (name) {
        copyValue(name + ' ' + number)
    } else {
        copyValue(number)
    }
}

const copyNumber = (item) => {
    let number = getPhoneNumberDigitsOnly(item)

    copyValue(number ? number : item)
}

export {copyValue, showTooltip, copyNameWithNumber, copyNumber}