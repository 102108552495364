<template>
    <div class="person-did-details">
        <span class="label">{{ label }}</span>
        <span class="caller-id-num">{{ formattedDidDetailsNumber }}</span>
    </div>
</template>

<script>
import {formatNumber} from "../../../../utils/phoneNumbers";

export default {
    name: "PersonDidDetails",
    props: {
        label: {
            type: String
        },
        number: {
            type: String
        }
    },
    computed: {
        formattedDidDetailsNumber() {
            return formatNumber(this.number)
        }
    }
}
</script>

<style scoped>

</style>