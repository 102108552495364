import { render, staticRenderFns } from "./HistoryAvatar.vue?vue&type=template&id=563080e4&scoped=true&"
import script from "./HistoryAvatar.vue?vue&type=script&lang=js&"
export * from "./HistoryAvatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563080e4",
  null
  
)

export default component.exports