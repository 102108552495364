<template>
    <div :class="classes" class="dial-pad-action-button">
        <base-tooltip
            :disabled="breakpoints.isMobile || !tooltip"
            :width="95"
            :text="tooltip"
            text-align="center"
            position="top"
        >
            <template v-slot:activator>
                <button
                    v-long-click="{longClick: onLongClick, mouseUp: onMouseUp, timeout: 600}"
                    :style="buttonStyle"
                    class="number-remove-button"
                >
                    <span
                        :class="icon"
                        class="icon"
                    ></span>
                </button>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import breakpoints from "../../../plugins/breakpoints";
import {DIAL_PAD_COLOR} from "./config";

const POSITION_LEFT = "left"
const POSITION_RIGHT = "right"

export default {
    name: "DialPadActionButton",
    components: {BaseTooltip},
    props: {
        icon: {
            type: String
        },
        size: {
            type: Number,
            default: 40
        },
        position: {
            type: String
        },
        tooltip: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: DIAL_PAD_COLOR.LIGHT
        }
    },
    data() {
        return {
            breakpoints
        }
    },
    computed: {
        classes() {
            return {
                "dial-pad-action-button-color-light": this.color === DIAL_PAD_COLOR.LIGHT,
                "dial-pad-action-button-color-dark": this.color === DIAL_PAD_COLOR.DARK,

                "dial-pad-action-button-position-right": this.position === POSITION_RIGHT,
                "dial-pad-action-button-position-left": this.position === POSITION_LEFT
            }
        },

        buttonStyle() {
            const size = Math.max(Math.min(this.size, 48), 36)

            return {
                width: `${size}px`,
                height: `${size}px`,
            }
        }
    },
    methods: {
        onMouseUp(isLongClick) {
            if (!isLongClick) {
                this.$emit("click")
            }
        },
        onLongClick() {
            this.$emit("longClick")
        }
    }
}
</script>

<style scoped>

</style>