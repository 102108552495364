<template>
    <div class="softphone-dial-pad-skeleton">
        <div class="softphone-dial-pad-skeleton-row" style="row-gap: 8px">
            <softphone-dial-pad-skeleton-field
                :width="breakpoints.isTabletOrSmMobile ? 126 : 137"
                :height="breakpoints.isTabletOrSmMobile ? 16 : 18"
                :border-radius="9"
            ></softphone-dial-pad-skeleton-field>

            <softphone-dial-pad-skeleton-field
                :width="240"
                :height="breakpoints.isTabletOrSmMobile ? 44 : 48"
                :border-radius="12"
            ></softphone-dial-pad-skeleton-field>

            <softphone-dial-pad-skeleton-field
                :width="136"
                :height="16"
                :border-radius="8"
            ></softphone-dial-pad-skeleton-field>
        </div>

        <div class="softphone-dial-pad-skeleton-row">
            <softphone-dial-pad-skeleton-field
                :width="300"
                :height="breakpoints.isTabletOrSmMobile ? 48 : 60"
                :border-radius="30"
                :style="{marginBottom: breakpoints.isTabletOrSmMobile ? '38' : '42px'}"
            ></softphone-dial-pad-skeleton-field>

            <div class="softphone-dial-pad-skeleton-keyboard">
                <softphone-dial-pad-skeleton-field
                    v-for="(item, idx) in 12"
                    :key="idx"
                    v-bind="skeletonButtonBind"
                ></softphone-dial-pad-skeleton-field>
            </div>
        </div>

        <div class="softphone-dial-pad-skeleton-row">
            <softphone-dial-pad-skeleton-field
                v-bind="skeletonButtonBind"
            ></softphone-dial-pad-skeleton-field>
        </div>
    </div>
</template>

<script>
import SoftphoneDialPadSkeletonField from "./SoftphoneDialPadSkeletonField.vue";
import breakpoints from "../../../plugins/breakpoints";

export default {
    name: "SoftphoneDialPadSkeleton",
    components: {SoftphoneDialPadSkeletonField},
    data() {
        return {
            breakpoints
        }
    },
    computed: {
        skeletonButtonBind() {
            const size = breakpoints.isTabletOrSmMobile ? 48 : 60

            return {
                width: size,
                height: size,
                borderRadius: "50%"
            }
        }
    }
}
</script>

<style scoped>

</style>