<template>
    <call-controls-button
        @click="$emit('click')"
        :disabled="disabled"
        label="Park call"
        color="blue"
        icon="icon-phone-parked"
    ></call-controls-button>
</template>

<script>
import CallControlsButton from "./CallControlsButton.vue";

export default {
    name: "CallControlsParkCallButton",
    components: {CallControlsButton},
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>