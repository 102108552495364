<template>
    <softphone-state-default>
        <template v-slot:top>
            <container-persons-collapsed>
                <person-collapsed
                    v-for="session in getSessions"
                    :key="session.id"
                    :session="session"
                ></person-collapsed>
            </container-persons-collapsed>
        </template>

        <template v-slot:center>
            <container-call-settings-button>
                <!-- First row -->
                <template v-slot:first-row>
                    <call-settings-mute-button disabled></call-settings-mute-button>

                    <!-- If is attended transfer -->
                    <call-settings-hold-button v-if="getIsAttendedTransfer" disabled></call-settings-hold-button>

                    <call-settings-add-call-button v-if="getIsAttendedTransfer" disabled></call-settings-add-call-button>

                    <!-- If is add call -->
                    <call-settings-swap-button v-if="getIsAddCall" disabled></call-settings-swap-button>

                    <call-settings-merge-button v-if="getIsAddCall" disabled></call-settings-merge-button>
                </template>
                <!-- First row end -->

                <!-- Second row -->
                <template v-slot:second-row>
                    <call-settings-keypad-button disabled></call-settings-keypad-button>

                    <call-settings-record-button disabled :show-tooltip="false"></call-settings-record-button>

                    <call-settings-transfer-button disabled></call-settings-transfer-button>
                </template>
                <!-- Second row end -->
            </container-call-settings-button>
        </template>

        <template v-slot:call_control_button_col-2>
            <call-controls-cancel-button
                @click="hangUp(getSecondSession.id)"
            ></call-controls-cancel-button>
        </template>
    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import ContainerPersonsCollapsed from "../../container/ContainerPersonsCollapsed.vue";
import PersonCollapsed from "../../person/PersonCollapsed.vue";
import ContainerCallSettingsButton from "../../container/ContainerCallSettingsButton.vue";
import CallSettingsMuteButton from "../../softphoneButtons/callSettings/CallSettingsMuteButton.vue";
import CallSettingsHoldButton from "../../softphoneButtons/callSettings/CallSettingsHoldButton.vue";
import CallSettingsAddCallButton from "../../softphoneButtons/callSettings/CallSettingsAddCallButton.vue";
import CallSettingsKeypadButton from "../../softphoneButtons/callSettings/CallSettingsKeypadButton.vue";
import CallSettingsRecordButton from "../../softphoneButtons/callSettings/CallSettingsRecordButton.vue";
import CallSettingsTransferButton from "../../softphoneButtons/callSettings/CallSettingsTransferButton.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallSettingsSwapButton from "../../softphoneButtons/callSettings/CallSettingsSwapButton.vue";
import CallSettingsMergeButton from "../../softphoneButtons/callSettings/CallSettingsMergeButton.vue";

export default {
    name: "SoftphoneStateActiveOutgoing",
    components: {
        CallSettingsMergeButton,
        CallSettingsSwapButton,
        CallControlsCancelButton,
        CallSettingsTransferButton,
        CallSettingsRecordButton,
        CallSettingsKeypadButton,
        CallSettingsAddCallButton,
        CallSettingsHoldButton,
        CallSettingsMuteButton,
        ContainerCallSettingsButton, PersonCollapsed, ContainerPersonsCollapsed, SoftphoneStateDefault},
    computed: {
        ...mapGetters("softphone", ["getSessions", "getSecondSession", "getIsAttendedTransfer", "getIsAddCall"])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp'
        ])
    }
}
</script>

<style scoped>

</style>