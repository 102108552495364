import {formatNumber} from "../../utils/phoneNumbers";
import i18n from "../../locales";

const SESSION_STATUS = Object.freeze({
    ACTIVE: "active",
    OUTGOING: "outgoing",
    INCOMING: "incoming"
})

const SESSION_DIRECTION = Object.freeze({
    IN: "in",
    OUT: "out"
})

export default class Session {
    /**
     * @param {string} id
     * @param {string} number
     * @param {string} displayName
     * @param {string} status
     * @param {string} callerIdNum
     * @param {string} callerIdName
     * @param {string} direction
     * @param {boolean} isRecording
     */
    constructor({
        id,
        number,
        displayName,
        status,
        callerIdNum,
        callerIdName,
        direction,
        isRecording
    }) {
        this._id = id
        this._isOnHold = false
        this._isManuallySetOnHold = false
        this._number = number
        this._displayName = formatNumber(displayName)
        this._time = '0:00'
        this._callDuration = 0
        this._status = status
        this._didDetails = {
            label: status === SESSION_STATUS.OUTGOING ? i18n.t("softphone.caller-id") : i18n.t("softphone.call-received-on"),
            name: callerIdName,
            number: callerIdNum
        }
        this._direction = direction
        this._accept = false
        this._isRecording = isRecording
        this._startTimestamp = Date.now()
        this._answerTimestamp = 0
        this._isCanceled = false
        this._prevSymbol = ''
    }

    /**
     * @return {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {string}
     */
    get number() {
        return this._number;
    }

    /**
     * @return {string}
     */
    get displayName() {
        return this._displayName;
    }

    /**
     * @return {string}
     */
    get status() {
        return this._status;
    }

    /**
     * @param {string} value
     */
    set status(value) {
        this._status = value;
    }

    /**
     * @return {string}
     */
    get direction() {
        return this._direction;
    }

    /**
     * @return {boolean}
     */
    get isRecording() {
        return this._isRecording;
    }

    /**
     * @param {boolean} value
     */
    set isRecording(value) {
        this._isRecording = value;
    }

    /**
     * @return {boolean}
     */
    get isOnHold() {
        return this._isOnHold;
    }

    /**
     * @param {boolean} value
     */
    set isOnHold(value) {
        this._isOnHold = value;
    }

    /**
     * @return {boolean}
     */
    get isManuallySetOnHold() {
        return this._isManuallySetOnHold;
    }

    /**
     * @param {boolean} value
     */
    set isManuallySetOnHold(value) {
        this._isManuallySetOnHold = value;
    }

    /**
     * @return {string}
     */
    get time() {
        return this._time;
    }

    /**
     * @param {string} value
     */
    set time(value) {
        this._time = value;
    }

    /**
     * @return {int}
     */
    get callDuration() {
        return this._callDuration;
    }

    /**
     * @param {int} value
     */
    set callDuration(value) {
        this._callDuration = value;
    }

    /**
     * @return {{number: string, name: string, label: string}}
     */
    get didDetails() {
        return this._didDetails;
    }

    /**
     * @return {boolean}
     */
    get accept() {
        return this._accept;
    }

    /**
     * @param {boolean} value
     */
    set accept(value) {
        this._accept = value;
    }

    /**
     * @return {int}
     */
    get startTimestamp() {
        return this._startTimestamp;
    }

    /**
     * @return {int}
     */
    get answerTimestamp() {
        return this._answerTimestamp;
    }

    /**
     * @param {int} value
     */
    set answerTimestamp(value) {
        this._answerTimestamp = value;
    }

    /**
     * @return {boolean}
     */
    get isCanceled() {
        return this._isCanceled;
    }

    /**
     * @param {boolean} value
     */
    set isCanceled(value) {
        this._isCanceled = value;
    }

    /**
     * @return {string}
     */
    get prevSymbol() {
        return this._prevSymbol;
    }

    /**
     * @param {string} value
     */
    set prevSymbol(value) {
        this._prevSymbol = value;
    }
}

export {SESSION_STATUS, SESSION_DIRECTION}