<template>
    <softphone-button
        @click="$emit('click')"
        :disabled="disabled"
        :label="_label"
        :size="size"
        :tooltip="tooltip"
        :class="classes"
        class="call-settings-button"
    >
        <span :class="_icon" class="icon"></span>
    </softphone-button>
</template>

<script>
import SoftphoneButton from "../SoftphoneButton.vue";

export default {
    name: "CallSettingsButton",
    components: {SoftphoneButton},
    props: {
        icon: {
            type: String
        },
        label: {
            type: String
        },
        activeIcon: {
            type: String,
            default: ""
        },
        activeLabel: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "default"
        },
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: ""
        }
    },
    computed: {
        classes() {
            return {
                "call-settings-button-active": this.active
            }
        },

        _icon() {
            return this.active && this.activeIcon ? this.activeIcon : this.icon
        },

        _label() {
            return this.active && this.activeLabel ? this.activeLabel : this.label
        }
    }
}
</script>

<style scoped>

</style>