import {mapActions, mapGetters} from "vuex";

const dtmfMixin = {
    data() {
        return {
            dtmfModel: "",
            showDtmfKeypad: false
        }
    },
    mounted() {
        document.addEventListener("keyup", this.onKeyup)
    },
    destroyed() {
        document.removeEventListener("keyup", this.onKeyup)
    },
    computed: {
        ...mapGetters("softphone", ["isInCall", "isCallEstablished", "isConference"])
    },
    methods: {
        ...mapActions("softphone", ["sendDTMF"]),

        toggleDtmfKeypad() {
            this.showDtmfKeypad = !this.showDtmfKeypad;
        },

        onKeyup(event) {
            const regex = new RegExp(/^([0-9]|[\*,\#,\+])$/)
            if (this.isInCall && this.isCallEstablished && !this.isConference && regex.test(event.key) && !this.showDtmfKeypad) {
                const activeElement = document.activeElement
                if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
                    return
                }
                this.dtmfModel += event.key
                this.sendDTMF(event.key)
            }
        }
    }
}

export default dtmfMixin