<template>
    <div class="top-bar" :class="{'do-not-disturb': !user.isOnline}">
        <div class="title">
            <div class="logo" :style="{'background-image': 'url(' + logoUrl + ')'}"></div>
            <base-button
                v-if="electronAppUpdateAvailable && isElectronExist()"
                @click="downloadUpdate"
                :class="breakpoints.isMobile ? 'pos-absolute-x-center' : ''"
                color="orange"
                text="top-bar.update-btn"
            ></base-button>

            <span
                v-else
                :class="breakpoints.isMobile ? 'pos-absolute-x-center' : ''"
                class="title-text"
            >{{ $t("top-bar.title") }}</span>
        </div>
        <Menu></Menu>
        <div class="logged-in-panel">
            <d-n-d-switcher v-if="breakpoints.width > 1300"></d-n-d-switcher>

            <div class="logged-in-dropdown-container" id="logged-in-dropdown-container">
                <base-tooltip text="top-bar.name-tooltip" position="bottom">
                    <template v-slot:activator>
                        <div :title="userNameWithExtension" class="name-wrapper">
                            <span class="name">{{ userNameWithExtension }}</span>
                        </div>
                    </template>
                </base-tooltip>

                <top-bar-dropdown></top-bar-dropdown>
            </div>
        </div>

        <div v-show="isLogOutLoading" class="logout-overlay">
            <base-spinner :size="'big'" :color="'blue'"></base-spinner>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Menu from "./Menu.vue";
import BaseButton from "../defaults/BaseButton.vue";
import {addElectronListener, sendToElectron, isElectronExist} from "../../utils/electron";
import BaseSpinner from "../defaults/BaseSpinner.vue";
import SocketIOHelper from "../../helpers/SocketIOHelper";
import BaseTooltip from "../defaults/BaseTooltip.vue";
import TopBarDropdown from "./TopBarDropdown.vue";
import DNDSwitcher from "./DNDSwitcher.vue";
import breakpoints from "../../plugins/breakpoints";
import DNDSwitcherMixin from "../../mixins/DNDSwitcherMixin";

export default {
    name: "TopBar",
    components: {
        DNDSwitcher,
        TopBarDropdown,
        BaseTooltip,
        BaseSpinner,
        Menu,
        BaseButton
    },
    mixins: [DNDSwitcherMixin],
    data() {
        return {
            breakpoints
        }
    },
    created() {
        addElectronListener("DNDToggle", () => {
            this.toggleDND()
        })

        SocketIOHelper.socket.on('update_dnd_status', (data) => {
            this.setIsOnline(!data.is_dnd_enabled)

            if (isElectronExist()) {
                sendToElectron("setDNDStatus", Boolean(data.is_dnd_enabled))
            }
        })
    },
    destroyed() {
        SocketIOHelper.socket.removeAllListeners("update_dnd_status")
    },
    computed: {
        ...mapGetters(['logoUrl', 'electronAppUpdateAvailable']),
        ...mapGetters('user', ['user', 'isOnline', 'userNameWithExtension', 'isLogOutLoading']),
        ...mapGetters('softphone', ['getIsRegistered'])
    },
    methods: {
        ...mapActions('user', ['logOut', 'changeIsOnline', 'setIsOnline']),
        ...mapActions('softphone', ['ctxSipStop']),

        downloadUpdate() {
            sendToElectron("downloadUpdate")
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>