<template>
    <call-settings-button
        @click="$emit('click')"
        :disabled="disabled"
        :active="active"
        label="Split"
        icon="icon-split"
    ></call-settings-button>
</template>

<script>
import CallSettingsButton from "./CallSettingsButton.vue";
import callSettingsButtonMixin from "../../../../../mixins/softphone/callSettingsButtonMixin";

export default {
    name: "CallSettingsSplitButton",
    components: {CallSettingsButton},
    mixins: [callSettingsButtonMixin]
}
</script>

<style scoped>

</style>