<template>
    <div class="person-collapsed" :class="classes">
        <Avatar
            :custom-size="_size === PERSON_SIZE.DEFAULT ? 40 : 34"
            :src="contact.avatar"
            :text="contact.name"
            :color="contact.color"
            :call-type="session.direction"
        ></Avatar>

        <div class="person-collapsed-details">
            <person-name-number-group
                :number="session.displayName"
                :name="contactName"
                :base-font-size="_size === PERSON_SIZE.DEFAULT ? '14px' : '13px'"
                :secondary-font-size="_size === PERSON_SIZE.DEFAULT ? '13px' : '12px'"
                align="left"
            ></person-name-number-group>

            <person-did-details
                :label="session.didDetails.label"
                :number="session.didDetails.number"
            ></person-did-details>
        </div>

        <div class="person-collapsed-call-info">
            <call-status-label
                :animated="session.status === SESSION_STATUS.OUTGOING || session.status === SESSION_STATUS.INCOMING"
                :top-offset="0"
            >
                {{ sessionStatusLabel }}
            </call-status-label>

            <call-status-label
                v-if="session.status === SESSION_STATUS.ACTIVE"
                :top-offset="0"
            >
                {{ session.time }}
            </call-status-label>
        </div>
    </div>
</template>

<script>
import Avatar from "../../../defaults/Avatar.vue";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import {SESSION_STATUS} from "../../../../models/softphone/Session";
import PersonDidDetails from "./PersonDidDetails.vue";
import PersonNameNumberGroup from "./PersonNameNumberGroup.vue";
import personMixin from "../../../../mixins/softphone/personMixin";
import CallStatusLabel from "../CallStatusLabel.vue";
import {PERSON_SIZE} from "./config";

export default {
    name: "PersonCollapsed",
    components: {CallStatusLabel, PersonDidDetails, Avatar, BaseTooltip, PersonNameNumberGroup},
    mixins: [personMixin],
    data() {
        return {
            SESSION_STATUS,
            PERSON_SIZE
        }
    },
    computed: {
        classes() {
            return {
                "person-collapsed-on-hold": this.session.isOnHold,
                "person-collapsed-size-default": this._size === PERSON_SIZE.DEFAULT,
                "person-collapsed-size-small": this._size === PERSON_SIZE.SMALL
            }
        },

        sessionStatusLabel() {
            if (this.session.status === SESSION_STATUS.OUTGOING) {
                return "Outgoing"
            }

            if (this.session.status === SESSION_STATUS.INCOMING) {
                return "Incoming"
            }

            // if is active
            return this.session.isOnHold ? `(${this.$t('person-collapsed.person-status-hold')})` : `(${this.$t('person-collapsed.person-status-active')})`
        }
    }
}
</script>

<style scoped>

</style>