<template>
    <div class="base-country-select">
        <base-dropdown
            v-model="showDropdown"
            :width="width"
            :max-height="height"
            :close-on-content-click="false"
            :offsetX="-12"
            align="left"
            position="bottom"
            open-by="click"
        >
            <template v-slot:activator>
                <div class="base-country-select-activator">
                    <span :class="selectedCountry ? selectedCountry.icon : ''" class="icon"></span>
                    <base-button
                        :icon="showDropdown ? 'icon-expand-up' : 'icon-expand-down'"
                        size="small"
                        color="none"
                    ></base-button>
                </div>
            </template>

            <template v-slot:no-scrollable-content>
                <div class="form-group base-country-select-search-input-group">
                    <input
                        v-model="search"
                        class="search-input"
                        type="text"
                        placeholder="Search"
                        ref="searchCountryInput"
                    >
                    <span class="icon icon-search"></span>
                </div>
            </template>

            <base-list v-if="options.length">
                <base-list-item
                    v-for="option in options"
                    @click="onSelect(option)"
                    :key="option.code"
                    :selected="option.code === value"
                >
                    <base-list-item-icon :icon="option.icon"></base-list-item-icon>

                    <base-list-item-title>{{ option.name }}</base-list-item-title>
                </base-list-item>
            </base-list>

            <base-list-item v-else :hover="false">
                <base-list-item-title>No elements found</base-list-item-title>
            </base-list-item>
        </base-dropdown>
    </div>
</template>

<script>
import BaseDropdown from "./BaseDropdown.vue";
import BaseButton from "./BaseButton.vue";
import BaseList from "./baseList/BaseList.vue";
import BaseListItem from "./baseList/BaseListItem.vue";
import {getListOfCountries, getCountryByCode} from "../../helpers/CountryHelper";
import BaseListItemTitle from "./baseList/BaseListItemTitle.vue";
import BaseListItemIcon from "./baseList/BaseListItemIcon.vue";

export default {
    name: "BaseCountrySelect",
    components: {BaseListItemIcon, BaseListItemTitle, BaseListItem, BaseList, BaseButton, BaseDropdown},
    props: {
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        value: {
            type: String,
            default: "AU"
        }
    },
    data() {
        return {
            showDropdown: false,
            search: ""
        }
    },
    watch: {
        showDropdown(value) {
            if (!value) {
                this.search = ""
                return
            }

            window.requestAnimationFrame(() => {
                this.$refs.searchCountryInput.focus()
            })
        }
    },
    computed: {
        selectedCountry() {
            return getCountryByCode(this.value)
        },

        options() {
            return getListOfCountries().filter((country) => {
                const search = this.search.toLowerCase()

                return country.name.toLowerCase().includes(search) || country.code.toLowerCase().includes(search)
            })
        }
    },
    methods: {
        onSelect(country) {
            this.$emit("input", country.code)
            this.showDropdown = false
        }
    }
}
</script>

<style scoped>

</style>