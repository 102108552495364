import Vue from 'vue';
import App from './components/App.vue';
import store from './store';
import router from './router';
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate';
import 'vuetify/dist/vuetify.min.css'
import './scss/style.scss';
import i18n from './locales/index.js'
import Vuebar from 'vuebar';
import PortalVue from 'portal-vue';
import { sentryInit } from "./sentry";

Vue.use(Vuetify);
Vue.use(Vuelidate);
Vue.use(Vuebar);
Vue.use(PortalVue);

Vue.prototype.$eventBus = new Vue();

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target)) && !event.composedPath().includes(el)) {
                if(binding.value instanceof Function) {
                    binding.value(event)
                } else {
                    vnode.context[binding.expression](event);
                }
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Vue.directive('long-click', {
    bind(el, binding, vnode) {
        const DEFAULT_TIMEOUT = 600

        const timeout = binding.value.timeout ? Number(binding.value.timeout) : DEFAULT_TIMEOUT

        el._longClick = {
            timer: null,
            startTimestamp: null,
            isLongClick: false,
            startClick: (event) => {
                el._longClick.clear()

                el._longClick.timer = setTimeout(() => {
                    el._longClick.isLongClick = true
                    binding.value.longClick?.(event)
                }, timeout)
            },
            endClick: () => {
                binding.value.mouseUp?.(el._longClick.isLongClick)
                el._longClick.clear()
            },
            clear: () => {
                if (el._longClick.timer) {
                    clearTimeout(el._longClick.timer)
                }
                el._longClick.isLongClick = false
            }
        }

        el.addEventListener('pointerdown', el._longClick.startClick)
        el.addEventListener('pointerup', el._longClick.endClick)
    },
    unbind(el) {
        el.removeEventListener('pointerdown', el._longClick.startClick)
        el.removeEventListener('pointerup', el._longClick.endClick)

        delete el._longclick
    }
})

sentryInit(Vue);

window.App = new Vue({
    el: '#vue-app',
    store,
    router,
    vuetify: new Vuetify(),
    i18n,
    render: h => h(App),
    created() {
        document.addEventListener('beforeunload', this.handler)
    },
    methods: {
        handler(event) {
            store.dispatch('softphone/ctxSipStop');
        }
    }
});
