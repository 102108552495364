<template>
    <div class="person" :class="classes">
        <div class="person-top">
            <span class="caller-id-name">{{ session.didDetails.name }}</span>

            <person-did-details
                :label="session.didDetails.label"
                :number="session.didDetails.number"
            ></person-did-details>
        </div>

        <Avatar
            :custom-size="_size === PERSON_SIZE.DEFAULT ? 60 : 48"
            :src="contact.avatar"
            :text="contact.name"
            :color="contact.color"
            :call-type="session.direction"
        ></Avatar>

        <person-name-number-group
            :number="session.displayName"
            :name="contactName"
            :base-font-size="_size === PERSON_SIZE.DEFAULT ? '20px' : '18px'"
            :secondary-font-size="_size === PERSON_SIZE.DEFAULT ? '16px' : '14px'"
            align="center"
        ></person-name-number-group>
    </div>
</template>

<script>
import Avatar from "../../../defaults/Avatar.vue";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import PersonDidDetails from "./PersonDidDetails.vue";
import PersonNameNumberGroup from "./PersonNameNumberGroup.vue";
import personMixin from "../../../../mixins/softphone/personMixin";
import {PERSON_SIZE} from "./config";

export default {
    name: "Person",
    components: {PersonNameNumberGroup, PersonDidDetails, BaseTooltip, Avatar},
    mixins: [personMixin],
    data() {
        return {
            PERSON_SIZE
        }
    },
    computed: {
        classes() {
            return {
                "person-size-default": this._size === PERSON_SIZE.DEFAULT,
                "person-size-small": this._size === PERSON_SIZE.SMALL,
            }
        }
    }
}
</script>

<style scoped>

</style>