import Api from '../../utils/api';
import {getNationalNumberLength, getPhoneNumberDigitsOnly, isExtension} from "../../utils/phoneNumbers";
import Crm, {ZAPIER_TYPE} from "../../helpers/integrations/crms";
import i18n from "../../locales";
import {fetchCallInfo} from "../../utils/integrations";

const TIME_GAP = 10000 // the time difference between the start of a call recorded in the webphone and in the PBX is approximately 10 seconds

/**
 * Help function for saving and retrieving notes
 *
 * @param {string} crmType
 * @param {int} contactId
 * @param {string} number
 * @param {int} timestamp
 * @param {{crm_type: string, contact_id: int, number: string, timestamp: int}} note
 */
const compareNote = (crmType, contactId, number, timestamp, note) => {
    return crmType === note.crm_type &&
           contactId === note.contact_id &&
           getPhoneNumberDigitsOnly(number) === getPhoneNumberDigitsOnly(note.number) &&
           Math.abs(note.timestamp - timestamp) < TIME_GAP
}

export default {
    namespaced: true,
    state: {
        integrations: [],
        callInfo: {},
        callInfoExtended: {},
        callLogBuffer: {},
        zapierSubscriptions: [],
        zapierContactInfo: {},
        notes: [],
        isLoadingCallInfo: {},
        contactNamesInCrms: {}
    },
    getters: {
        integrations: (state) => {
            return state.integrations
        },
        callInfo: (state) => number => {
            return state.callInfo[number] ? Object.values(state.callInfo[number]) : []
        },
        callInfoExtended: (state) => id => {
            return state.callInfoExtended[id]
        },
        allCallInfoExtended: (state) => {
            return state.callInfoExtended
        },
        callLogBuffer: (state) => number => {
            return state.callLogBuffer[number] ? Object.values(state.callLogBuffer[number]) : []
        },
        zapierSubscriptions: (state) => {
            return state.zapierSubscriptions
        },
        zapierEnabled: (state) => {
            const zapierEnabled = state.integrations.find((integration) => integration.type === ZAPIER_TYPE && integration.enabled)
            return Boolean(zapierEnabled)
        },
        zapierContactInfo: (state) => number => {
            return state.zapierContactInfo[number] ? state.zapierContactInfo[number] : {}
        },
        notes: (state) => (crmType, contactId, number, timestamp) => {
            const note = state.notes.find((note) => compareNote(crmType, contactId, number, timestamp, note))

            return note ? note.value : ""
        },
        isLoadingCallInfo: (state) => number => {
            return state.isLoadingCallInfo[number] ? state.isLoadingCallInfo[number] : false
        },
        contactNameInCrm: (state) => number => {
            return state.contactNamesInCrms[number] ? state.contactNamesInCrms[number] : ""
        }
    },
    actions: {
        getIntegrations: (context, {tenant, email}) => {
            return new Promise((resolve, reject) => {
                Api().get('/integrations/get_integrations', {
                    params: {
                        tenant: tenant,
                        email: email
                    }
                }).then((response) => {
                    context.commit('setIntegrations', response.data)

                    resolve()
                }).catch(() => reject())
            })
        },

        disableIntegration: (context, type) => {
            Api().post('/integrations/disable_integration', {
                type: type
            }).then((response) => {
                context.commit('setIntegrationEnable', {type: type, enabled: false})
            }).catch(() => {})
        },

        enableIntegration: (context, type) => {
            context.commit('setIntegrationEnable', {type: type, enabled: true})
        },

        getCallInfo: (context, {tenant, email, number}) => {
            if (context.getters.isLoadingCallInfo(number)) {
                return Promise.reject()
            }

            context.commit("setIsLoadingCallInfo", {number, status: true})
            let crmList = []
            context.getters.integrations.forEach((i) => {
                if (i.enabled && i.type !== ZAPIER_TYPE) {
                    crmList.push(i.type)
                }
            })

            if (!crmList) {
                return Promise.reject()
            }

            return new Promise((resolve, reject) => {
                fetchCallInfo({
                    tenant: tenant,
                    email: email,
                    number: getPhoneNumberDigitsOnly(number),
                    crm_list: crmList.join(','),
                    phone_digits_to_search: getNationalNumberLength(number),
                    use_strict_search: isExtension(getPhoneNumberDigitsOnly(number))
                }).then((response) => {
                    const expandContactRequests = []

                    for (let item of response.data) {
                        if (item.error_code !== 0) {
                            const message = i18n.t('alert-messages.error-occure-with-code') + item.error_code + " \n" + item.error_message
                            context.dispatch("createAlertMessage", {message: message, type: 'warning'}, {root: true})
                        }

                        if (item.contacts.length && item.contacts.length < 3) {
                            for (let contact of item.contacts) {
                                expandContactRequests.push(context.dispatch("getCallInfoExtended", {contact, crmType: item.crm_type}))
                            }
                        }

                        if (item.contacts.length && !context.getters.contactNameInCrm(number)) {
                            context.commit("setContactNameInCrm", {number, name: item.contacts[0]?.name})
                        }
                    }

                    context.commit("setCallInfo", {number, info: response.data})
                    return Promise.allSettled(expandContactRequests)
                }).then(() => {
                    resolve()
                }).catch((err) => {
                    console.error(err)
                    reject()
                }).finally(() => {
                    context.commit("setIsLoadingCallInfo", {number, status: false})
                })
            })
        },

        setContactNameInCrm: (context, {number, name}) => {
            context.commit("setContactNameInCrm", {number, name})
        },

        removeContactNameInCrm: (context, number) => {
            context.commit("removeContactNameInCrm", number)
        },

        getCallInfoExtended: (context, {contact, crmType}) => {
            const crm = new Crm().create(crmType)
            const requestParams = crm.getParamsForCallInfoExtendedRequest(contact)

            return new Promise((resolve, reject) => {
                Api().get('/integrations/call_info_extended', {
                    params: requestParams
                }).then((response) => {
                    context.commit("setCallInfoExtended", {id: contact.crm_object_id, info: response.data})
                    resolve()
                }).catch((error) => reject(error.response))
            })
        },

        resetCallInfo: (context, numbers) => {
            numbers.forEach((num) => {
                context.getters.callInfo(num).forEach((info) => {
                    context.commit("removeCallInfoExtended", info.contacts)
                })
                context.commit("removeCallInfo", num)
                context.commit("removeContactNameInCrm", num)
                context.commit("removeIsLoadingCallInfo", num)
            })
        },

        createContact: (context, {crmType, data, contactName, number}) => {
            return new Promise((resolve, reject) => {
                Api().post("/integrations/crm_create_contact", {
                    data: data,
                    crm_type: crmType
                }).then((response) => {
                    context.commit("createContact", {name: contactName, objectId: response.data.contact_id, objectType: response.data.crm_object_type, crmType, number})
                    const crm = context.getters.callInfo(number).find((item) => item.crm_type === crmType)
                    let contact
                    if (crm) {
                        contact = crm.contacts.find(contact => contact.crm_object_id === response.data.contact_id)
                    }
                    resolve(contact)
                }).catch((error) => reject())
            })
        },

        addDataToCallLogBuffer: (context, {number, data}) => {
            context.commit('updateCallLogBuffer', {number, data})
        },

        resetCallLogBuffer: (context, {number = null, contactId = null}) => {
            if (!number) {
                Object.keys(context.state.callLogBuffer).forEach((number) => {
                    context.commit('clearCallLogBuffer', {number, contactId})
                })
            } else {
                context.commit('clearCallLogBuffer', {number, contactId})
            }
        },

        updateNote: (context, {crmType, contactId, number, timestamp, value}) => {
            context.commit('updateNote', {crmType, contactId, number, timestamp, value})
        },

        getZapierSubscriptions: (context, {tenant, email}) => {
            return new Promise((resolve, reject) => {
                Api().get("/integrations/zapier_subsriptions", {
                    params: {
                        tenant: tenant,
                        user_email: email
                    }
                }).then((response) => {
                    context.commit("setZapierSubsriptions", response.data.subscriptions)
                    resolve()
                }).catch((error) => reject())
            })
        },

        getZapierContactInfo: (context, number) => {
            return new Promise((resolve, reject) => {
                Api().get('/integrations/get_zapier_contact_info', {
                    params: {
                        phone_number: number
                    }
                }).then((response) => {
                    const data = response.data
                    if (Object.keys(data).length) {
                        context.commit("setZapierContactInfo", {number: number, info: response.data})
                    }
                    resolve(data)
                }).catch((error) => reject())
            })
        },

        resetZapierContactInfo: (context, numbers) => {
            numbers.forEach((num) => {
                context.commit("removeZapierContactInfo", num)
            })
        },

        removeIntegration: (context, integrationType) => {
            context.commit("removeIntegration", integrationType)
        },

        resetCallLogBufferForCrm: (context, crmType) => {
            context.commit('clearCallLogBufferForCrm', crmType)
        }
    },
    mutations: {
        setIntegrations: (state, integrations) => {
            state.integrations = integrations
        },

        removeIntegration: (state, integrationType) => {
            state.integrations = state.integrations.filter((integration) => integration.type !== integrationType)
        },

        setIntegrationEnable: (state, {type, enabled}) => {
            const integration = state.integrations.find((item) => item.type === type)
            integration.enabled = enabled
        },

        setCallInfo: (state, {number, info}) => {
            state.callInfo = {
                ...state.callInfo,
                [ number ]: { ...info }
            }
        },

        setCallInfoExtended: (state, {id, info}) => {
            state.callInfoExtended = {
                ...state.callInfoExtended,
                [ id ]: { ...info }
            }
        },

        removeCallInfo: (state, number) => {
            delete state.callInfo[number]
        },

        removeCallInfoExtended: (state, contacts) => {
            contacts.forEach((contact) => {
                delete state.callInfoExtended[contact.crm_object_id]
            })
        },

        updateCallLogBuffer: (state, {number, data}) => {

            if (state.callLogBuffer[number]) {
                state.callLogBuffer[number].push(data)
            } else {
                state.callLogBuffer = {
                    ...state.callLogBuffer,
                    [ number ]: [data]
                }
            }
        },

        clearCallLogBuffer: (state, {number, contactId = null}) => {
            if (!contactId) {
                delete state.callLogBuffer[number]
            } else {
                if (state.callLogBuffer[number]) {
                    const buffer = state.callLogBuffer[number]
                    state.callLogBuffer[number] = buffer.filter((data) => data.contactId !== contactId)
                }
            }
        },

        createContact: (state, {name, objectId, objectType, crmType, number}) => {
            const crm = Object.values(state.callInfo[number]).find((item) => item.crm_type === crmType)
            if (crm) {
                crm.contacts.push({
                    crm_object_id: objectId,
                    crm_object_type: objectType,
                    name: name
                })
            }
        },

        updateNote: (state, {crmType, contactId, number, timestamp, value}) => {
            const existingNote = state.notes.find((note) => compareNote(crmType, contactId, number, timestamp, note))

            if (existingNote) {
                existingNote.value = value
            } else {
                state.notes.push({
                    crm_type: crmType,
                    contact_id: contactId,
                    number: number,
                    timestamp: timestamp,
                    value
                })
            }
        },

        setZapierSubsriptions: (state, zapierSubscriptions) => {
            state.zapierSubscriptions = zapierSubscriptions
        },

        setZapierContactInfo: (state, {number, info}) => {
            state.zapierContactInfo = {
                ...state.zapierContactInfo,
                [ number ]: { ...info }
            }
        },

        removeZapierContactInfo: (state, number) => {
            delete state.zapierContactInfo[number]
        },

        clearCallLogBufferForCrm: (state, crmType) => {
            Object.keys(state.callLogBuffer).forEach((number) => {
                if (state.callLogBuffer[number]) {
                    const buffer = state.callLogBuffer[number]
                    state.callLogBuffer[number] = buffer.filter((data) => data.crmType !== crmType)
                }
            })
        },

        setIsLoadingCallInfo: (state, {number, status}) => {
            state.isLoadingCallInfo = {
                ...state.isLoadingCallInfo,
                [ number ]: status
            }
        },

        removeIsLoadingCallInfo: (state, number) => {
            delete state.isLoadingCallInfo[number]
        },

        setContactNameInCrm: (state, {number, name}) => {
            state.contactNamesInCrms = {
                ...state.contactNamesInCrms,
                [ number ]: name
            }
        },

        removeContactNameInCrm: (state, number) => {
            delete state.contactNamesInCrms[number]
        },
    }
}