<template>
    <div class="container-persons-collapsed">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ContainerPersonsCollapsed"
}
</script>

<style scoped>

</style>