<template>
    <call-settings-button
        @click="$emit('click')"
        :disabled="disabled"
        :active="active"
        :label="$t('buttons-group.buttons-group-manage-call.record')"
        :active-label="$t('buttons-group.buttons-group-manage-call.stop')"
        :tooltip="_tooltip"
        icon="icon-radio-checked"
        active-icon="icon-radio-unchecked"
        class="call-settings-record-button"
    ></call-settings-button>
</template>

<script>
import CallSettingsButton from "./CallSettingsButton.vue";
import callSettingsButtonMixin from "../../../../../mixins/softphone/callSettingsButtonMixin";

export default {
    name: "CallSettingsRecordButton",
    components: {CallSettingsButton},
    mixins: [callSettingsButtonMixin],
    props: {
        showTooltip: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        _tooltip() {
            if (!this.showTooltip) {
                return ""
            }

            return this.disabled ?
                this.$t('buttons-group.buttons-group-manage-call.record-button.disabled-tooltip') :
                this.$t('buttons-group.buttons-group-manage-call.record-button.enabled-tooltip')
        }
    }
}
</script>

<style scoped>

</style>