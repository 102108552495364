<template>
    <div id="sms" class="tab sms-tab" :class="{'active': this.active}">
        <div v-show="showChatsList" class="sms-panel left-panel">
            <div v-if="!isSearchPanelActive" class="sms-header left-panel-header">
                <div class="title-wrapper">
                    <h3>{{ $t("panel.sms.title") }}</h3>
                </div>
                <div class="sms-buttons">
                    <base-button
                        class="search-button"
                        @click="changeIsSearchPanelActive"
                        size="big"
                        icon="icon-search"
                        color="gray"
                    ></base-button>
                    <base-button
                        @click="showNewSmsDialogPopup"
                        size="big"
                        icon="icon-add"
                        color="blue"
                    ></base-button>
                </div>
            </div>
            <div v-else class="sms-header left-panel-header">
                <base-button
                    @click="changeIsSearchPanelActive"
                    :size="'big'"
                    :color="'gray'"
                    :icon="'icon-chevron-left'"
                ></base-button>
                <base-input
                    v-model="searchString"
                    class="find-chat"
                    placeholder="panel.sms.find-contact-placeholder"
                    icon="icon-clear"
                    type="text"
                    iconColor="gray"
                    iconClick="clear"
                    focused
                ></base-input>
            </div>

            <div class="sms-chats" v-bar>
                <div :class="{'empty-list': chatList.length === 0}" class="scroll-wrapper">
                    <div v-show="searchString && !searchChatList.length" class="no-sms-chats nothing-found">{{ $t("panel.sms.no-results-found") }}</div>
                    <sms-chat
                        v-for="(chat, index) in (searchString ? searchChatList : chatList)"
                        @click="selectChat(chat)"
                        :key="index"
                        :number="chat.number"
                        :last-sms="chat.last_sms"
                        :number-of-unread-messages="chat.number_of_unread_messages"
                        :selected="chat.id === selectedChat.id"
                        :is-unread="Boolean(chat.is_unread)"
                        :ref="index === chatList.length - 1 ? 'lastPost' : null"
                    ></sms-chat>

                    <div class="spinner-wrapper">
                        <base-spinner v-if="chatsLoading && chatList.length === 0"></base-spinner>
                    </div>

                    <div v-if="!chatsLoading && chatList.length === 0" class="no-sms-chats">
                        <h4>{{ $t("panel.sms.no-active-chats") }}</h4>
                        <p v-if="smsNumbers.length === 0">{{ $t("panel.sms.no-sms-numbers") }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showDialog && selectedChat.id" class="sms-panel right-panel">
            <div class="sms-header right-panel-header">
                <div class="left-actions-group">
                    <base-button
                        v-if="!isLaptop && isPhoneNumber"
                        @click="speedDial(selectedChat.number)"
                        text="panel.sms.make-a-call-button"
                        size="big"
                        color="green"
                        class="make-call-button"
                    ></base-button>

                    <base-button
                        v-show="windowWidth <= 620"
                        @click="backToContactsList"
                        :icon="'icon-chevron-left'"
                        color="default"
                        size="default"
                        disable-borders
                    ></base-button>

                    <avatar
                        v-show="windowWidth <= 620"
                        :src="selectedContact.avatar"
                        :text="selectedContact.name"
                        :color="selectedContact.color"
                        size="small"
                    ></avatar>

                    <base-tooltip v-model="copyButton.isCopied" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
                        <template v-slot:activator>
                            <div class="title-wrapper" :title="selectedContact.name">
                                <h3>{{ selectedContact.name ? selectedContact.name : formatNumber(selectedChat.number) }}</h3>
                            </div>
                        </template>
                    </base-tooltip>
                    <copy-button @click="[copyNameWithNumber(selectedContact.name, selectedChat.number), showTooltip(copyButton, 'isCopied')]"></copy-button>
                </div>
                <div class="right-actions-group">
                    <base-button
                        v-if="!isLaptop"
                        @click="toggleDeleteSmsDialogPopup"
                        size="big"
                        icon="icon-delete-forever"
                        class="delete-btn"
                    ></base-button>

                    <base-button
                        v-if="!isLaptop"
                        @click="onClickMarkChatAsUnread"
                        size="big"
                        text="panel.sms.mark-as-unread"
                        icon="icon-mark-chat-unread"
                    ></base-button>

                    <base-button
                        v-if="canShowIntegrations && !isLaptop && isPhoneNumber"
                        @click="toggleIntegrations"
                        :color="integrationsInline ? 'dark-gray' : 'default'"
                        text="panel.sms.crm-integrations-button"
                        size="big"
                        icon="icon-join-right-black"
                    ></base-button>

                    <base-button
                        v-if="!selectedContact.id && isPhoneNumber"
                        @click="showAddContactPopup(selectedChat.number)"
                        icon="icon-person-add"
                        :text="isLaptop ? '' : 'panel.sms.add-contact-button'"
                        size="big"
                        color="blue"
                    ></base-button>

                    <base-button
                        v-else-if="!isLaptop && isPhoneNumber"
                        @click="showEditContactPopup(selectedContact)"
                        icon="icon-edit"
                        text="popups.contact-popup.title-update-contact"
                        size="big"
                        color="blue"
                    ></base-button>

                    <base-button
                        v-if="isLaptop && isPhoneNumber"
                        @click="speedDial(selectedChat.number)"
                        icon="icon-call"
                        size="big"
                        color="green"
                        disable-borders
                    ></base-button>

                    <base-dropdown
                        v-if="isLaptop"
                        :width="180"
                        position="bottom"
                        align="right"
                    >
                        <template v-slot:activator>
                            <base-button
                                size="big"
                                icon="icon-dots"
                                color="gray"
                            ></base-button>
                        </template>

                        <base-list>
                            <base-list-item
                                v-for="(option, idx) in dropdownOptions"
                                @click="option.onclick()"
                                :key="idx"
                            >
                                <base-list-item-icon :icon="option.icon"></base-list-item-icon>

                                <base-list-item-title>{{ option.name }}</base-list-item-title>
                            </base-list-item>
                        </base-list>
                    </base-dropdown>
                </div>
            </div>

            <div v-if="integrationsInline && canShowIntegrations" class="integrations-wrapper" v-bar>
                <div class="integrations-scroll">
                    <integrations-inline
                        :allow-call-logging="false"
                        :number="selectedChat.number"
                    ></integrations-inline>
                </div>
            </div>

            <div class="sms-dialog-wrapper">
                <sms-dialog
                    :chat="selectedChat"
                    :contact="selectedContact"
                    :window-width="windowWidth"
                ></sms-dialog>
            </div>
        </div>
        <div v-else-if="!selectedChat.id && chatList.length > 0 && windowWidth > 620" class="sms-panel right-panel select-contact-tip">
            <chip text="panel.sms.no-chat-selected" color="white" size="big" disable-borders></chip>
        </div>

        <delete-sms-dialog-popup
            v-if="deleteSmsDialogPopup"
            @delete="onDeleteSmsDialogPopup"
            @close="toggleDeleteSmsDialogPopup"
        ></delete-sms-dialog-popup>
        <new-sms-dialog-popup v-if="newSmsDialogPopup" @close="closeNewSmsDialogPopup"></new-sms-dialog-popup>

        <integrations-popup
            v-if="integrationsPopup"
            @close="closeIntegrationsPopup"
            :numbers="[selectedChat.number]"
            :width="336"
            :allow-call-logging="false"
            position="bottom"
            mount-to="#sms"
            blackout
        ></integrations-popup>
    </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import SmsChat from "./SmsChat.vue";
import SmsDialog from "./SmsDialog.vue";
import {mapActions, mapGetters} from "vuex";
import DeleteSmsDialogPopup from "../../defaults/popups/DeleteSmsDialogPopup.vue";
import NewSmsDialogPopup from "../../defaults/popups/NewSmsDialogPopup.vue";
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";
import Avatar from "../../defaults/Avatar.vue";
import IntegrationsPopup from "../../integrations/IntegrationsPopup.vue";
import Chip from "../../defaults/Chip.vue";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import {formatNumber, numbersAreMatched, isPhoneNumber} from "../../../utils/phoneNumbers";
import SocketIOHelper from "../../../helpers/SocketIOHelper";
import {SMS_TYPE_INBOUND} from "../../../store/modules/SMSes";
import {showAddContactPopup, showEditContactPopup} from "../../../utils/phonebooks";
import {speedDial} from "../../../utils/softphone";
import {canShowIntegrations} from "../../../utils/integrations";
import BaseInput from "../../defaults/BaseInput.vue";
import {findInContact} from "../../../utils/phonebooks";
import CopyButton from "../../defaults/CopyButton.vue";
import {copyNameWithNumber, showTooltip} from "../../../utils/copy";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import BaseDropdown from "../../defaults/BaseDropdown.vue";
import BaseList from "../../defaults/baseList/BaseList.vue";
import BaseListItem from "../../defaults/baseList/BaseListItem.vue";
import BaseListItemIcon from "../../defaults/baseList/BaseListItemIcon.vue";
import BaseListItemTitle from "../../defaults/baseList/BaseListItemTitle.vue";

export default {
    name: "Sms",
    components: {
        BaseListItemTitle,
        BaseListItemIcon,
        BaseListItem,
        BaseList,
        BaseDropdown,
        BaseSpinner,
        Chip,
        IntegrationsPopup,
        Avatar, IntegrationsInline, NewSmsDialogPopup, DeleteSmsDialogPopup, SmsDialog, SmsChat, BaseButton, BaseInput, CopyButton, BaseTooltip},
    props: {
        active: Boolean,
        windowWidth: Number
    },
    data() {
        return {
            deleteSmsDialogPopup: false,
            newSmsDialogPopup: false,
            integrationsInline: false,
            integrationsPopup: false,
            showChatsList: true,
            showDialog: true,
            selectedChat: {},
            chatsLimit: 50,
            allChatsAreLoaded: false,
            chatsLoading: false,
            lastPostObserver: null,
            now: null,
            isSearchPanelActive: false,
            searchString: '',
            copyButton: {
                'isCopied': false
            }
        }
    },
    created() {
        this.loadChats()

        SocketIOHelper.socket.on("sms_received", this.onSmsReceived)
        SocketIOHelper.addEventListener("reconnect", this.loadChats)
    },
    destroyed() {
        SocketIOHelper.socket.off("sms_received", this.onSmsReceived)
        SocketIOHelper.removeEventListener("reconnect", this.loadChats)
    },
    watch: {
        windowWidth() {
            if (this.windowWidth > 620) {
                this.showChatsList = true
                this.showDialog = true
            } else {
                this.showChatsList = true
                this.showDialog = false
            }
        },
        isSearchPanelActive() {
            if (!this.isSearchPanelActive) {
                this.searchString = ""
            }
        }
    },
    computed: {
        ...mapGetters(['activeTab']),
        ...mapGetters("details", ["getContactByNumber"]),
        ...mapGetters("user", ["smsNumbers", "isIntegrationsEnabled"]),
        ...mapGetters("SMSes", ["chats"]),
        ...mapGetters("details", ["getDefaultPhonebook", "defaultContact"]),
        ...mapGetters('integrations', ['integrations']),

        selectedContact() {
            return this.getContactByNumber(this.selectedChat.number)
        },

        chatList() {
            return this.chats.sort((firstChat, secondChat) => {
                return new Date(secondChat.last_sms.date) - new Date(firstChat.last_sms.date)
            })
        },

        searchChatList() {
            return this.chats.filter((chat) => {
                const contact = this.getContactByNumber(chat.number)
                const isInContacts =  findInContact(this.searchString, ((contact.name && contact.number) ? contact : chat))
                const isNamedNumber = chat.number.toLowerCase().includes(this.searchString.toLowerCase())

                return isInContacts || isNamedNumber
            })
        },

        canShowIntegrations() {
            return canShowIntegrations(this.selectedChat.number)
        },

        dropdownOptions() {
            let options = []

            if (this.selectedContact.id && this.isPhoneNumber) {
                options.push({
                    icon: "icon-edit",
                    name: this.$t("popups.contact-popup.title-update-contact"),
                    value: "update",
                    onclick: () => this.showEditContactPopup(this.selectedContact)
                })
            }

            if (this.isPhoneNumber) {
                options.push({
                    icon: "icon-join-right-black",
                    name: this.$t("panel.sms.crm-integrations-button"),
                    value: "CRM_integrations",
                    onclick: this.toggleIntegrations
                })
            }

            options.push({
                icon: "icon-delete-forever",
                name: "Delete chat",
                value: "delete",
                onclick: this.toggleDeleteSmsDialogPopup
            })

            options.push({
                icon: "icon-mark-chat-unread",
                name: this.$t("panel.sms.mark-as-unread"),
                value: "mark_as_unread",
                onclick: this.onClickMarkChatAsUnread
            })

            return options
        },

        isLaptop() {
            return this.windowWidth < 1536
        },

        isPhoneNumber() {
            return isPhoneNumber(this.selectedChat.number)
        }
    },
    methods: {
        ...mapActions('SMSes', [
            'getChats',
            'deleteChat',
            'updateChatLastSms',
            'updateChatNumberOfUnreadMessages',
            'createNewChat',
            'markChatAsUnread'
        ]),

        toggleDeleteSmsDialogPopup() {
            this.deleteSmsDialogPopup = !this.deleteSmsDialogPopup
        },
        showNewSmsDialogPopup() {
            this.newSmsDialogPopup = true
        },
        changeIsSearchPanelActive() {
            this.isSearchPanelActive = this.isSearchPanelActive ? false : true
        },
        closeNewSmsDialogPopup(toNumber) {
            this.newSmsDialogPopup = false

            if (!toNumber) {
                return
            }

            this.loadChats().then(() => {
                const chat = this.chats.find(chat => numbersAreMatched(chat.number, toNumber))
                this.allChatsAreLoaded = false
                if (this.selectedChat.id === chat.id) {
                    this.unselectChat()
                    this.$nextTick(() => {
                        this.selectChat(chat)
                    })
                } else {
                    this.selectChat(chat)
                }
            })
        },
        closeIntegrationsPopup() {
            this.integrationsPopup = false
        },
        toggleIntegrations() {
            if (this.windowWidth > 620) {
                this.integrationsInline = !this.integrationsInline
            } else {
                this.integrationsPopup = !this.integrationsPopup
            }
        },
        onDeleteSmsDialogPopup() {
            const chatId = this.selectedChat.id
            this.toggleDeleteSmsDialogPopup()
            this.unselectChat()
            this.deleteChat(chatId).then(() => {
                this.$nextTick(() => {
                    this.setLastPostObserver()
                })
            }).catch(() => {})
        },
        backToContactsList() {
            this.showChatsList = true
            this.showDialog = false
        },
        selectChat(chat) {
            this.selectedChat = chat

            if (this.windowWidth > 620) {
                this.showChatsList = true
                this.showDialog = true
            } else {
                this.showChatsList = false
                this.showDialog = true
            }

            if (this.selectedChat.is_unread) {
                this.markChatAsUnread({chatId: this.selectedChat.id, isUnread: false})
                    .catch(() => {})
            }
        },
        unselectChat() {
            this.selectedChat = {}

            if (this.windowWidth > 620) {
                this.showChatsList = true
                this.showDialog = false
            } else {
                this.showChatsList = true
                this.showDialog = false
            }
        },
        loadChats() {
            const prevChatsLength = this.chats.length
            this.chatsLoading = true
            return this.getChats(this.chatsLimit).then(() => {
                this.chatsLoading = false
                if (prevChatsLength < this.chats.length) {
                    this.$nextTick(() => {
                        this.setLastPostObserver()
                    })
                }
                if (this.chats.length < this.chatsLimit || prevChatsLength >= this.chats.length) {
                    this.allChatsAreLoaded = true
                }
            }).catch(() => this.chatsLoading = false)
        },
        setLastPostObserver() {
            if (this.lastPostObserver) {
                this.lastPostObserver.disconnect()
            }

            this.lastPostObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !this.allChatsAreLoaded) {
                        this.chatsLimit += 50
                        this.loadChats()
                    }
                })
            })

            const element = this.$refs.lastPost[0]?.$el
            if (element) {
                this.lastPostObserver.observe(element)
            }
        },
        onSmsReceived(socketData) {
            const chat = this.chats.find(chat => chat.id === socketData.chat.id)
            if (!chat) {
                this.createNewChat({
                    id: socketData.chat.id,
                    number: socketData.chat.number,
                    numberOfUnreadMessages: socketData.chat.number_of_unread_messages,
                    fromNumberId: socketData.chat.from_number_id,
                    lastSmsDate: socketData.sms.date,
                    lastSmsText: socketData.sms.text,
                    lastSmsType: SMS_TYPE_INBOUND
                })

                return
            }

            this.updateChatLastSms({chatId: socketData.chat.id, date: socketData.sms.date, text: socketData.sms.text, type: SMS_TYPE_INBOUND})
            if (this.selectedChat.id !== socketData.chat.id || this.activeTab !== 'sms') {
                this.updateChatNumberOfUnreadMessages({
                    chatId: socketData.chat.id,
                    numberOfUnreadMessages: chat.number_of_unread_messages + 1
                })
            }
        },
        onClickMarkChatAsUnread() {
            this.markChatAsUnread({chatId: this.selectedChat.id, isUnread: true})
                .catch(() => {})

            this.unselectChat()
        },
        formatNumber,
        showAddContactPopup,
        speedDial,
        showEditContactPopup,
        showTooltip,
        copyNameWithNumber
    }
}
</script>

<style scoped>

</style>