<template>
    <div class="active-call-keypad-speed-dial-list" :class="shadowClasses">
        <ul @scroll="updateShadows" ref="list">
            <li
                v-for="(item, idx) in items"
                @click="onSelectItem(item, idx)"
                :key="idx"
                :class="{'selected-item': idx === selectedItemIdx}"
            >
                <slot name="item" :item="item"></slot>
            </li>
        </ul>
        <span
            v-if="items.length === 0"
            class="no-results"
        >
            {{ $te(noItemsText) ? $t(noItemsText) : noItemsText }}
        </span>
    </div>
</template>

<script>
import Avatar from "../../../defaults/Avatar.vue";

export default {
    name: "ActiveCallKeypadSpeedDialList",
    components: {Avatar},
    props: {
        value: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default() {
                return []
            }
        },
        noItemsText: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            shadowClasses: ['hide-top-shadow'],
            selectedItemIdx: null
        }
    },
    watch: {
        items() {
            const element = this.$refs.list
            element.scrollTop = 0
            this.updateShadows()

            this.deselect()

            if (this.items.length === 1) {
                this.onSelectItem(this.items[0], 0)
            }
        }
    },
    methods: {
        updateShadows() {
            const element = this.$refs.list
            const scrollPosition = element.scrollTop / (element.scrollHeight - element.clientHeight);

            if (scrollPosition > 0) {
                // show all shadows
                this.shadowClasses = []
            }

            if (scrollPosition === 1) {
                // show only top shadow
                this.shadowClasses = ['hide-bottom-shadow']
            }

            if (scrollPosition === 0 || element.scrollHeight === element.clientHeight) {
                // show only bottom shadow
                this.shadowClasses = ['hide-top-shadow']
            }
        },
        onSelectItem(item, idx) {
            this.selectedItemIdx = idx
            this.$emit("input", item.number.toString())
        },
        deselect() {
            this.selectedItemIdx = null
            this.$emit("input", "")
        }
    }
}
</script>

<style scoped>

</style>