<template>
    <div class="base-phone-number-input" ref="wrapper">
        <div class="form-group" :class="{'filled': localPhoneNumber}">
            <base-country-select
                @input="onUpdateCountryCode"
                :value="localCountryCode"
                :width="dropdownWidth"
                :height="dropdownMaxHeight"
            ></base-country-select>

            <input
                @keypress="isValidKey"
                @input="onUpdatePhoneNumber"
                :value="formattedNumber"
                :class="{'is-invalid': numberIsInvalid}"
                class="has-label phone-number-input"
                :placeholder="$t('base-phone-number-input.placeholder')"
                :id="id"
                ref="phoneNumberInput"
                type="text"
            >
            <label :for="id">{{ $t('base-phone-number-input.placeholder') }}</label>
            <div class="invalid-feedback">
                <span v-show="numberIsInvalid">{{ $t('base-phone-number-input.number-invalid') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import BaseSelect from "./BaseSelect.vue";
import {mapGetters} from "vuex";
import {isValidNumberForCountry, sanitizePhoneNumber, getRegionCodeForNumber, formatNumberAsYouType, PHONE_NUMBER_FORMAT} from "../../utils/phoneNumbers";
import BaseCountrySelect from "./BaseCountrySelect.vue";

export default {
    name: "BasePhoneNumberInput",
    components: {BaseCountrySelect, BaseSelect, BaseButton},
    props: {
        phoneNumber: {
            type: String,
            default: ""
        },
        countryCode: {
            type: String,
            default: ""
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        dropdownMaxHeight: {
            type: Number,
            default: 350
        }
    },
    data() {
        return {
            localPhoneNumber: "",
            localCountryCode: "",
            search: "",
            dropdownWidth: 336
        }
    },
    created() {
        this.localPhoneNumber = this.phoneNumber
        this.localCountryCode = this.countryCode

        if (this.autofocus) {
            this.$nextTick(() => {
                this.$refs.phoneNumberInput.focus()

                if (this.$refs.wrapper) {
                    this.dropdownWidth = this.$refs.wrapper.getBoundingClientRect().width
                }
            })
        }
    },
    watch: {
        phoneNumber(val) {
            this.localPhoneNumber = val
        },
        countryCode(val) {
            this.localCountryCode = val
        }
    },
    computed: {
        ...mapGetters(['languages']),

        numberIsInvalid() {
            return this.localPhoneNumber && !isValidNumberForCountry(this.localPhoneNumber, this.localCountryCode)
        },
        id() {
            return this._uid
        },
        formattedNumber() {
            return formatNumberAsYouType(this.localPhoneNumber, this.localCountryCode, PHONE_NUMBER_FORMAT.international)
        }
    },
    methods: {
        onUpdatePhoneNumber(event) {
            this.localPhoneNumber = event.target.value
            this.$emit("update:phone-number", sanitizePhoneNumber(this.formattedNumber))

            const regionCode = getRegionCodeForNumber(sanitizePhoneNumber(this.formattedNumber))
            if (regionCode) {
                this.onUpdateCountryCode(regionCode)
            }
        },
        onUpdateCountryCode(code) {
            this.localCountryCode = code
            this.$emit("update:country-code", this.localCountryCode)
        },
        isValidKey(event) {
            const regex = new RegExp(/^([0-9]|[\+])$/)
            if (regex.test(event.key)) {
                return true
            } else {
                event.preventDefault()
            }
        }
    }
}
</script>

<style scoped>

</style>