<template>
    <div
        :class="{'integrations-button-active': active}"
        class="integrations-button"
        @click="onClick"
    >
        <span class="icon icon-join-right-black"></span>

        <span class="integrations-button-label">{{ $t("softphone.integrations-xl") }}</span>

        <span class="icon icon-chevron-right"></span>
    </div>
</template>

<script>
export default {
    name: "IntegrationsButton",
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick() {
            this.$emit("click")
        }
    }
}
</script>

<style scoped>

</style>