import breakpoints from "../../plugins/breakpoints";

const callDialPadMixin = {
    computed: {
        dialPadSize() {
            if (breakpoints.isTabletOrSmMobile) {
                return {inputHeight: 40, buttonSize: 48, buttonFontSize: 18, keyboardHeight: 192, keyboardWidth: 216}
            }

            return {inputHeight: 48, buttonSize: 60, buttonFontSize: 20, keyboardHeight: 246, keyboardWidth: 300}
        },
    }
}

export default callDialPadMixin