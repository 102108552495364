<template>
    <div class="call-settings-button-container">
        <div class="call-settings-button-container-row">
            <slot name="first-row"></slot>
        </div>

        <div class="call-settings-button-container-row">
            <slot name="second-row"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContainerCallSettingsButton"
}
</script>

<style scoped>

</style>