import Session from "../../models/softphone/Session";
import {mapGetters} from "vuex";
import {PERSON_SIZE} from "../../components/softphone/call/person/config";
import breakpoints from "../../plugins/breakpoints";

const personMixin = {
    props: {
        session: {
            type: Session
        },
        size: {
            type: String,
            default: PERSON_SIZE.DEFAULT
        }
    },
    computed: {
        ...mapGetters("integrations", ["contactNameInCrm"]),
        ...mapGetters('details', ['getContactByNumber']),

        _size() {
            if (breakpoints.isTabletOrSmMobile) {
                return PERSON_SIZE.SMALL
            }

            return this.size
        },

        contact() {
            return this.getContactByNumber(this.session.number)
        },

        contactName() {
            return this.isKnownContact ? this.contact.name || this.contactNameInCrm(this.session.number) : ''
        },

        isKnownContact() {
            return this.contact.id || this.contactNameInCrm(this.session.number)
        }
    }
}

export default personMixin