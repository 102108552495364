<template>
    <div
        @click="$emit('click')"
        :class="{'softphone-do-call-button-disabled': disabled}"
        class="softphone-do-call-button"
    >
        <span class="icon icon-call"></span>
    </div>
</template>

<script>
export default {
    name: "SoftphoneDoCallButton",
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>