<template>
    <div id="softphone" class="softphone">
        <!-- softphone states during a call -->
        <template v-if="isInCall">
            <softphone-state-outgoing v-if="isOutgoing"></softphone-state-outgoing>
            <softphone-state-outgoing-incoming v-if="isOutgoingIncoming"></softphone-state-outgoing-incoming>

            <softphone-state-incoming v-if="isIncoming"></softphone-state-incoming>
            <softphone-state-incoming-incoming v-if="isIncomingIncoming"></softphone-state-incoming-incoming>

            <softphone-state-active v-if="isActiveCall"></softphone-state-active>
            <softphone-state-active-multiple v-if="isActiveMultiple"></softphone-state-active-multiple>
            <softphone-state-active-outgoing v-if="isMultipleOutgoing"></softphone-state-active-outgoing>
            <softphone-state-active-incoming v-if="isIncomingSecond"></softphone-state-active-incoming>
        </template>

        <!-- loading softphone settings -->
        <softphone-dial-pad-skeleton
            v-else-if="settingsIsLoading"
        ></softphone-dial-pad-skeleton>

        <!-- softphone dialpad -->
        <softphone-dial-pad
            v-else
            v-model="dialNumber"
            @call="onCall"
        ></softphone-dial-pad>

        <integrations-popup
            v-if="integrationsPopup"
            @close="closeIntegrationsPopup"
            :width="integrationsPopupWidth"
            :position="breakpoints.isMobile ? 'bottom' : 'left'"
            :mount-to="breakpoints.isMobile ? '#softphone' : '#panel'"
            :height="breakpoints.isMobile ? '99%' : '100%'"
            :blackout="!breakpoints.isMobile"
            :numbers="integrationsPopupNumbers"
            :call-start-timestamps="integrationsPopupCallStartTimestamps"
            keep-crm-info
        ></integrations-popup>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    import SoftphoneDialPad from "./SoftphoneDialPad.vue";
    import SoftphoneStateActive from "./call/softphoneStates/active/SoftphoneStateActive.vue";
    import SoftphoneStateOutgoing from "./call/softphoneStates/outgoing/SoftphoneStateOutgoing.vue";
    import SoftphoneStateIncoming from "./call/softphoneStates/incoming/SoftphoneStateIncoming.vue";
    import SoftphoneStateActiveMultiple from "./call/softphoneStates/active/SoftphoneStateActiveMultiple.vue";
    import SoftphoneDialPadSkeleton from "./softphoneDialPadSkeleton/SoftphoneDialPadSkeleton.vue";
    import IntegrationsPopup from "../integrations/IntegrationsPopup.vue";
    import SoftphoneStateActiveIncoming from "./call/softphoneStates/active/SoftphoneStateActiveIncoming.vue";
    import SoftphoneStateOutgoingIncoming from "./call/softphoneStates/outgoing/SoftphoneStateOutgoingIncoming.vue";
    import SoftphoneStateIncomingIncoming from "./call/softphoneStates/incoming/SoftphoneStateIncomingIncoming.vue";
    import {isElectronExist} from "../../utils/electron";
    import {existEnabledCrms, onlyZapierEnabled} from "../../utils/integrations";
    import {getPhoneNumberDigitsOnly, isExtension} from "../../utils/phoneNumbers";
    import breakpoints from "../../plugins/breakpoints";
    import SoftphoneStateActiveOutgoing from "./call/softphoneStates/active/SoftphoneStateActiveOutgoing.vue";

    export default {
        name: "Softphone",
        props: {
            settingsIsLoading: {
                type: Boolean,
                default: true
            },
            softphoneSettings: {
                type: Object,
                default: {}
            }
        },
        components: {
            SoftphoneStateActiveOutgoing,
            SoftphoneStateIncomingIncoming,
            SoftphoneStateOutgoingIncoming,
            SoftphoneStateActiveIncoming,
            IntegrationsPopup,
            SoftphoneDialPadSkeleton,
            SoftphoneStateActiveMultiple,
            SoftphoneStateIncoming,
            SoftphoneStateOutgoing,
            SoftphoneStateActive,
            SoftphoneDialPad
        },
        data() {
            return {
                dialNumber: '',
                breakpoints
            }
        },
        computed: {
            ...mapGetters('softphone', [
                'isInCall',
                'isActiveCall',
                'isOutgoing',
                'isIncoming',
                'isIncomingSecond',
                'isActiveAddCall',
                'isAttendedTransfer',
                'isMultipleOutgoing',
                'isConference',
                'getSessions',
                'getSecondSession',
                'getElectronDialNumber',
                'isOutgoingIncoming',
                'isIncomingIncoming',
                'isCallEstablished',
                'isActiveMultiple',
                'integrationsPopup'
            ]),

            ...mapGetters('user', ['isIntegrationsEnabled', 'crmTenantName', 'email', 'calleridId']),
            ...mapGetters([
              'activeTab'
            ]),

            ...mapGetters('integrations', [
                'integrations',
                'callInfo',
                'zapierEnabled'
            ]),

            integrationsPopupNumbers() {
                return this.getSessions.map((session) => session.number)
            },
            integrationsPopupCallStartTimestamps() {
                const result = {}
                this.getSessions.forEach((session) => {
                    result[session.number] = session.startTimestamp
                })

                return result
            },
            integrationsPopupWidth() {
                if (breakpoints.isDesktop) {
                    return 506
                }

                if (breakpoints.isLaptopOrTablet) {
                    return 406
                }

                return Math.min(breakpoints.width - 30, 380)
            }
        },
        watch: {
            getElectronDialNumber(number) {
                if (number && number.length) {
                    this.dialNumber = number
                    this.updateElectronDialNumber("")
                }
            },

            getSessions() {
                if (this.getSessions.length === 0) {
                    this.closeIntegrationsPopup()
                    return
                }

                let number = ""
                if (this.getSecondSession) {
                    number = this.getSecondSession.number
                }

                if (
                    this.isInCall &&
                    !this.integrationsPopup &&
                    this.isIntegrationsEnabled &&
                    this.integrations.length &&
                    existEnabledCrms() &&
                    getPhoneNumberDigitsOnly(number).length &&
                    !isExtension(number) &&
                    !this.callInfo(number).length
                ) {
                    if (onlyZapierEnabled()) {
                        this.getZapierContactInfo(number)
                            .then((response) => {
                                if (response && Object.keys(response).length && this.isInCall && !breakpoints.isMobile) {
                                    this.showIntegrationPopup()
                                }
                            }).catch(() => {})
                        return
                    }

                    this.getCallInfo({
                        tenant: this.crmTenantName,
                        email: this.email,
                        number: number
                    }).then(() => {
                        let crmInfoExist = false
                        for (let item of this.callInfo(number)) {
                            crmInfoExist = Boolean(item.contacts.length)

                            if (crmInfoExist && this.isInCall && !breakpoints.isMobile) {
                                this.showIntegrationsPopup()
                            }

                            if (crmInfoExist) {
                                break
                            }
                        }

                        if (!crmInfoExist && this.zapierEnabled) {
                            return this.getZapierContactInfo(number)
                        }
                    }).then((response) => {
                        if (response && Object.keys(response).length && this.isInCall && !breakpoints.isMobile) {
                            this.showIntegrationPopup()
                        }
                    }).catch(() => {})
                }
            },

            settingsIsLoading() {
                if (this.settingsIsLoading) {
                    return
                }

                if (this.softphoneSettings.credentials) {
                    this.setSettings(this.softphoneSettings)
                    this.$nextTick(() => {
                        this.initPhone()
                    })
                }
            },

            "breakpoints.isMobile": function() {
                if (this.integrationsPopup) {
                    this.closeIntegrationsPopup()
                }
            }
        },
        created() {
            if (isElectronExist() && this.getElectronDialNumber) {
                this.dialNumber = this.getElectronDialNumber
                this.updateElectronDialNumber("")
            }
        },
        methods: {
            ...mapActions('softphone', [
                'doCall',
                'initPhone',
                'setSettings',
                'toggleNotification',
                'updateElectronDialNumber',
                'showIntegrationsPopup',
                'closeIntegrationsPopup'
            ]),

            ...mapActions(['createAlertMessage']),

            ...mapActions('integrations', ['getCallInfo', 'resetCallInfo', 'getZapierContactInfo']),

            onCall(number) {
                this.doCall({
                    number: number,
                    silent: false,
                    calleridId: this.calleridId
                })

                this.dialNumber = ""
            }
        }
    }
</script>

<style scoped>

</style>