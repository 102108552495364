import i18n from "../locales";

const APP_TYPES = Object.freeze({
    WINDOWS: "windows",
    MACOS: "macos",
    LINUX: "linux",
    IOS: "ios",
    ANDROID: "android"
})

export default class App {
    /**
     * @param {string} type
     * @param {string} link
     */
    constructor({type, link}) {
        this._type = type
        this._link = link
    }

    /**
     * @return {string}
     */
    get type() {
        return this._type
    }

    /**
     * @return {string}
     */
    get link() {
        return this._link
    }

    /**
     * @return {string}
     */
    get name() {
        return i18n.t(`top-bar.menu.apps.${this.type}.link`)
    }

    /**
     * @return {string}
     */
    get icon() {
        return this.isIos ? `icon-${APP_TYPES.MACOS}` : `icon-${this.type}`
    }

    /**
     * @return {boolean}
     */
    get isIos() {
        return this.type === APP_TYPES.IOS
    }

    /**
     * @return {boolean}
     */
    get isAndroid() {
        return this.type === APP_TYPES.ANDROID
    }

    /**
     * @return {boolean}
     */
    get isDesktopApp() {
        return [APP_TYPES.MACOS, APP_TYPES.LINUX, APP_TYPES.WINDOWS].includes(this.type)
    }

    /**
     * @return {boolean}
     */
    get isMobileApp() {
        return [APP_TYPES.ANDROID, APP_TYPES.IOS].includes(this.type)
    }
}

export {APP_TYPES}