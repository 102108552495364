<template>
    <base-popup
        v-if="value"
        @close="onClose"
        :title="title"
        :width="360"
        :height="390"
        popup-class="download-popup"
        :save-btn-visible="false"
    >
        <div class="download-popup-content">
            <img :src="qrCodeSrc" alt="QR code" width="200" height="200">

            <div class="download-popup-copy-section">
                <base-tooltip
                    v-model="copyButton.isCopied"
                    open-by="manual"
                    text="copy-message"
                    position="top"
                    textAlign="center"
                    :width="70"
                >
                    <template v-slot:activator>
                        <div @click="[copyValue(downloadUrl), showTooltip(copyButton, 'isCopied')]">
                            <span class="icon icon-copy"></span>
                            <span>{{ $t("copy-url") }}</span>
                        </div>
                    </template>
                </base-tooltip>
            </div>

            <base-button
                @click="redirectByClick(downloadUrl)"
                :text="openBtnText"
            ></base-button>
        </div>
    </base-popup>
</template>

<script>
import BasePopup from "../BasePopup.vue";
import BaseTooltip from "../BaseTooltip.vue";
import {showTooltip, copyValue} from "../../../utils/copy";
import BaseButton from "../BaseButton.vue";
import {redirectByClick} from "../../../utils/DOM";

export default {
    name: "DownloadPopup",
    components: {BaseButton, BasePopup, BaseTooltip},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        qrCodeSrc: {
            type: String
        },
        downloadUrl: {
            type: String
        },
        openBtnText: {
            type: String
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    methods: {
        onClose() {
            this.$emit("input", false)
        },
        showTooltip,
        copyValue,
        redirectByClick
    }
}
</script>

<style scoped>

</style>