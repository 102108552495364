import Vue from 'vue'
import {isTouchDevice} from "../utils/DOM";

const SCREENS = {
    smMobile: {start: 0, stop: 390},
    lgMobile: {start: 391, stop: 800},
    tablet: {start: 801, stop: 1100},
    laptop: {start: 1101, stop: 1536},
    desktop: {start: 1536, stop: Infinity}
}

const isMatchScreen = ({start, stop}) => {
    return window.innerWidth >= start && window.innerWidth <= stop
}

const getBreakpoints = () => {
    return {
        isSmMobile: isMatchScreen(SCREENS.smMobile),
        isLgMobile: isMatchScreen(SCREENS.lgMobile),
        isMobile: isMatchScreen(SCREENS.lgMobile) || isMatchScreen(SCREENS.smMobile),
        isTablet: isMatchScreen(SCREENS.tablet),
        isTabletOrSmMobile: isMatchScreen(SCREENS.tablet) || isMatchScreen(SCREENS.smMobile),
        isTabletOrMobile: isMatchScreen(SCREENS.tablet) || isMatchScreen(SCREENS.lgMobile) || isMatchScreen(SCREENS.smMobile),
        isLaptop: isMatchScreen(SCREENS.laptop),
        isLaptopOrTablet: isMatchScreen(SCREENS.laptop) || isMatchScreen(SCREENS.tablet),
        isDesktop: isMatchScreen(SCREENS.desktop),
        isDesktopOrLaptop: isMatchScreen(SCREENS.desktop) || isMatchScreen(SCREENS.laptop),
        isTabletOrMobileAndTouch: (isMatchScreen(SCREENS.tablet) || isMatchScreen(SCREENS.lgMobile) || isMatchScreen(SCREENS.smMobile)) && isTouchDevice()
    }
}

const breakpoints = Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight,
    ...getBreakpoints()
})

window.addEventListener('resize', () => {
    breakpoints.width = window.innerWidth
    breakpoints.height = window.innerHeight

    const newBreakpoints = getBreakpoints(window.innerWidth)
    Object.keys(newBreakpoints).forEach((key) => breakpoints[key] = newBreakpoints[key])
})

export default breakpoints