import {PhoneNumberUtil, PhoneNumberFormat, Error, getSupportedRegions, ShortNumberInfo, AsYouTypeFormatter} from "google-libphonenumber"
import store from '../store'

const MAX_INTERNAL_NUMBER_LENGTH = 5
const PHONE_NUMBER_UTIL = PhoneNumberUtil.getInstance()
const SHORT_INFO = ShortNumberInfo.getInstance()
const VALIDATION_RESULT = PhoneNumberUtil.ValidationResult

const t9Map = {
    a: 2,
    b: 2,
    c: 2,
    d: 3,
    e: 3,
    f: 3,
    g: 4,
    h: 4,
    i: 4,
    j: 5,
    k: 5,
    l: 5,
    m: 6,
    n: 6,
    o: 6,
    p: 7,
    q: 7,
    r: 7,
    s: 7,
    t: 8,
    u: 8,
    v: 8,
    w: 9,
    x: 9,
    y: 9,
    z: 9,
};

const PHONE_NUMBER_FORMAT = {
    original: "original",
    international: "international"
}

const _parsePhoneNumber = (number, country = null, keepRaw = false) => {
    let numberObj = null

    try {
        numberObj = keepRaw ? PHONE_NUMBER_UTIL.parseAndKeepRawInput(number, country) : PHONE_NUMBER_UTIL.parse(number, country)
    } catch (e) {
        return numberObj
    }

    return numberObj
}

const numbersAreMatched = (firstNum, secondNum) => {
    firstNum = firstNum.toString()
    secondNum = secondNum.toString()

    const regexLeaveOnlyLastTenDigits = /^.*(\d{10})$/

    const firstPhoneNumberSanitized = getPhoneNumberDigitsOnly(firstNum).replace(regexLeaveOnlyLastTenDigits, "$1")
    const secondPhoneNumberSanitized = getPhoneNumberDigitsOnly(secondNum).replace(regexLeaveOnlyLastTenDigits, "$1")

    const MatchType = PhoneNumberUtil.MatchType

    const mt = PHONE_NUMBER_UTIL.isNumberMatch(firstPhoneNumberSanitized, secondPhoneNumberSanitized)

    return mt === MatchType.NSN_MATCH || mt === MatchType.EXACT_MATCH || (mt === MatchType.SHORT_NSN_MATCH && firstPhoneNumberSanitized.length > MAX_INTERNAL_NUMBER_LENGTH && secondPhoneNumberSanitized.length > MAX_INTERNAL_NUMBER_LENGTH)
}

/**
 * Function for formatting a static phone number
 *
 * @param {string} number
 * @param {string} country
 * @return {string}
 */
const formatNumber = (number, country = store.getters["user/country"]) => {
    /**
     * Helps to return the result
     *
     * @param {string} number
     * @param {string} CIDPrefix
     * @return {string}
     */
    const appendCIDPrefix = (number, CIDPrefix) => CIDPrefix ? `${CIDPrefix}:${number}`: number

    let CIDPrefix = ""
    // check that the number contains a CID prefix
    if (number.indexOf(":") !== -1) {
        const numberSplitted = number.split(":")
        number = numberSplitted.pop()
        CIDPrefix = numberSplitted.join(":")
    }

    // if number contains letters, then return it as is
    if (/[a-zA-Z]/.test(number)) {
        return appendCIDPrefix(number, CIDPrefix)
    }

    const numberSanitized = sanitizePhoneNumber(number)
    if (numberSanitized.length <= MAX_INTERNAL_NUMBER_LENGTH) {
        return appendCIDPrefix(numberSanitized, CIDPrefix)
    }

    // try to parse number if it doesn't work, then return it as is
    const numberObj = _parsePhoneNumber(numberSanitized, country, true)
    if (!numberObj) {
        return appendCIDPrefix(numberSanitized, CIDPrefix)
    }

    if (
        PHONE_NUMBER_UTIL.isPossibleNumber(numberObj) &&
        PHONE_NUMBER_UTIL.isValidNumber(numberObj)
    ) {
        const originalFormat = PHONE_NUMBER_UTIL.formatInOriginalFormat(numberObj, country)
        const nationalFormat = PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.NATIONAL)
        const internationalFormat = PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.INTERNATIONAL)

        if (PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, country)) {
            // if number was originally written in the national format, then need to save this format, otherwise return the international format
            return appendCIDPrefix(originalFormat === nationalFormat ? nationalFormat : internationalFormat, CIDPrefix)
        }

        // if is possible and valid number but not for this country, then return the international format
        return appendCIDPrefix(internationalFormat, CIDPrefix)
    }

    if (
        PHONE_NUMBER_UTIL.isPossibleNumber(numberObj) &&
        (SHORT_INFO.isPossibleShortNumber(numberObj) || SHORT_INFO.isPossibleShortNumberForRegion(numberObj, country))
    ) {
        // if is possible short number, then return in original format
        return appendCIDPrefix(PHONE_NUMBER_UTIL.formatInOriginalFormat(numberObj, country), CIDPrefix)
    }

    // if number is not possible and not valid try adding + to the beginning of the number
    if (numberSanitized.indexOf('+') !== 0) {
        // try to parse number if it doesn't work, then return it as is
        const numberObjWithPlus = _parsePhoneNumber(`+${numberSanitized}`, country, true)
        if (!numberObjWithPlus) {
            return appendCIDPrefix(numberSanitized, CIDPrefix)
        }

        if (PHONE_NUMBER_UTIL.isPossibleNumber(numberObjWithPlus) && PHONE_NUMBER_UTIL.isValidNumber(numberObjWithPlus)) {
            // if number has become a possible and valid, then return the international format
            return appendCIDPrefix(PHONE_NUMBER_UTIL.format(numberObjWithPlus, PhoneNumberFormat.INTERNATIONAL), CIDPrefix)
        }
    }

    // otherwise return number as is
    return appendCIDPrefix(numberSanitized, CIDPrefix)
}

/**
 * Function for formatting a phone number entered in parts
 * Use this function if the phone number will be formatted in the input field
 *
 * @param {string} number
 * @param {string} country
 * @param {PHONE_NUMBER_FORMAT} phoneNumberFormat
 * @return {string}
 */
const formatNumberAsYouType = (number, country = store.getters["user/country"], phoneNumberFormat = PHONE_NUMBER_FORMAT.original) => {
    const formatter = new AsYouTypeFormatter(country)
    const numberSanitized = sanitizePhoneNumber(t9Translate(number))

    // add digits step by step using AsYouTypeFormatter
    let result = numberSanitized
    for (let i = 0; i < numberSanitized.length; ++i) {
        let inputChar = numberSanitized.charAt(i)
        result = formatter.inputDigit(inputChar)
    }
    result = result.trim()

    // try to parse number if it doesn't work, then return it as is
    let numberObj = _parsePhoneNumber(result, country, true)
    if (!numberObj) {
        return result
    }

    const isPossibleNumber = PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)
    const isValidNumber = PHONE_NUMBER_UTIL.isValidNumber(numberObj)
    const isPossibleShortNumberForRegion = SHORT_INFO.isPossibleShortNumberForRegion(numberObj, country)
    const isPossibleLocalOnly = PHONE_NUMBER_UTIL.isPossibleNumberWithReason(numberObj) === VALIDATION_RESULT.IS_POSSIBLE_LOCAL_ONLY
    if (PHONE_NUMBER_FORMAT.original === phoneNumberFormat && isPossibleNumber && (isValidNumber || isPossibleShortNumberForRegion || isPossibleLocalOnly)) {
        const originalFormat = PHONE_NUMBER_UTIL.formatInOriginalFormat(numberObj, country)
        const internationalFormat = PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.INTERNATIONAL)
        const isValidNumberForRegion = PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, country)

        // for example, if in AU country user type "61 xxx xxx xxx" need to return "+61 xxx xxx xxx"
        if (isValidNumber && isPossibleNumber && isValidNumberForRegion && originalFormat === internationalFormat.replace("+", "")) {
            return internationalFormat
        }

        return PHONE_NUMBER_UTIL.formatInOriginalFormat(numberObj, country)
    }

    // if an international format is selected, the number must be possible and valid
    if (PHONE_NUMBER_FORMAT.international === phoneNumberFormat && isPossibleNumber && isValidNumber) {
        return  PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.INTERNATIONAL)
    }

    // if number is not possible, not valid or not short, try adding + to the beginning of the number
    if (result.indexOf('+') !== 0) {
        // try to parse number if it doesn't work, then return it as is
        const numberObjWithPlus = _parsePhoneNumber(`+${result}`, country, true)
        if (!numberObjWithPlus) {
            return result
        }

        if (PHONE_NUMBER_UTIL.isPossibleNumber(numberObjWithPlus) && PHONE_NUMBER_UTIL.isValidNumber(numberObjWithPlus)) {
            // if number has become a possible and valid, then return the formatted number
            return PHONE_NUMBER_FORMAT.original === phoneNumberFormat ?
                PHONE_NUMBER_UTIL.formatInOriginalFormat(numberObjWithPlus, country) :
                PHONE_NUMBER_UTIL.format(numberObjWithPlus, PhoneNumberFormat.INTERNATIONAL)
        }
    }

    // otherwise return number as is
    return result
}

const getPhoneNumberDigitsOnly = (number) => {
    const regexLeaveOnlyDigits = /[^\d]/g

    return number.replace(regexLeaveOnlyDigits, "")
}

const getRegionCodeForNumber = (number) => {
    const numberSanitized = sanitizePhoneNumber(number)
    const numberObj = _parsePhoneNumber(numberSanitized)

    if (numberObj) {
        return PHONE_NUMBER_UTIL.getRegionCodeForNumber(numberObj)
    }

    return null
}

/**
 * Function clears phone number and leaves only digits and special symbols (*, #, +)
 * @param number
 * @returns {string}
 */
const sanitizePhoneNumber = (number) => {
    const regexLeaveOnlyDigitsAndSpecialSymbols = /[^\d,^\*,^\#,^\+]/g

    return number.replace(regexLeaveOnlyDigitsAndSpecialSymbols, "")
}

/**
 * Function return length of nationally significant number
 * @param {string} number
 * @returns {null|number}
 */
const getNationalNumberLength = (number) => {
    const numberSanitized = sanitizePhoneNumber(number)
    const numberWithPlus = numberSanitized.indexOf('+') !== 0 ? "+" + numberSanitized : numberSanitized
    let numberObj = null

    if (numberSanitized.length <= MAX_INTERNAL_NUMBER_LENGTH) {
        return number.length
    }

    numberObj = _parsePhoneNumber(numberWithPlus, PhoneNumberUtil.UNKNOWN_REGION_)
    if (numberObj && PHONE_NUMBER_UTIL.isValidNumber(numberObj) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)) {
        return numberObj.getNationalNumber().toString().length
    }

    const countryCode = store.getters["user/country"]
    numberObj = _parsePhoneNumber(numberSanitized, countryCode)
    if (numberObj && PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, countryCode) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)) {
        return numberObj.getNationalNumber().toString().length
    }

    // if number is in national format, but country in tenant settings is incorrect or invalid number
    return null
}

const isExtension = (number) => {
    return number.length <= store.getters["user/extensionLength"]
}

const t9Translate = (number) => {
    let arr = number.split("")
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].match(/[a-zA-z]/i)) {
            arr[i] = t9Map[arr[i].toLowerCase()]
        }
    }

    return arr.join("")
}

const isValidNumberForCountry = (number, code) => {
    if (!number || !code) {
        return false
    }

    const numberObj = _parsePhoneNumber(number, code)
    return numberObj && PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, code) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)
}

/**
 * Function checks that the string is a phone number
 * @param {string} number
 * @returns {boolean}
 */
const isPhoneNumber = (number) => {
    number = number.replace(/\s+/g, "")
    return /^\+?\d{2,18}$/.test(number)
}

export {
    numbersAreMatched,
    formatNumber,
    getPhoneNumberDigitsOnly,
    sanitizePhoneNumber,
    getNationalNumberLength,
    isExtension,
    t9Translate,
    isValidNumberForCountry,
    getRegionCodeForNumber,
    isPhoneNumber,
    formatNumberAsYouType,
    PHONE_NUMBER_FORMAT
}