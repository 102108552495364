<template>
    <div :class="classes" class="person-name-number-group">
        <span v-if="name" :style="nameStyle" :title="name" class="name">
            {{ name }}
        </span>

        <base-tooltip v-model="copyButton.isCopied" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
            <template v-slot:activator>
                <span
                    @click="[copyNumber(number), showTooltip(copyButton, 'isCopied')]"
                    :style="numberStyle"
                    class="number"
                >
                    {{ number }}
                </span>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import {copyNumber, showTooltip} from "../../../../utils/copy";

export default {
    name: "PersonNameNumberGroup",
    components: {BaseTooltip},
    props: {
        number: {
            type: String
        },
        name: {
            type: String,
            default: ""
        },
        baseFontSize: {
            type: String,
            default: "24px"
        },
        secondaryFontSize: {
            type: String,
            default: "20px"
        },
        align: {
            type: String,
            default: "center",
            validator(value) {
                return ["center", "left", "right"].includes(value)
            }
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        classes() {
            return {
                "person-name-number-group-align-left": this.align === "left",
                "person-name-number-group-align-center": this.align === "center",
                "person-name-number-group-align-right": this.align === "right",
                "person-name-number-group-name-exist": !!this.name
            }
        },

        nameStyle() {
            return {
                fontSize: this.baseFontSize
            }
        },

        numberStyle() {
            return {
                fontSize: !!this.name ? this.secondaryFontSize : this.baseFontSize
            }
        }
    },
    methods: {
        copyNumber,
        showTooltip
    }
}
</script>

<style scoped>

</style>