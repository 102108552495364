<template>
    <div class="softphone-state-default">
        <div class="softphone-state-default-main-container" :class="mainContainerClass">
            <slot>
                <!-- Contain collapsed person, time, statuses: "Outgoing call...", "Incoming call..."  -->
                <div class="softphone-state-default-top-container">
                    <slot name="top"></slot>
                </div>

                <!-- Contain person, manage call buttons, dtmfDialPad or active call keypad -->
                <div class="softphone-state-default-center-container">
                    <slot name="center"></slot>
                </div>

                <!-- Contain buttons like Hangup, Accept, etc. -->
                <div class="softphone-state-default-bottom-container">
                    <div
                        v-for="(col, idx) in bottomColumns"
                        :key="idx"
                        class="softphone-state-default-bottom-col"
                    >
                        <slot :name="'call_control_button_' + col"></slot>
                    </div>
                </div>
            </slot>
        </div>

        <div class="softphone-state-default-integrations-container">
            <base-tooltip text="internal-number-label.hint">
                <template v-slot:activator>
                    <span class="internal-number-label">
                        {{ $t("internal-number-label.text") }} {{ extension }}
                    </span>
                </template>
            </base-tooltip>

            <integrations-button
                v-if="canShowIntegrationsButton"
                @click="toggleIntegrationsPopup"
                :active="integrationsPopup"
            ></integrations-button>
        </div>
    </div>
</template>

<script>
import IntegrationsButton from "../IntegrationsButton.vue";
import {mapActions, mapGetters} from "vuex";
import {isExtension} from "../../../../utils/phoneNumbers";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";

export default {
    name: "SoftphoneStateDefault",
    components: {
        BaseTooltip,
        IntegrationsButton
    },
    props: {
        mainContainerClass: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapGetters("softphone", ["getSessions", "isInCall", "integrationsPopup"]),
        ...mapGetters('user', ['isIntegrationsEnabled', 'extension']),
        ...mapGetters('integrations', ['integrations']),

        bottomColumns() {
            return ["col-1", "col-2", "col-3"]
        },

        canShowIntegrationsButton() {
            const isExistNonInternalNumbers = this.getSessions.find((session) => !isExtension(session.number))

            return this.isInCall && this.isIntegrationsEnabled && this.integrations.length && isExistNonInternalNumbers
        }
    },
    methods: {
        ...mapActions("softphone", ["toggleIntegrationsPopup"])
    }
}
</script>

<style scoped>

</style>