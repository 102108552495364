<template>
    <div class="base-input" @click="onClick" :class="classes">
        <div class="base-input-wrapper">
            <input
                @input="onInput"
                :value="localValue"
                @blur="onBlur"
                :placeholder="placeholder ? $t(placeholder) : null"
                :type="inputType"
                :disabled="disabled"
                :readonly="readonly"
                :id="id"
                ref="input"
                @keypress="isNumber($event)"
            >
            <label v-if="label" :for="id">{{ $t(label) }}</label>

            <div v-if="icon" class="icon-wrapper" :class="iconColorClass" @click="iconClickAction">
                <span class="icon" :class="icon"></span>
            </div>

            <div v-if="type === INPUT_TYPE_PASSWORD" @click="togglePasswordType" class="icon-wrapper icon-wrapper-password">
                <span class="icon" :class="passwordIcon"></span>
            </div>
        </div>
    </div>
</template>

<script>
import {formatNumberAsYouType, sanitizePhoneNumber} from "../../utils/phoneNumbers";

const INPUT_TYPE_TEXT = "text"
const INPUT_TYPE_PASSWORD = "password"
const INPUT_TYPE_PHONE = "phone"

const INPUT_COLOR_DEFAULT = "default"
const INPUT_COLOR_LOGIN = "login"
const INPUT_COLOR_DARK = "dark"
const INPUT_COLOR_GRAY = "gray"

const ICON_CLICK_ACTION_CLEAR = "clear"

export default {
    name: "BaseInput",
    props: {
        value: {
            type: [String, Number],
            default: ""
        },
        type: {
            type: String,
            default: INPUT_TYPE_TEXT
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ""
        },
        iconColor: {
            type: String,
            default: ""
        },
        iconClick: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: INPUT_COLOR_DEFAULT
        },
        disabled: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        error: {
          type: Boolean,
          default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        focused: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: "",
            passwordIsVisible: false,
            INPUT_TYPE_PASSWORD
        }
    },
    created() {
        this.localValue = this.value
    },
    mounted() {
        if (this.focused) {
            this.$refs.input.focus()
        }
    },
    watch: {
        value() {
            this.localValue = this.value
        },
        localValue() {
            if (this.type === INPUT_TYPE_PHONE) {
                this.localValue = formatNumberAsYouType(this.localValue)
            }
        }
    },
    computed: {
        classes() {
            return {
                "base-input-error": this.error,
                "base-input-filled": this.value || this.localValue,
                "base-input-has-label": this.label,
                "base-input-rounded": this.rounded,
                "base-input-has-icon": this.icon,
                "base-input-type-password": this.type === INPUT_TYPE_PASSWORD,
                "base-input-color-default": this.color === INPUT_COLOR_DEFAULT,
                "base-input-color-login": this.color === INPUT_COLOR_LOGIN,
                "base-input-color-dark": this.color === INPUT_COLOR_DARK
            }
        },
        iconColorClass() {
            return {
                "base-input-icon-color-gray": this.iconColor === INPUT_COLOR_GRAY
            }
        },
        passwordIcon() {
            return this.passwordIsVisible ? "icon-visibility" : "icon-visibility-off"
        },
        inputType() {
            if (this.type === INPUT_TYPE_TEXT) {
                return INPUT_TYPE_TEXT
            }

            if (this.type === INPUT_TYPE_PASSWORD) {
                return this.passwordIsVisible ? INPUT_TYPE_TEXT : INPUT_TYPE_PASSWORD
            }
        },
        id() {
            return this._uid
        }
    },
    methods: {
        onInput(event) {
            this.localValue = event.target.value

            this.$nextTick(() => {
                this.$emit('input', this.type === INPUT_TYPE_PHONE ? sanitizePhoneNumber(this.localValue) : this.localValue)
            })
        },
        onBlur() {
            this.$emit("blur")
        },
        togglePasswordType() {
            this.passwordIsVisible = !this.passwordIsVisible
        },
        isNumber(evt) {
            if (this.type === INPUT_TYPE_PHONE) {
                const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#', '+'];
                const keyPressed = evt.key;
                if (!keysAllowed.includes(keyPressed)) {
                    evt.preventDefault()
                }
            }
        },
        onClick() {
            this.$emit("click")
        },
        iconClickAction() {
            if (this.iconClick === ICON_CLICK_ACTION_CLEAR) {
                this.localValue = ""
            }
        },
        focus() {
            if (this.$refs.input) {
                this.$refs.input.focus()
            }
        }
    }
}
</script>

<style scoped>

</style>