<template>
    <div :class="{'has-status': this.showStatus}" class="avatar-container" style="display: inline-block;">
        <div v-if="!!this.src" class="avatar exists-avatar"
             :class="sizeClasses"
             :style="{'background-image': 'url(' + src + ')', ...style}"></div>
        <div v-else-if="!!this.text && !!this.color && !!letter" class="avatar generated-avatar"
             :class="sizeClasses"
             :style="{'background-color': color, ...style}">{{letter}}</div>
        <div v-else-if="unknownPerson || !letter" class="avatar generated-avatar"
             :class="sizeClasses"
             :style="{'background-color': color, ...style}"><span class="icon icon-person"></span></div>
        <div v-else class="avatar generated-avatar"
             :class="sizeClasses" :style="style">{{ letter }}</div>
        <div  v-show="this.showStatus" class="status" :class="statusClasses"></div>
    </div>
</template>

<script>
const CONTACT_UNAVAILABLE = "contact-unavailable"
const CONTACT_IDLE = "contact-idle"
const CONTACT_BUSY = "contact-busy"

const USER_ONLINE = "user-online"
const USER_OFFLINE = "user-offline"
const USER_NOT_REGISTERED = "user-not-registered"

const AVATAR_SIZE_SMALL = "small"
const AVATAR_SIZE_MEDIUM = "medium"
const AVATAR_SIZE_BIG = "big"
const AVATAR_SIZE_EXTRA_BIG = "extraBig"

const FONT_COEFFICIENT = 2.2

export default {
    name: "Avatar",
    props: {
        size: {default: 'small'},
        src: {default: ''},
        text: {default: ''},
        color: {default: ''},
        showStatus: {default: false},
        status: {default: ''},
        callType: {default: ''},
        unknownPerson: {default: false},
        customSize: {default: null}
    },
    computed: {
        statusClasses() {
            return {
                'icon-done': this.status === USER_ONLINE ,
                'do-not-disturb': this.status === USER_OFFLINE,
                'icon-rectangle': this.status === USER_OFFLINE,
                'disabled': this.status === USER_NOT_REGISTERED,
                'contact-status-unavailable': this.status === CONTACT_UNAVAILABLE,
                'contact-status-idle': this.status === CONTACT_IDLE,
                'contact-status-busy': this.status === CONTACT_BUSY
            }
        },

        sizeClasses() {
            return {
                'small': this.size === AVATAR_SIZE_SMALL,
                'medium': this.size === AVATAR_SIZE_MEDIUM,
                'big': this.size === AVATAR_SIZE_BIG,
                'extra-big': this.size === AVATAR_SIZE_EXTRA_BIG
            }
        },

        style() {
            if (!this.customSize) {
                return {}
            }

            const fontSize = Math.max(Math.min(Math.ceil(this.customSize / FONT_COEFFICIENT), 38), 14)

            return {
                fontSize: `${fontSize}px`,
                width: `${this.customSize}px`,
                height: `${this.customSize}px`
            }
        },

        letter() {
            if (this.text && /^[A-Za-z]+/.test(this.text)) {
                return this.text.charAt(0).toUpperCase()
            }

            if (this.callType) {
                const letters = {
                    outgoing: "O",
                    incoming: "I",
                    missed: "M",
                    in: "I",
                    out: "O"
                }

                return letters[this.callType]
            }

            return null
        }
    }
}
</script>

<style scoped>

</style>