<template>
    <div :class="classes" class="softphone-button">
        <base-tooltip
            :disabled="!tooltip"
            :text="tooltip"
        >
            <template v-slot:activator>
                <div @click="onClick" class="softphone-button-button">
                    <slot></slot>
                </div>
            </template>
        </base-tooltip>

        <span
            v-if="label"
            :title="label"
            class="softphone-button-label"
        >{{ label }}</span>
    </div>
</template>

<script>
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import {SOFTPHONE_BUTTON_SIZE} from "./config";
import breakpoints from "../../../../plugins/breakpoints";

export default {
    name: "SoftphoneButton",
    components: {BaseTooltip},
    props: {
        size: {
            type: String,
            default: SOFTPHONE_BUTTON_SIZE.DEFAULT
        },
        label: {
            type: String,
            default: ""
        },
        tooltip: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                "softphone-button-size-default": this.size === SOFTPHONE_BUTTON_SIZE.DEFAULT && !breakpoints.isTabletOrSmMobile,
                "softphone-button-size-small": this.size === SOFTPHONE_BUTTON_SIZE.SMALL || breakpoints.isTabletOrSmMobile,
                "softphone-button-disabled": this.disabled
            }
        }
    },
    methods: {
        onClick() {
            if (this.disabled) {
                return
            }

            this.$emit('click')
        }
    }
}
</script>

<style scoped>

</style>