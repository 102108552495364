<template>
    <softphone-state-default>
        <template v-slot:top>
            <call-status-label animated>
                {{ $t("softphone-state-outgoing.call-status") }}
            </call-status-label>
        </template>

        <template v-slot:center>
            <person :session="getPrimarySession"></person>

            <container-call-settings-button>
                <!-- First row -->
                <template v-slot:first-row>
                    <call-settings-mute-button disabled></call-settings-mute-button>

                    <call-settings-hold-button disabled></call-settings-hold-button>

                    <call-settings-add-call-button disabled></call-settings-add-call-button>
                </template>
                <!-- First row end -->

                <!-- Second row -->
                <template v-slot:second-row>
                    <call-settings-keypad-button disabled></call-settings-keypad-button>

                    <call-settings-record-button disabled :show-tooltip="false"></call-settings-record-button>

                    <call-settings-transfer-button disabled></call-settings-transfer-button>
                </template>
                <!-- Second row end -->
            </container-call-settings-button>
        </template>

        <template v-slot:call_control_button_col-2>
            <call-controls-cancel-button
                @click="hangUp(getPrimarySession.id)"
            ></call-controls-cancel-button>
        </template>

    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Person from "../../person/Person.vue";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import CallStatusLabel from "../../CallStatusLabel.vue";
import ContainerCallSettingsButton from "../../container/ContainerCallSettingsButton.vue";
import CallSettingsMuteButton from "../../softphoneButtons/callSettings/CallSettingsMuteButton.vue";
import CallSettingsHoldButton from "../../softphoneButtons/callSettings/CallSettingsHoldButton.vue";
import CallSettingsAddCallButton from "../../softphoneButtons/callSettings/CallSettingsAddCallButton.vue";
import CallSettingsKeypadButton from "../../softphoneButtons/callSettings/CallSettingsKeypadButton.vue";
import CallSettingsRecordButton from "../../softphoneButtons/callSettings/CallSettingsRecordButton.vue";
import CallSettingsTransferButton from "../../softphoneButtons/callSettings/CallSettingsTransferButton.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";

export default {
    name: "SoftphoneStateOutgoing",
    components: {
        CallControlsCancelButton,
        CallSettingsTransferButton,
        CallSettingsRecordButton,
        CallSettingsKeypadButton,
        CallSettingsAddCallButton,
        CallSettingsHoldButton,
        CallSettingsMuteButton,
        ContainerCallSettingsButton,
        CallStatusLabel,
        SoftphoneStateDefault,
        Person
    },
    computed: {
        ...mapGetters('softphone', ['getPrimarySession']),
    },
    methods: {
        ...mapActions('softphone', ['hangUp'])
    }
}
</script>

<style scoped>

</style>