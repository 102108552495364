<template>
    <div class="voicemail-item">
        <div class="voicemail-item-header">
            <h3>{{ voicemail.name }}</h3>
            <div class="storage-used-wrapper">
                <base-tooltip
                    :width="240"
                    text="panel.services.voicemail.tooltip"
                    position="bottom"
                >
                    <template v-slot:activator>
                        <chip
                            :text="$t('panel.services.voicemail.of-storage-used', {count: voicemail.messages.length})"
                            :color="chipStorageUsedColor"
                            radius="13px"
                            class="storage-used-label"
                        ></chip>
                    </template>
                </base-tooltip>
            </div>
        </div>

        <div class="voicemail-item-content">
            <base-table
                v-if="voicemail.messages.length"
                :value="selectedRows"
                @input="onFullSelect"
                :headers="headers"
                :items="voicemail.messages"
                :rounded="breakpoints.isDesktop"
                :show-expanded-rows-by-default="breakpoints.isMobile"
                item-key="id"
                selectable
                multiple
                expandable-rows
            >
                <template v-slot:row="{item, toggleRow, isExpanded}">
                    <voicemail-item-table-row
                        @select="onSelectRow(item)"
                        @show-integrations="toggleRow(item.id)"
                        @message-listened="onMessageListened(voicemail.id, item.filename, item.dirname)"
                        :is-expanded="isExpanded"
                        :key="voicemail.id + '-' + item.id"
                        :item="item"
                        :voicemail="voicemail"
                        :selected="isSelectedRow(item.id)"
                    ></voicemail-item-table-row>
                </template>

                <template v-slot:expandedRow="{item}">
                    <voicemail-item-table-expanded-row
                        @message-listened="onMessageListened(voicemail.id, item.filename, item.dirname)"
                        :voicemail="voicemail"
                        :item="item"
                    ></voicemail-item-table-expanded-row>
                </template>
            </base-table>

            <p v-else class="no-voicemail-messages">{{ $t('panel.services.voicemail.no-voicemails-messages') }}</p>
        </div>
    </div>
</template>

<script>
import Chip from "../../../defaults/Chip.vue";
import BaseTable from "../../../defaults/baseTable/BaseTable.vue";
import VoicemailItemTableRow from "./VoicemailItemTableRow.vue";
import VoicemailItemTableExpandedRow from "./VoicemailItemTableExpandedRow.vue";
import {mapActions} from "vuex";
import {MESSAGE_DIRNAME_INBOX} from "../../../../store/modules/voicemails";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import breakpoints from "../../../../plugins/breakpoints";

export default {
    name: "VoicemailItem",
    components: {BaseTooltip, VoicemailItemTableExpandedRow, VoicemailItemTableRow, BaseTable, Chip},
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        voicemail: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            selectedRows: [],
            breakpoints
        }
    },
    watch: {
        value() {
            this.selectedRows = this.value
        }
    },
    computed: {
        headers() {
            if (breakpoints.isMobile) {
                return [
                    {title: this.$t('panel.services.voicemail.headers.status'), key: 'status', align: 'left', fr: 1},
                    {title: this.$t('panel.services.voicemail.headers.from'), key: 'callerid', align: 'left', fr: 1.5}
                ]
            }

            if (breakpoints.isTablet) {
                return [
                    {title: this.$t('panel.services.voicemail.headers.status'), key: 'status', align: 'left', fr: 1},
                    {title: this.$t('panel.services.voicemail.headers.from'), key: 'callerid', align: 'left', fr: 1},
                    {title: '', key: 'actions', align: 'left', fr: 1}
                ]
            }

            return [
                {title: this.$t('panel.services.voicemail.headers.status'), key: 'status', align: 'left', fr: 1},
                {title: this.$t('panel.services.voicemail.headers.from'), key: 'callerid', align: 'left', fr: 1},
                {title: this.$t('panel.services.voicemail.headers.time'), key: 'datetime', align: 'left', fr: 1},
                {title: '', key: 'actions', align: 'left', fr: breakpoints.isDesktop ? 3 : 1}
            ]
        },
        chipStorageUsedColor() {
            const count = this.voicemail.messages.length

            if (count <= 29) {
                return "light-green"
            }

            if (count >= 30 && count <= 49) {
                return "light-orange"
            }

            return "light-red"
        }
    },
    methods: {
        ...mapActions("voicemails", ["markAsListenedMessages"]),

        isSelectedRow(id) {
            return Boolean(this.selectedRows.find((item) => item.id === id))
        },
        onSelectRow(item) {
            if (this.isSelectedRow(item.id)) {
                this.selectedRows = this.selectedRows.filter((row) => row.id !== item.id)
            } else {
                this.selectedRows.push(item)
            }

            this.$emit('input', this.selectedRows)
        },
        onFullSelect(items) {
            this.selectedRows = items
            this.$emit('input', this.selectedRows)
        },
        onMessageListened(voicemailId, filename, dirname) {
            if (dirname === MESSAGE_DIRNAME_INBOX) {
                this.markAsListenedMessages([
                    {voicemail_id: Number(voicemailId), filename: filename, dirname: dirname}
                ]).catch(() => {})
            }
        }
    }
}
</script>

<style scoped>

</style>