<template>
    <softphone-state-default>
        <template v-slot:top>
            <person-collapsed :session="getPrimarySession"></person-collapsed>

            <call-status-label :top-offset="0" animated>
                {{ $t("softphone-state-incoming.call-status") }}
            </call-status-label>
        </template>

        <template v-slot:center>
            <person :session="getSecondSession" style="margin-bottom: 0"></person>

            <mute-ring-button
                @click="muteRingSoundClick"
                :active="muteBtnActive"
                style="margin-bottom: auto"
            ></mute-ring-button>
        </template>

        <template v-slot:call_control_button_col-1>
            <call-controls-cancel-button
                @click="declineSecondLine"
                :label="$t('softphone-state-incoming.buttons.decline')"
            ></call-controls-cancel-button>
        </template>

        <template v-slot:call_control_button_col-2>
            <call-controls-hangup-and-accept-button
                @click="hangUpAndAccept"
            ></call-controls-hangup-and-accept-button>
        </template>

        <template v-slot:call_control_button_col-3>
            <call-controls-hold-and-accept-button
                @click="holdAndAccept"
            ></call-controls-hold-and-accept-button>
        </template>
    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PersonCollapsed from "../../person/PersonCollapsed.vue";
import Person from "../../person/Person.vue";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import CallStatusLabel from "../../CallStatusLabel.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallControlsHangupAndAcceptButton from "../../softphoneButtons/callControls/CallControlsHangupAndAcceptButton.vue";
import CallControlsHoldAndAcceptButton from "../../softphoneButtons/callControls/CallControlsHoldAndAcceptButton.vue";
import MuteRingButton from "../../MuteRingButton.vue";

export default {
    name: "SoftphoneStateActiveIncoming",
    components: {
        MuteRingButton,
        CallControlsHoldAndAcceptButton,
        CallControlsHangupAndAcceptButton,
        CallControlsCancelButton,
        CallStatusLabel,
        SoftphoneStateDefault,
        Person,
        PersonCollapsed
    },
    data() {
        return {
            muteBtnActive: false
        }
    },
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'acceptCall',
            'sessionSetHold'
        ]),

        muteRingSoundClick() {
            this.muteBtnActive = !this.muteBtnActive
            if (this.muteBtnActive) {
                document.dispatchEvent(new CustomEvent('stopRingSound'))
            } else {
                document.dispatchEvent(new CustomEvent('playRingSound'))
            }
        },

        declineSecondLine() {
            this.hangUp(this.getSecondSession.id)
        },

        hangUpAndAccept() {
            this.acceptCall(this.getSecondSession.id)
            this.hangUp(this.getPrimarySession.id)
        },

        holdAndAccept() {
            this.acceptCall(this.getSecondSession.id)
            this.sessionSetHold({sessionid: this.getPrimarySession.id, manually: false})
        }
    }
}
</script>

<style scoped>

</style>