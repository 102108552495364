<template>
    <div
        v-long-click="{longClick: onLongClick, mouseUp: onMouseUp, timeout: 600}"
        class="dial-pad-keyboard-button"
    >
        <base-tooltip
            :disabled="!tooltip"
            :width="95"
            text="softphone-dial-pad.hint-for-plus"
            position="top"
        >
            <template v-slot:activator>
                <div
                    class="dial-pad-keyboard-button-button"
                    :class="classes"
                    :style="buttonStyle"
                >
                    <span
                        :style="signStyle"
                        class="dial-pad-keyboard-button-sign"
                    >{{ sign }}</span>

                    <span v-if="text" :style="textStyle" class="dial-pad-keyboard-button-text" v-html="text"></span>
                </div>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import {DIAL_PAD_COLOR, KEYBOARD_SIGNS_MAP} from "./config";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import breakpoints from "../../../plugins/breakpoints";

const FONT_COEFFICIENT = 2.2

export default {
    name: "DialPadKeyboardButton",
    components: {BaseTooltip},
    props: {
        sign: {
            type: String,
            validator(value) {
                return Object.keys(KEYBOARD_SIGNS_MAP).includes(value)
            }
        },
        size: {
            type: Number,
            default: 60
        },
        fontSize: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: DIAL_PAD_COLOR.LIGHT
        }
    },
    computed: {
        isAsteriskSign() {
            return this.sign === "*"
        },

        isPoundSign() {
            return this.sign === "#"
        },

        isZeroSign() {
            return this.sign === "0"
        },

        classes() {
            return {
                'dial-pad-keyboard-button-color-light': this.color === DIAL_PAD_COLOR.LIGHT,
                'dial-pad-keyboard-button-color-dark': this.color === DIAL_PAD_COLOR.DARK,

                'dial-pad-keyboard-button-pound-sign': this.isPoundSign
            }
        },

        buttonStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`
            }
        },

        signStyle() {
            if (this.isAsteriskSign) {
                // styles for centering the asterisk sign
                return {
                    fontSize: `${this.fontSize * 1.5}px`,
                    height: `${this.size / 2.4}px`,
                    lineHeight: 1,
                    verticalAlign: "middle"
                }
            }

            return {
                fontSize: `${this.fontSize}px`
            }
        },

        textStyle() {
            return {
                fontSize: `${Math.max(Math.min(Math.ceil(this.fontSize / FONT_COEFFICIENT), 13), 9)}px`
            }
        },

        text() {
            return KEYBOARD_SIGNS_MAP[this.sign]
        },

        tooltip() {
            return !breakpoints.isMobile && this.isZeroSign
        }
    },
    methods: {
        onMouseUp(isLongClick) {
            if (this.isZeroSign && isLongClick) {
                return
            }

            this.$emit('click', this.sign)
        },

        onLongClick() {
            if (this.isZeroSign) {
                this.$emit('click',  KEYBOARD_SIGNS_MAP[this.sign])
            }
        }
    }
}
</script>

<style scoped>

</style>