<template>
      <div :class="{'electron-exist': isElectronExist()}" class="menu">
          <div class="navigation">
              <div class="navigation-item softphone-item" :class="{'active': activeTab === 'softphone'}" @click="changeActiveTab('softphone')">
                  <div v-if="!isInCall || (isInCall && activeTab === 'softphone')">
                    <span class="icon icon-dialpad"></span>
                    <span class="navigation-item-title">{{ $t("top-bar.menu.softphone") }}</span>
                  </div>

                  <div v-if="isInCall && activeTab !== 'softphone'">
                      <span class="icon icon-headphones"></span>
                      <span class="navigation-item-title time">{{ callTime }}</span>
                  </div>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'phonebooks'}" @click="changeActiveTab('phonebooks')">
                      <span class="icon icon-stories"></span>
                      <span class="navigation-item-title">{{ $t("top-bar.menu.phonebooks") }}</span>
              </div>
              <div class="navigation-item sms-item" :class="{'active': activeTab === 'sms'}" @click="changeActiveTab('sms')">
                      <span class="icon icon-sms"></span>
                      <span class="navigation-item-title">{{ $t("top-bar.menu.sms") }}</span>
                      <red-dot v-if="unreadSmsExist"></red-dot>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'history'}" @click="changeActiveTab('history')">
                      <span class="icon icon-history"></span>
                      <span class="navigation-item-title">{{ $t("top-bar.menu.history") }}</span>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'services'}" @click="changeActiveTab('services')">
                  <span class="icon icon-supervisor"></span>
                  <span class="navigation-item-title">{{ $t("top-bar.menu.services") }}</span>
                  <red-dot v-if="isUnreadVoicemailMessagesExists"></red-dot>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'settings'}" @click="changeActiveTab('settings')">
                    <span class="icon icon-settings"></span>
                    <span class="navigation-item-title">{{ $t("top-bar.menu.settings") }}</span>
              </div>
              <apps-dropdown
                  v-if="windowWidth > 800 && apps.length && !isElectronExist()"
                  v-model="isOpenedAppsDropdown"
                  :width="172"
                  position="bottom"
              >
                  <template v-slot:activator>
                      <div class="navigation-item download-app">
                          <span class="navigation-item-title">{{ $t("top-bar.menu.download-app") }}</span>
                          <span v-if="isOpenedAppsDropdown" class="icon icon-expand-up"></span>
                          <span v-else class="icon icon-expand-down"></span>
                      </div>
                  </template>
              </apps-dropdown>
          </div>
      </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {addElectronListener, isElectronExist, sendToElectron} from "../../utils/electron";
import BaseSelect from "../defaults/BaseSelect.vue";
import RedDot from "../defaults/RedDot.vue";
import AppsDropdown from "../defaults/dropdowns/AppsDropdown.vue";

export default {
    name: "Menu",
    components: {
        AppsDropdown,
        RedDot,
        BaseSelect
    },
    data() {
        return {
            windowWidth: 0,
            isOpenedAppsDropdown: false
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        addElectronListener("settingsTrayClick", () => {
            this.changeActiveTab('settings');
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        isIncoming(value) {
            if (value && this.windowWidth <= 800) {
                this.changeActiveTab('softphone');
            }
        },

        unreadSmsExist(value) {
            sendToElectron("missedSms", value)
        }
    },
    computed: {
        ...mapGetters([
            'activeTab'
        ]),

        ...mapGetters('apps', ['apps']),

        ...mapGetters('softphone', ['isInCall', 'isIncoming', 'getPrimarySession']),

        ...mapGetters('SMSes', ['chats']),

        ...mapGetters('user', ['isUnreadVoicemailMessagesExists']),

        unreadSmsExist() {
            const chatWithUnreadMessages = this.chats.find((chat) => chat.number_of_unread_messages > 0)
            return Boolean(chatWithUnreadMessages)
        },

        callTime() {
            return this.isInCall && this.getPrimarySession ? this.getPrimarySession.time : "0:00"
        }
    },
    methods: {
        ...mapActions([
          'changeActiveTab'
        ]),
        handleResize() {
            this.windowWidth = window.innerWidth;
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>