<template>
    <div class="active-call-keypad-tab-selector">
        <div
            v-for="tab in tabs"
            @click="selectTab(tab.name)"
            :class="isCurrentTab(tab.name) ? 'active-call-keypad-tab-item-current' : ''"
            class="active-call-keypad-tab-item"
        >
            <span :class="tab.icon" class="icon"></span>

            <span v-if="isCurrentTab(tab.name) && showText" class="text">
                {{ $te(tab.text) ? $t(tab.text) : tab.text }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActiveCallKeypadTabSelector",
    props: {
        value: {
            type: String
        },
        tabs: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            showText: true
        }
    },
    methods: {
        isCurrentTab(tabName) {
            return this.value === tabName
        },
        selectTab(tabName) {
            this.showText = false
            setTimeout(() => {
                this.showText = true
            }, 250)

            this.$emit('input', tabName)
        }
    }
}
</script>

<style scoped>

</style>