import Api from '../../utils/api';
import {numbersAreMatched, isExtension} from "../../utils/phoneNumbers";
import{getColor} from "../../utils/colors";

export default {
    namespaced: true,
    state: {
        callHistory: [],
        callHistoryTotalCountRows: 0,
        phonebooks: [],
        contacts: [],
        extensions: [],
        defaultContact: {
            name: '',
            number: '',
            callerid_id: '',
            phonebook_id: '',
            email: '',
            image: null,
            avatar: '',
            color: '',
            id: null,
            is_new: 1
        },
        callTypes: {
            outgoing: "panel.call-history.call-types.outgoing",
            incoming: "panel.call-history.call-types.incoming",
            missed: "panel.call-history.call-types.missed"
        }
    },
    getters: {
        callHistory: (state) => {
            return state.callHistory;
        },
        callHistoryTotalCountRows: (state) => {
            return state.callHistoryTotalCountRows
        },
        phonebooks: (state) => {
            return state.phonebooks;
        },
        defaultContact: (state) => {
            return state.defaultContact;
        },
        contacts: (state) => {
            return state.contacts;
        },
        extensions: (state) => {
            return state.extensions
        },
        callTypes: (state) => {
            return state.callTypes
        },
        getContactByNumber: (state) => number => {
            let contact = state.contacts.find((contact) => {
                return numbersAreMatched(contact.number, number)
            })
            if (contact) {
                return contact
            }

            return  state.defaultContact
        },
        getContactsByNumber: (state) => number => {
            let contacts = state.contacts.filter(contact => numbersAreMatched(contact.number, number))
            if (contacts.length) {
                return contacts
            }

            return  [state.defaultContact]
        },
        getDefaultPhonebook: (state) => {
            let defaultPhonebook = state.phonebooks.find(phonebook => phonebook.global && phonebook.default && !phonebook.readonly)
            if (defaultPhonebook) {
                return defaultPhonebook
            }

            return state.phonebooks.find(phonebook => !phonebook.global)
        },
        getIsReadOnlyPhonebook: (state) => (id) => {
            const phonebook = state.phonebooks.find((phonebook) => phonebook.id === id)
            return phonebook.readonly
        }
    },
    actions: {
        loadCallHistory: (context, params) => {
            return Api()
                .get('/get_history', {
                    params
                }).then(response => {
                    context.commit('setCallHistory', response.data.rows);
                    context.commit('setCallHistoryTotalCountRows', response.data.total_count)
                    context.commit('bindCallHistoryContacts');
                }).catch(() => {})
        },
        loadPhonebooks: ({commit, rootGetters}, phonebookId) => {
            return new Promise((resolve, reject) => {
                Api().get('/get_phonebooks')
                    .then(response => {
                        let extensions = []

                        response.data.contacts.forEach(function (item) {
                            item.is_new = 0;
                            item.color = getColor(item.name)
                            item.status = 'contact-unavailable'

                            if (item.is_linked_to_user) {
                                extensions.push(item.number)
                            }
                        })
                        commit('setPhonebooks', response.data.phonebooks)
                        commit('setContacts', response.data.contacts)
                        commit('setExtensions', extensions)
                        resolve(response.data)
                    })
                    .catch(() => reject())
            })
        },
        removeContact: (context, contact) => {
            Api().post('/remove_contact', {contact_id: contact.id})
                .then(response => {
                    context.commit('deleteContact', contact)
                })
                .then(response => {
                    context.commit('bindCallHistoryContacts');
                })
                .catch(() => {})
        },
        createContact: (context, contact) => {
            const formData = new FormData()
            if (contact.image) {
                formData.append('image', contact.image)
            }
            formData.append('phonebook_id', contact.phonebook_id)
            formData.append('name', contact.name)
            formData.append('number', contact.number)
            formData.append('email', contact.email)
            Api().post('/create_contact', formData)
                .then(response => {
                    contact.id = response.data.contact_id;
                    contact.status = 'contact-unavailable';
                    contact.color = getColor(contact.name);
                    contact.is_new = 0;
                    context.commit('addContact', {contact})

                    if (response.data.is_linked_to_user) {
                        context.commit('addExtension', contact.number)
                    }
                })
                .then(response => {
                    context.commit('bindCallHistoryContacts');
                })
                .catch(() => {})
        },
        editContact: (context, contact) => {
            var formData = new FormData();
            if (contact.image === null) {
                formData.append('delete_avatar', 1)
            } else if (contact.image) {
                formData.append('image', contact.image)
            }

            if (contact.email) {
                formData.append('email', contact.email)
            }

            formData.append('contact_id', contact.id)
            formData.append('phonebook_id', contact.phonebook_id)
            formData.append('name', contact.name)
            formData.append('number', contact.number)

            Api().post('/update_contact', formData)
                .then(response => {
                    context.commit('updateContact', contact)

                    if (response.data.is_linked_to_user) {
                        context.commit('addExtension', contact.number)
                    }
                })
                .then(response => {
                    context.commit('bindCallHistoryContacts');
                })
                .catch(() => {})
        }
    },
    mutations: {
        setCallHistory: (state, callHistory) => {
            state.callHistory = callHistory;
        },

        setCallHistoryTotalCountRows: (state, count) => {
            state.callHistoryTotalCountRows = count
        },
        setPhonebooks: (state, phonebooks) => {
            state.phonebooks = phonebooks;
        },
        setContacts: (state, contacts) => {
            state.contacts = contacts;
        },
        setExtensions: (state, extensions) => {
            state.extensions = [...new Set(extensions)]
        },
        addExtension: (state, extension) => {
            if (state.extensions.indexOf(extension) === -1) {
                state.extensions.push(extension)
            }
        },
        deleteContact: (state, contact) => {
            var deletedContactIndex = state.contacts.findIndex(item => item.id == contact.id);

            if (deletedContactIndex !== -1) {
                state.contacts.splice(deletedContactIndex, 1);
            }
        },
        addContact: (state, {contact}) => {
            state.contacts.push(contact);
        },
        updateContact: (state, updatedContact) => {
            var contact = state.contacts.find(contact => contact.id === updatedContact.id);
            if (contact) {
                if (contact.phonebook_id != updatedContact.phonebook_id) {
                    var deletedContactIndex = state.contacts.findIndex(item => item.id == contact.id);

                    if (deletedContactIndex !== -1) {
                        state.contacts.splice(deletedContactIndex, 1);
                    }

                    state.contacts.push(updatedContact);
                } else {
                    Object.assign(contact, updatedContact);
                }
            }
        },
        bindCallHistoryContacts: (state) => {
            let updatedCallHistory = [];
            state.callHistory.forEach(function (item) {
                item.contact = null;
                let contact = state.contacts.find((contact) => {
                    return numbersAreMatched(contact.number, item.number)
                })
                if (contact) {
                    item.contact = contact;
                }
                updatedCallHistory.push(item);
            });
            state.callHistory = updatedCallHistory;
        }
    }
}