import { Stopwatch } from '../../utils/stopwatch';
import {formatNumber, sanitizePhoneNumber} from '../../utils/phoneNumbers';
import dayjsOverride from "../../helpers/dayjsOverride";
import i18n from "../../locales";
import Api from "../../utils/api";
import Session from "../../models/softphone/Session";

const STATUS_ACTIVE = 'active';
const STATUS_OUTGOING = 'outgoing';
const STATUS_INCOMING = 'incoming';

const SESSION_DIRECTION_IN = 'in'
const SESSION_DIRECTION_OUT = 'out'

export default {
    namespaced: true,
    state: {
        inCall: false,
        status: '',
        isMuted: false,
        sessions: [],
        isAttendedTransfer: false,
        isAddToCall: false,
        isConference: false,
        callActiveID: null,
        addToCallNumber: '',
        transferCallNumber: '',
        notificationEnable: false,
        credentials: {},
        isRegistered: false,
        isUseOpenSips: false,
        electronDialNumber: '', // used when desktop app opened by tel: link
        mediaDevices: [],
        isCallRecordingAllowed: false,
        enabledInboundAutoRecording: false,
        enabledOutboundAutoRecording: false,
        integrationsPopup: false
    },
    actions: {
        initPhone: (context) => {
            console.log('-------------initPhone------------');

            const credentials = context.getters.getCredentials;
            const user = {User: credentials.sip_login, Pass: credentials.sip_password, Realm: credentials.sip_server, Display: credentials.display_name, WSServer: credentials.ws_server, isUseOpenSips: context.getters.getIsUseOpenSips};

            if (typeof(user) === 'undefined') {
                console.log('initPhone error - no user')
            }

            const eventInit = new CustomEvent("connectUA", { "detail": { user: user } });
            document.dispatchEvent(eventInit);
        },
        setSettings: (context, settings) => {
            context.commit('setNotificationEnable', Boolean(settings.notification_enabled))
            context.commit('setIsUseOpenSips', Boolean(settings.is_use_open_sips))
            context.commit('setIsCallRecordingAllowed', Boolean(settings.is_call_recording_allowed))
            context.commit('setCredentials', settings.credentials)
        },
        loadAutoCallRecordingsStates: (context) => {
            return new Promise((resolve, reject) => {
                Api().get('/get_auto_call_recordings_states')
                    .then(response => {
                        context.commit('setEnabledInboundAutoRecording', Boolean(response.data.enabled_inbound_auto_recording))
                        context.commit('setEnabledOutboundAutoRecording', Boolean(response.data.enabled_outbound_auto_recording))

                        const sessions = context.getters.getSessions
                        if (sessions.length > 0) {
                            sessions.forEach((session) => {
                                let isRecording = session.isRecording

                                if (session.direction === SESSION_DIRECTION_OUT && context.getters.isEnabledOutboundAutoRecording) {
                                    isRecording = true
                                }

                                if (session.direction === SESSION_DIRECTION_IN && context.getters.isEnabledInboundAutoRecording) {
                                    isRecording = true
                                }

                                context.commit("updateSessionIsRecording", {isRecording, sessionId: session.id})
                            })
                        }
                        resolve()
                    }).catch(() => reject())
            })
        },
        toggleNotification: (context, isEnabled) => {
            Api().post('/update_notification_status', {is_enabled: Number(isEnabled)})
                .then(response => {
                    context.commit('setNotificationEnable', isEnabled);
                })
                .catch(() => {})
        },
        sessionTerminated: (context, sessionId) => {
            const sessions = context.getters.getSessions;
            const session = sessions.find((sess) => sess.id === sessionId);
            const activeSession = sessions.find((sess) => sess.id !== sessionId);
            const callStatus = context.getters.getStatus
            const callActiveID = context.getters.getCallActiveID

            if (session.timer) {
                session.timer.stop();
                session.timer = null;
            }

            if (context.getters.isOutgoingIncoming) {
                context.commit('deleteCallSession', sessionId)
                context.dispatch('integrations/resetCallInfo', [session.number], {root: true})

                if (activeSession.accept) {
                    context.commit('updateCallStatus', STATUS_ACTIVE)
                    return
                }

                if (session.status === STATUS_INCOMING) {
                    return
                }

                if (session.status === STATUS_OUTGOING && activeSession.status === STATUS_INCOMING) {
                    context.commit('updateCallStatus', STATUS_INCOMING)
                }

                return
            }

            if (context.getters.isIncomingIncoming) {
                context.commit('deleteCallSession', sessionId)
                context.dispatch('integrations/resetCallInfo', [session.number], {root: true})

                if (activeSession.accept) {
                    context.commit('updateCallStatus', STATUS_ACTIVE)
                    return
                }

                if (activeSession.status === STATUS_INCOMING) {
                    context.commit('updateCallStatus', STATUS_INCOMING)
                }

                return
            }

            if (sessionId === callActiveID && sessions.length > 1) {
                if (context.getters.isIncomingSecond && activeSession.status === STATUS_INCOMING && !activeSession.accept) {
                    context.commit('updateCallStatus', STATUS_INCOMING)
                }

                context.commit('deleteCallSession', sessionId);
                context.dispatch('integrations/resetCallInfo', [session.number], {root: true})
                context.commit('updateAddToCallState', false);
                context.commit('updateAttendedTransferState', false);
                context.commit('updateConferenceCallState', false);
                context.commit('updateCallActiveID', activeSession.id)

                if (activeSession.isOnHold) {
                    context.dispatch("sessionUnhold", {sessionid: activeSession.id, manually: false})
                }
                return;
            }

            if (sessions.length === 1 || sessionId === context.getters.getCallActiveID) {
                context.commit('updateCallIsMuted', false);
                context.commit('updateAddToCallState', false);
                context.commit('updateAttendedTransferState', false);
                context.commit('updateConferenceCallState', false);
                context.dispatch('endCall', sessionId)
                return
            }

            if (sessions.length > 1) {
                const primarySession = context.getters.getPrimarySession;

                context.commit('updateCallStatus', STATUS_ACTIVE);
                if (primarySession.isOnHold) {
                    context.dispatch("sessionUnhold", {sessionid: primarySession.id, manually: false})
                }
            }

            if (context.getters.getIsAttendedTransfer) {
                context.commit('updateCallStatus', STATUS_ACTIVE);
                context.commit('updateAttendedTransferState', false);
            }

            if (context.getters.getIsAddCall) {
                context.commit('updateCallStatus', STATUS_ACTIVE);
                context.commit('updateAddToCallState', false);
            }

            if (context.getters.getIsConference) {
                context.commit('updateCallStatus', STATUS_ACTIVE);
                context.commit('updateConferenceCallState', false);
            }

            context.commit('deleteCallSession', sessionId);
            context.dispatch('integrations/resetCallInfo', [session.number], {root: true})
        },
        conferenceTerminated: (context) => {
            let sessionsIds = []

            context.getters.getSessions.forEach((session) => {
                if (session.timer) {
                    session.timer.stop();
                    session.timer = null;
                }
                sessionsIds.push(session.id)
            })

            context.commit('updateCallIsMuted', false);
            context.commit('updateAddToCallState', false);

            sessionsIds.forEach((id) => {
                context.commit('updateConferenceCallState', false);
                context.dispatch('endCall', id)
            })
        },
        endCall: (context, sessionId) => {
            const sessions = context.getters.getSessions;
            const session = sessions.find((sess) => sess.id === sessionId);
            context.dispatch('integrations/resetCallInfo', [session.number], {root: true})

            context.commit('deleteCallSession', sessionId);
            context.commit('updateCallStatus', '');
            context.commit('updateCallActiveID', null);
            context.commit('updateInCall', false);
        },
        sessionEstablished: (context, sessionId) => {
            const session = context.getters.getSessions.find((sess) => sess.id === sessionId);

            if (!session.timer) {
                session.timer = new Stopwatch();
                session.time = '0:00';
                session.timer.start((clock) => {
                    context.commit('updateCallTime', {sessionId: sessionId, time: dayjsOverride(clock).format('m:ss'), callDuration: clock})
                });
            }

            if (context.getters.isOutgoingIncoming && context.getters.getSecondSession.id !== sessionId) {
                context.dispatch("hangUp", context.getters.getSecondSession.id)
            }

            if (context.getters.isIncomingSecond) {
                context.commit('updateAddToCallState', true);
            }

            context.commit('updateCallActiveID', sessionId)
            context.commit('updateSessionStatus', {status: STATUS_ACTIVE, sessionid: sessionId})
            context.commit('updateCallStatus', STATUS_ACTIVE)
            context.commit('updateSessionAnswerTimestamp', {timestamp: Date.now(), sessionId})
        },
        incomingCall: (context, {sessionData, callerIdNum, callerIdName}) => {
            sessionData.direction = SESSION_DIRECTION_IN
            context.commit('createCallSession', {data: sessionData, isRecording: context.getters.isEnabledInboundAutoRecording, callerIdNum, callerIdName})

            if (!context.getters.isInCall) {
                context.commit('updateInCall', true)
                context.commit('updateCallStatus', STATUS_INCOMING)
            }
        },
        outgoingCall: (context, {sessionData, callerIdNum, callerIdName}) => {
            sessionData.direction = SESSION_DIRECTION_OUT
            context.commit('createCallSession', {data: sessionData, isRecording: context.getters.isEnabledOutboundAutoRecording, callerIdNum, callerIdName})
            context.commit('updateInCall', true)
            context.commit('updateCallStatus', STATUS_OUTGOING)
        },
        updateCallStatus: (context, value) => {
            context.commit('updateCallStatus', value);
        },
        updateSessionStatus: (context, {status, sessionid}) => {
            context.commit('updateSessionStatus', {status, sessionid});
        },
        updateSessionAccept: (context, {accept, sessionId}) => {
            context.commit('updateSessionAccept', {accept, sessionId})
        },
        updateSessionIsCanceled: (context, {isCanceled, sessionId}) => {
            context.commit('updateSessionIsCanceled', {isCanceled, sessionId})
        },
        updateCallTime: (context, {sessionId, time, callDuration}) => {
            context.commit('updateCallTime', {sessionId, time, callDuration});
        },
        updateCallIsMuted: (context, value) => {
            context.commit('updateCallIsMuted', value);
        },
        sessionSetHold: (context, {sessionid, manually}) => {
            const session = context.getters.getSessions.find(sess => sess.id === sessionid && sess.isOnHold)
            if (!session) {
                const eventCallHold = new CustomEvent('holdSession', {"detail": {sessionId: sessionid}});
                document.dispatchEvent(eventCallHold);
                context.commit('updateCallIsOnHold', {isOnHold: true, sessionid: sessionid, manually: manually});
            }
        },
        sessionUnhold: (context, {sessionid, manually}) => {
            const session = context.getters.getSessions.find(sess => sess.id === sessionid)

            if ((session.isManuallySetOnHold && manually) || !session.isManuallySetOnHold) {
                const eventCallUnHold = new CustomEvent('unholdSession', {"detail": {sessionId: sessionid}});
                document.dispatchEvent(eventCallUnHold);
                context.commit('updateCallIsOnHold', {isOnHold: false, sessionid: sessionid, manually: false});
            }
        },
        resetIsManuallySetOnHold: (context) => {
            context.getters.getSessions.map((sess) => {
                context.commit('updateCallIsOnHold', {isOnHold: sess.isOnHold, sessionid: sess.id, manually: false});
            })
        },
        updateInCall: (context, value) => {
            context.commit('updateInCall', value);
        },
        createCallSession: (context, {data, isRecording, callerIdNum, callerIdName}) => {
            context.commit('createCallSession', {data, isRecording, callerIdNum, callerIdName});
        },
        deleteCallSession: (context, sessionId) => {
            context.commit('deleteCallSession', sessionId);
        },
        updateIsRegistered: (context, value) => {
            context.commit('setIsRegistered', value)
        },
        updateElectronDialNumber: (context, number) => {
            context.commit('setElectronDialNumber', number)
        },
        hangUp: (context, sessionId) => {
            console.log('sipHangUp');
            const sessions = context.getters.getSessions;

            if (sessions.length) {
                const eventHangUp = new CustomEvent('hangUp', {"detail": { sessionId: sessionId }});
                document.dispatchEvent(eventHangUp);
            }
        },
        hangUpConference: (context) => {
            document.dispatchEvent(new CustomEvent('terminateConference'))
        },
        hold: (context) => {
            const session = context.getters.getPrimarySession;

            if (session.isOnHold === true) {
                context.dispatch("sessionUnhold", {sessionid: session.id, manually: true})
            } else {
                context.dispatch("sessionSetHold", {sessionid: session.id, manually: true})
            }
        },
        mute: (context) => {
            const isMuted = context.getters.getIsMuted;

            context.commit('updateCallIsMuted', !isMuted);
            const eventUnMute = new CustomEvent('mute', {'detail': { isMuted: !isMuted, isConference: context.getters.isConference }});
            document.dispatchEvent(eventUnMute);
        },
        /**
         * @param context
         * @param {string} number - the number to which the call will be made
         * @param {boolean} silent - is means no events will be emitted except silentSessionTerminated
         * @param {number} calleridId - the callerID to use when calling
         * @param {number} replacedNumber - the number that will be displayed during the call, by default is {number}
         */
        doCall: (context, {number, silent, calleridId, replacedNumber}) => {
            const callerId = context.rootGetters["user/callerIds"].find((callerId) => callerId.id === calleridId)
            if (context.getters.getIsRegistered) {
                const eventCall = new CustomEvent('call', {"detail": {
                    target: sanitizePhoneNumber(number),
                    silent: silent,
                    callerId: callerId ? callerId.num : null,
                    replacedNumber: replacedNumber ? sanitizePhoneNumber(replacedNumber) : null
                }});
                document.dispatchEvent(eventCall);
            }
        },
        acceptCall: (context, sessionId) => {
            const sessions = context.getters.getSessions

            if (sessions.length) {
                context.dispatch("updateSessionAccept", {accept: true, sessionId: sessionId})
                const eventAccept = new CustomEvent('acceptCall', {"detail": { sessionId: sessionId }});
                document.dispatchEvent(eventAccept);
            }
        },
        sendDTMF: (context, symbol) => {
            const regex = new RegExp(/^([0-9]|[\*,\#,\+])$/);
            if (regex.test(symbol)) {
                const session = context.getters.getPrimarySession
                if (session) {
                    const eventSendDTMF = new CustomEvent('sendDTMF', {"detail": { symbol: symbol, sessionId: session.id }});
                    document.dispatchEvent(eventSendDTMF);

                    if (session.prevSymbol === "#") {
                        if (symbol === "0") {
                            context.commit("updateSessionIsRecording", {isRecording: false, sessionId: session.id})
                        } else if (symbol === "1") {
                            context.commit("updateSessionIsRecording", {isRecording: true, sessionId: session.id})
                        }
                    }

                    context.commit("updatePrevSymbol", {prevSymbol: symbol, sessionId: session.id})
                }
            }
        },
        blindTransferCall: (context, number) => {
            const eventBlindTransfer = new CustomEvent('blindTransfer', {"detail": { target: sanitizePhoneNumber(number), sessionId: context.getters.getCallActiveID }});
            document.dispatchEvent(eventBlindTransfer);
        },
        swapHoldSession: (context) => {
            const sessions = context.getters.getSessions;
            const sessionUnHold = Object.values(sessions).find( (sess) => sess.isOnHold);
            const sessionHold = Object.values(sessions).find( (sess) => !sess.isOnHold);

            context.dispatch("resetIsManuallySetOnHold")

            context.dispatch("sessionSetHold", {sessionid: sessionHold.id, manually: false})
            context.dispatch("sessionUnhold", {sessionid: sessionUnHold.id, manually: false})

            context.commit('updateCallActiveID', sessionUnHold.id)
        },
        prepareAttendedTransferCall: (context, number) => {
            const sessionsIdHold = context.getters.getPrimarySession.id;

            context.dispatch("sessionSetHold", {sessionid: sessionsIdHold, manually: false})

            context.dispatch("doCall", {number, silent: false, calleridId: context.rootGetters["user/calleridId"]})

            context.commit('updateInCall', true);
            context.commit('updateAttendedTransferState', true);
        },
        attendedTransferCall: (context) => {
            const replacementSessionId = context.getters.getSecondSession.id;

            context.commit('updateAttendedTransferState', false);

            const eventAttendedTransfer = new CustomEvent("attendedTransferCall", {'detail': { replacementSessionId: replacementSessionId, sessionId: context.getters.getCallActiveID }});
            document.dispatchEvent(eventAttendedTransfer);
        },
        addToCall: (context, number) => {
            const sessionsIdHold = context.getters.getPrimarySession.id;

            context.dispatch("sessionSetHold", {sessionid: sessionsIdHold, manually: false})

            context.dispatch("doCall", {number, silent: false, calleridId: context.rootGetters["user/calleridId"]})

            context.commit('updateInCall', true);
            context.commit('updateAddToCallState', true);
        },
        mergeCalls: (context) => {
            context.commit("updateConferenceCallState", true)
            context.commit('updateAddToCallState', false)
            context.dispatch("sessionUnhold", {sessionid: context.getters.getSecondSession.id, manually: false})

            // wait 1s while unhold event is processed
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('createConference'))
            }, 1000)
        },
        splitCalls: (context) => {
            document.dispatchEvent(new CustomEvent('splitConference'))
            context.dispatch("sessionSetHold", {sessionid: context.getters.getSecondSession.id, manually: false})
            context.commit('updateCallActiveID', context.getters.getPrimarySession.id)
            context.commit("updateConferenceCallState", false)
            context.commit('updateAddToCallState', true)
        },
        ctxSipStop: (context) => {
            const eventDisconnect = new CustomEvent("disconnectUA");
            document.dispatchEvent(eventDisconnect);
        },
        getMediaDevices: (context) => {
            return new Promise((resolve, reject) => {
                Api().get('/get_media_devices')
                    .then(response => {
                        context.commit('setMediaDevices', response.data)
                        resolve()
                    })
                    .catch(() => reject())
            })
        },
        updateMediaDevices: (context, mediaDevices) => {
            return new Promise((resolve, reject) => {
                Api().post('/update_media_devices', {
                    media_devices: mediaDevices
                }).then(response => {
                    context.commit('updateMediaDevices', mediaDevices)
                    resolve()
                }).catch(() => reject())
            })
        },
        record: (context) => {
            const session = context.getters.getPrimarySession

            document.dispatchEvent(new CustomEvent('sendDTMF', {"detail": { symbol: "#", sessionId: session.id }}))
            if (session && session.isRecording) {
                document.dispatchEvent(new CustomEvent('sendDTMF', {"detail": { symbol: "0", sessionId: session.id }}))
                context.commit("updateSessionIsRecording", {isRecording: false, sessionId: session.id})
            } else {
                document.dispatchEvent(new CustomEvent('sendDTMF', {"detail": { symbol: "1", sessionId: session.id }}))
                context.commit("updateSessionIsRecording", {isRecording: true, sessionId: session.id})
            }
        },
        closeIntegrationsPopup: (context) => {
            context.commit("setIntegrationsPopup", false)
        },
        showIntegrationsPopup: (context) => {
            context.commit("setIntegrationsPopup", true)
        },
        toggleIntegrationsPopup: (context) => {
            context.commit("setIntegrationsPopup", !context.getters.integrationsPopup)
        },
    },
    mutations: {
        updateCallStatus: (state, callStatus) => {
            state.status = callStatus;
        },
        updateSessionStatus: (state, {status, sessionid}) => {
            const session = state.sessions.find((sess) => sess.id === sessionid);
            session.status = status;
        },
        updateCallTime: (state, {sessionId, time, callDuration}) => {
            const session = state.sessions.find((sess) => sess.id === sessionId);
            if (session) {
                session.time = time;
                session.callDuration = callDuration
            }
        },
        updateCallIsMuted: (state, isMuted) => {
            state.isMuted = isMuted;
        },
        updateCallIsOnHold: (state, {isOnHold, sessionid, manually}) => {
            var session = state.sessions.find((sess) => sess.id === sessionid);

            session.isManuallySetOnHold = manually
            session.isOnHold = isOnHold;
        },
        updateSessionAccept: (state, {accept, sessionId}) => {
            const session = state.sessions.find((sess) => sess.id === sessionId);
            session.accept = accept;
        },
        updateSessionIsCanceled: (state, {isCanceled, sessionId}) => {
            const session = state.sessions.find((sess) => sess.id === sessionId)
            session.isCanceled = isCanceled
        },
        updateInCall: (state, inCall) => {
            state.inCall = inCall;
        },
        updateAttendedTransferState: (state, isAttendedTransfer) => {
            state.isAttendedTransfer = isAttendedTransfer;
        },
        updateAddToCallState: (state, isAddToCall) => {
            state.isAddToCall = isAddToCall;
        },
        updateConferenceCallState: (state, isConference) => {
            state.isConference = isConference;
        },
        updateCallActiveID: (state, callActiveId) => {
            state.callActiveID = callActiveId;
        },
        createCallSession: (state, {data, isRecording, callerIdNum = "", callerIdName = ""}) => {
            state.sessions.push(new Session({
                id: data.id,
                number: data.number,
                displayName: formatNumber(data.display_name),
                status: data.status,
                callerIdNum,
                callerIdName,
                direction: data.direction,
                isRecording: isRecording
            }));
        },
        deleteCallSession: (state, sessionId) => {
            state.sessions = state.sessions.filter((session) => session.id !== sessionId)
        },
        setNotificationEnable: (state, status) => {
            state.notificationEnable = status;
        },
        setCredentials: (state, credentials) => {
            state.credentials = credentials;
        },
        setIsRegistered: (state, status) => {
            state.isRegistered = status;
        },
        setIsUseOpenSips: (state, status) => {
            state.isUseOpenSips = status;
        },
        setElectronDialNumber: (state, number) => {
            state.electronDialNumber = number
        },
        setMediaDevices: (state, mediaDevices) => {
            state.mediaDevices = mediaDevices
        },
        updateMediaDevices: (state, mediaDevices) => {
            const all = [...state.mediaDevices, ...mediaDevices]
            const merged = {}
            all.forEach(mediaDevice => {
                Object.assign(merged[mediaDevice['device_type']] ?? (merged[mediaDevice['device_type']] = {}), mediaDevice)
            })
            state.mediaDevices = Object.values(merged)
        },
        updateSessionIsRecording: (state, {isRecording, sessionId}) => {
            const session = state.sessions.find((sess) => sess.id === sessionId)

            session.isRecording = isRecording
        },
        setIsCallRecordingAllowed: (state, isCallRecordingAllowed) => {
            state.isCallRecordingAllowed = isCallRecordingAllowed
        },
        updateSessionAnswerTimestamp: (state, {timestamp, sessionId}) => {
            const session = state.sessions.find((sess) => sess.id === sessionId)
            session.answerTimestamp = timestamp
        },
        setEnabledInboundAutoRecording: (state, status) => {
            state.enabledInboundAutoRecording = status
        },
        setEnabledOutboundAutoRecording: (state, status) => {
            state.enabledOutboundAutoRecording = status
        },
        updatePrevSymbol: (state, {prevSymbol, sessionId}) => {
            const session = state.sessions.find((sess) => sess.id === sessionId)
            session.prevSymbol = prevSymbol
        },
        setIntegrationsPopup: (state, value) => {
            state.integrationsPopup = value
        }
    },
    getters: {
        isInCall(state) {
            return state.inCall;
        },
        getStatus(state) {
            return state.status;
        },
        getIsMuted(state) {
            return state.isMuted;
        },
        getSessions(state) {
            return state.sessions;
        },
        getIsAttendedTransfer(state) {
            return state.isAttendedTransfer;
        },
        getIsAddCall(state) {
            return state.isAddToCall;
        },
        getIsConference(state) {
            return state.isConference;
        },
        getCallActiveID(state) {
            return state.callActiveID;
        },
        getIsNotificationEnabled(state) {
            return state.notificationEnable;
        },
        getIsUseOpenSips(state) {
            return state.isUseOpenSips;
        },
        getCredentials(state) {
            return state.credentials;
        },
        getSipLogin(state) {
            return state.credentials ? state.credentials.sip_login : null;
        },
        getIsRegistered(state) {
            return state.isRegistered;
        },
        getInitialSession(state) {
            return state.sessions[0];
        },
        getPrimarySession(state) {
            return state.callActiveID ? state.sessions.find((sess) => sess.id === state.callActiveID) : state.sessions[0];
        },
        getSecondSession(state) {
            return state.callActiveID ? state.sessions.find((sess) => sess.id !== state.callActiveID) : state.sessions[state.sessions.length - 1];
        },
        getActiveSession: (state) => {
            return state.sessions.find((sess) => !sess.isOnHold)
        },
        isOutgoing: (state) => {
            return state.inCall && state.status === STATUS_OUTGOING && state.sessions.length === 1 && state.sessions[0].status === STATUS_OUTGOING;
        },
        isMultipleOutgoing: (state) => {
            return state.inCall && state.status === STATUS_OUTGOING && state.sessions.length > 1 && state.sessions[1].status !== STATUS_INCOMING;
        },
        isIncoming: (state) => {
            return state.inCall && state.status === STATUS_INCOMING && state.sessions.length === 1 && state.sessions[0].status === STATUS_INCOMING;
        },
        isIncomingSecond: (state) => {
            return state.inCall && state.status === STATUS_ACTIVE && state.sessions.length > 1 && state.sessions[1].status === STATUS_INCOMING
        },
        isIncomingIncoming: (state) => {
            return state.inCall && state.status === STATUS_INCOMING && state.sessions.length > 1 && state.sessions[0].status === STATUS_INCOMING && state.sessions[1].status === STATUS_INCOMING
        },
        isActiveCall: (state) => {
            return state.inCall && state.status === STATUS_ACTIVE && state.sessions.length === 1;
        },
        isAttendedTransfer: (state) => {
            return state.inCall && state.status === STATUS_ACTIVE && state.isAttendedTransfer;
        },
        isActiveAddCall: (state) => {
            return state.inCall && state.status === STATUS_ACTIVE && state.isAddToCall;
        },
        isConference: (state) => {
            return state.inCall && state.status === STATUS_ACTIVE && state.isConference;
        },
        isOutgoingIncoming: (state) => {
            return state.inCall && state.status === STATUS_OUTGOING && state.sessions.length > 1 && state.sessions[1].status === STATUS_INCOMING
        },
        isActiveMultiple: (state) => {
            return state.inCall && state.status === STATUS_ACTIVE && state.sessions.length > 1 && state.sessions[0].status === STATUS_ACTIVE && state.sessions[1].status === STATUS_ACTIVE
        },
        getElectronDialNumber: (state) => {
            return state.electronDialNumber
        },
        isCallEstablished: (state) => {
            return state.status === STATUS_ACTIVE
        },
        mediaDevices: (state) => {
            return state.mediaDevices
        },
        getCallIsRecording: (state) => {
            return Boolean(state.sessions.find((sess) => sess.isRecording))
        },
        getIsCallRecordingAllowed: (state) => {
            return state.isCallRecordingAllowed
        },
        isEnabledInboundAutoRecording: (state) => {
            return state.enabledInboundAutoRecording
        },
        isEnabledOutboundAutoRecording: (state) => {
            return state.enabledOutboundAutoRecording
        },
        integrationsPopup: (state) => {
            return state.integrationsPopup
        }
    },
}