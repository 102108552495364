<template>
    <div class="softphone-dialpad">
        <div class="softphone-dialpad-row">
            <span class="caller-id-label">{{ $t("softphone-dial-pad.caller-id-display-as") }}</span>

            <caller-id-select
                @input="changeCallerId($event.id)"
                :options="calleridsOptions"
                :value="callerId"
            ></caller-id-select>

            <base-tooltip text="internal-number-label.hint">
                <template v-slot:activator>
                    <span class="internal-number-label">
                        {{ $t("internal-number-label.text") }} {{ extension }}
                    </span>
                </template>
            </base-tooltip>
        </div>

        <div class="softphone-dialpad-row">
            <dial-pad
                v-model="dialedNumber"
                @pressEnter="call"
                v-bind="dialPadSize"
                placeholder="softphone-dial-pad.placeholder"
            >
                <template v-slot:numberAppend="{ defocus }">
                    <dial-pad-action-button
                        v-if="showAddButton"
                        :size="breakpoints.isTabletOrSmMobile ? 40 : 48"
                        @click="defocus(); showAddContactPopup(dialedNumber)"
                        class="add-contact-btn"
                        icon="icon-person-add"
                        position="left"
                    ></dial-pad-action-button>

                    <span v-show="contactName" class="contact-name">{{ contactName }}</span>
                </template>
            </dial-pad>
        </div>

        <div class="softphone-dialpad-row">
            <softphone-do-call-button
                @click="call"
                :disabled="!getIsRegistered"
            ></softphone-do-call-button>

            <span v-if="connectLabel" class="connect-label">
                {{ $t("connecting") }}...
            </span>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CallerIdSelect from "./callerIdSelect/CallerIdSelect.vue";
import {formatNumber} from "../../utils/phoneNumbers";
import BaseButton from "../defaults/BaseButton.vue";
import DialPad from "./dialPad/DialPad.vue";
import {showAddContactPopup} from "../../utils/phonebooks";
import DialPadActionButton from "./dialPad/DialPadActionButton.vue";
import calleridsOptionsMixin from "../../mixins/calleridsOptionsMixin";
import breakpoints from "../../plugins/breakpoints";
import BaseTooltip from "../defaults/BaseTooltip.vue";
import SoftphoneDoCallButton from "./SoftphoneDoCallButton.vue";

export default {
    name: "SoftphoneDialPad",
    components: {
        SoftphoneDoCallButton,
        BaseTooltip,
        DialPadActionButton,
        DialPad,
        BaseButton,
        CallerIdSelect
    },
    mixins: [calleridsOptionsMixin],
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    data(){
        return {
            dialedNumber: this.value,
            contactName: '',
            showAddButton: false,
            connectLabel: false,
            breakpoints
        }
    },
    created() {
        if (this.dialedNumber) {
            this.findContact()
        }

        document.addEventListener("connectionStart", this.showConnectLabel)

        document.addEventListener("registerSuccess", this.hideConnectLabel)
        document.addEventListener("connectionError", this.hideConnectLabel)
    },
    beforeDestroy() {
        document.removeEventListener("connectionStart", this.showConnectLabel)

        document.removeEventListener("registerSuccess", this.hideConnectLabel)
        document.removeEventListener("connectionError", this.hideConnectLabel)
    },
    computed: {
        ...mapGetters('softphone', ['getIsRegistered']),

        ...mapGetters('user', ['user', 'calleridId', 'extension']),

        ...mapGetters('details', ['callHistory', 'getContactByNumber', 'getDefaultPhonebook', 'defaultContact', 'contacts']),

        callerId() {
            if (!this.calleridId) {
                return this.anonymousItem
            }

            return this.calleridsOptions.find((callerid) => callerid.id === this.calleridId)
        },

        dialPadSize() {
            if (breakpoints.isTabletOrSmMobile) {
                return {inputHeight: 48, buttonSize: 48, buttonFontSize: 20, keyboardHeight: 228, keyboardWidth: "80%"}
            }

            return {inputHeight: 60, buttonSize: 64, buttonFontSize: 24, keyboardHeight: 304, keyboardWidth: "100%"}
        }
    },
    watch: {
        dialedNumber(value) {
            this.findContact()
            this.$emit('input', value)
        },
        contacts() {
            this.findContact()
        },
        value(value) {
            this.dialedNumber = value
        }
    },
    methods: {
        ...mapActions('user', ['changeCallerId']),

        call() {
            if (this.dialedNumber) {
                this.$emit('call', this.dialedNumber);
            } else {
                const firstEntry = this.callHistory[0]
                if (firstEntry && firstEntry.number !== 'anonymous') {
                    this.dialedNumber = formatNumber(firstEntry.number)
                }
            }
        },

        findContact() {
            const contact = this.getContactByNumber(this.dialedNumber)

            if (!contact || !contact.id) {
                this.showAddButton = true
                this.contactName = ""
            } else {
                this.showAddButton = false
                this.contactName = contact.name
            }

            if (!this.dialedNumber) {
                this.showAddButton = false
            }
        },

        showConnectLabel() {
            console.log("HERE")
            this.connectLabel = true
        },

        hideConnectLabel() {
            this.connectLabel = false
        },

        showAddContactPopup
    }
}
</script>