<template>
    <softphone-state-default>
        <template v-slot:top>
            <person-collapsed
                :session="getPrimarySession"
            ></person-collapsed>

            <call-status-label :top-offset="0" animated>
                {{ $t("softphone-state-incoming.call-status") }}
            </call-status-label>
        </template>

        <template v-slot:center>
            <person :session="getSecondSession"></person>
        </template>

        <template v-slot:call_control_button_col-1>
            <call-controls-cancel-button
                @click="rejectIncomingCall"
                :label="$t('softphone-state-incoming.buttons.decline')"
            ></call-controls-cancel-button>
        </template>

        <template v-slot:call_control_button_col-3>
            <call-controls-call-button
                @click="acceptIncomingCall"
                :label="$t('softphone-state-incoming.buttons.accept')"
            ></call-controls-call-button>
        </template>
    </softphone-state-default>
</template>

<script>
import PersonCollapsed from "../../person/PersonCollapsed.vue";
import {mapActions, mapGetters} from "vuex";
import Person from "../../person/Person.vue";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import CallStatusLabel from "../../CallStatusLabel.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallControlsCallButton from "../../softphoneButtons/callControls/CallControlsCallButton.vue";

export default {
    name: "SoftphoneStateOutgoingIncoming",
    components: {
        CallControlsCallButton,
        CallControlsCancelButton,
        CallStatusLabel,
        SoftphoneStateDefault,
        Person,
        PersonCollapsed
    },
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession',
            'getSessions'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'acceptCall',
        ]),

        rejectIncomingCall() {
            this.hangUp(this.getSecondSession.id)
        },

        acceptIncomingCall() {
            this.hangUp(this.getPrimarySession.id)

            // TODO: fix this
            setTimeout(() => {
                this.acceptCall(this.getSecondSession.id)
            }, 300)
        }
    }
}
</script>

<style scoped>

</style>