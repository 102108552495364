import { render, staticRenderFns } from "./AppsDropdown.vue?vue&type=template&id=4bbe8ff3&scoped=true&"
import script from "./AppsDropdown.vue?vue&type=script&lang=js&"
export * from "./AppsDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbe8ff3",
  null
  
)

export default component.exports