/**
 * Start downloading file
 *
 * @param {string} url
 * @param {string} filename
 */
const downloadFile = (url, filename) => {
    const a = document.createElement('a')
    a.href = url
    a.download = filename

    a.click()
    a.remove()
}

/**
 * Reload page
 */
const reloadPage = () => {
    window.location.reload();
}

/**
 * Redirect by click
 *
 * @param {String} url
 * @param {String} target
 */
const redirectByClick = (url, target = '_blank') => {
    const a = document.createElement('a')
    a.target = target
    a.href = url

    a.click()
    a.remove()
}

/**
 * Function checks whether the device is touch
 *
 * @return {boolean}
 */
const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0))
}

export { downloadFile, reloadPage, redirectByClick, isTouchDevice }