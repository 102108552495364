const callSettingsButtonMixin = {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    }
}

export default callSettingsButtonMixin