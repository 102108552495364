<template>
    <call-controls-button
        @click="$emit('click')"
        :disabled="disabled"
        :label="label"
        :size="size"
        color="green"
        icon="icon-call"
    ></call-controls-button>
</template>

<script>
import CallControlsButton from "./CallControlsButton.vue";

export default {
    name: "CallControlsCallButton",
    components: {CallControlsButton},
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "default"
        }
    }
}
</script>

<style scoped>

</style>