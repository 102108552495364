import { render, staticRenderFns } from "./Voicemails.vue?vue&type=template&id=481283f0&scoped=true&"
import script from "./Voicemails.vue?vue&type=script&lang=js&"
export * from "./Voicemails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481283f0",
  null
  
)

export default component.exports