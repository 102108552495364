<template>
    <active-call-keypad-tab
        @input="onSelect"
        :value="value"
        :search-callback="searchCallback"
        :items="phonebookContacts"
        no-items-text="active-call-keypad.no-contacts"
        class="active-call-keypad-phonebook-tab"
    >
        <template v-slot:beforeList>
            <phonebook-select
                v-model="selectedPhonebookId"
            ></phonebook-select>
        </template>

        <template v-slot:item="{item}">
            <contact-speed-dial-list-item
                :item="item"
                :key="item.id"
            ></contact-speed-dial-list-item>
        </template>
    </active-call-keypad-tab>
</template>

<script>
import ContactSpeedDialListItem from "./ContactSpeedDialListItem.vue";
import {mapGetters} from "vuex";
import PhonebookSelect from "./phonebookSelect/phonebookSelect.vue";
import ActiveCallKeypadTab from "../ActiveCallKeypadTab.vue";
import {findInContact} from "../../../../../../utils/phonebooks";

export default {
    name: "PhonebookTab",
    components: {PhonebookSelect, ContactSpeedDialListItem, ActiveCallKeypadTab},
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            selectedPhonebookId: null,
        }
    },
    created() {
        this.selectedPhonebookId = this.defaultPhonebookViewId
    },
    computed: {
        ...mapGetters("user", ["defaultPhonebookViewId"]),
        ...mapGetters("details", ["contacts"]),

        phonebookContacts() {
            if (this.selectedPhonebookId === "all") {
                return this.contacts
            }

            return this.contacts.filter(contact => contact.phonebook_id === this.selectedPhonebookId)
        }
    },
    methods: {
        searchCallback(searchString, item) {
            return findInContact(searchString, item)
        },
        onSelect(number) {
            this.$emit("input", number)
        }
    }
}
</script>

<style scoped>

</style>