<template>
    <softphone-state-default>
        <template v-slot:top>
            <person-collapsed
                v-if="showDtmfKeypad"
                :session="getPrimarySession"
            ></person-collapsed>

            <container-persons-collapsed v-else>
                <person-collapsed
                    v-for="session in getSessions"
                    :key="session.id"
                    :session="session"
                ></person-collapsed>
            </container-persons-collapsed>
        </template>

        <template v-slot:center>
            <dtmf-dial-pad
                v-if="showDtmfKeypad"
                v-model="dtmfModel"
                @typedSymbol="sendDTMF"
            ></dtmf-dial-pad>

            <container-call-settings-button v-else>
                <!-- First row -->
                <template v-slot:first-row>
                    <call-settings-mute-button
                        @click="mute"
                        :active="getIsMuted"
                    ></call-settings-mute-button>

                    <!-- Buttons for attended transfer -->
                    <template v-if="isAttendedTransfer">
                        <call-settings-hold-button
                            @click="hold"
                            :active="getPrimarySession.isOnHold"
                        ></call-settings-hold-button>

                        <call-settings-add-call-button disabled></call-settings-add-call-button>
                    </template>

                    <!-- Buttons for add call -->
                    <template v-if="isActiveAddCall">
                        <call-settings-swap-button
                            @click="swapHoldSession"
                        ></call-settings-swap-button>

                        <call-settings-merge-button
                            @click="mergeCalls"
                        ></call-settings-merge-button>
                    </template>

                    <!-- Buttons for conference -->
                    <template v-if="isConference">
                        <call-settings-swap-button disabled></call-settings-swap-button>

                        <call-settings-split-button
                            @click="splitCalls"
                        ></call-settings-split-button>
                    </template>
                </template>
                <!-- First row end -->

                <!-- Second row -->
                <template v-slot:second-row>
                    <call-settings-keypad-button
                        @click="toggleDtmfKeypad"
                        :disabled="isConference"
                    ></call-settings-keypad-button>

                    <call-settings-record-button
                        @click="record"
                        :disabled="!getIsCallRecordingAllowed"
                        :active="getPrimarySession.isRecording"
                    ></call-settings-record-button>

                    <call-settings-transfer-button
                        @click="attendedTransferCall"
                        :disabled="isActiveAddCall || isConference"
                        :class="{'softphone-state-active-multiple-transfer-button-colored': isAttendedTransfer}"
                    ></call-settings-transfer-button>
                </template>
                <!-- Second row end -->
            </container-call-settings-button>
        </template>

        <template v-slot:call_control_button_col-2>
            <call-controls-cancel-button
                @click="onHangupClick"
            ></call-controls-cancel-button>
        </template>

        <template v-slot:call_control_button_col-3>
            <call-settings-back-button
                v-if="showDtmfKeypad"
                @click="toggleDtmfKeypad"
            ></call-settings-back-button>
        </template>
    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PersonCollapsed from "../../person/PersonCollapsed.vue";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import ContainerPersonsCollapsed from "../../container/ContainerPersonsCollapsed.vue";
import ContainerCallSettingsButton from "../../container/ContainerCallSettingsButton.vue";
import CallSettingsMuteButton from "../../softphoneButtons/callSettings/CallSettingsMuteButton.vue";
import CallSettingsSwapButton from "../../softphoneButtons/callSettings/CallSettingsSwapButton.vue";
import CallSettingsSplitButton from "../../softphoneButtons/callSettings/CallSettingsSplitButton.vue";
import CallSettingsKeypadButton from "../../softphoneButtons/callSettings/CallSettingsKeypadButton.vue";
import CallSettingsRecordButton from "../../softphoneButtons/callSettings/CallSettingsRecordButton.vue";
import CallSettingsTransferButton from "../../softphoneButtons/callSettings/CallSettingsTransferButton.vue";
import CallSettingsMergeButton from "../../softphoneButtons/callSettings/CallSettingsMergeButton.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallSettingsHoldButton from "../../softphoneButtons/callSettings/CallSettingsHoldButton.vue";
import CallSettingsAddCallButton from "../../softphoneButtons/callSettings/CallSettingsAddCallButton.vue";
import dtmfMixin from "../../../../../mixins/softphone/dtmfMixin";
import DtmfDialPad from "../../DtmfDialPad.vue";
import CallSettingsBackButton from "../../softphoneButtons/callSettings/CallSettingsBackButton.vue";

export default {
    name: "SoftphoneStateActiveMultiple",
    mixins: [dtmfMixin],
    components: {
        CallSettingsBackButton,
        DtmfDialPad,
        CallSettingsAddCallButton,
        CallSettingsHoldButton,
        CallControlsCancelButton,
        CallSettingsMergeButton,
        CallSettingsTransferButton,
        CallSettingsRecordButton,
        CallSettingsKeypadButton,
        CallSettingsSplitButton,
        CallSettingsSwapButton,
        CallSettingsMuteButton,
        ContainerCallSettingsButton,
        ContainerPersonsCollapsed,
        SoftphoneStateDefault,
        PersonCollapsed
    },
    computed: {
        ...mapGetters('softphone', [
            'getIsMuted',
            'getSessions',
            'getPrimarySession',
            'getIsCallRecordingAllowed',
            'getActiveSession',
            'isActiveAddCall',
            'isConference',
            'isAttendedTransfer'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'mute',
            'swapHoldSession',
            'hangUp',
            'mergeCalls',
            'record',
            'hold',
            'attendedTransferCall',
            'splitCalls',
            'hangUpConference'
        ]),

        onHangupClick() {
            if (this.isConference) {
                this.hangUpConference()
                return
            }

            this.hangUp(this.getPrimarySession.id)
        }
    }
}
</script>

<style scoped>

</style>