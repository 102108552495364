const DIAL_PAD_COLOR = Object.freeze({
    LIGHT: "color_light",
    DARK: "color_dark"
})

const KEYBOARD_SIGNS_MAP = Object.freeze({
    "1": "&#8203;",
    "2": "ABC",
    "3": "DEF",
    "4": "GHI",
    "5": "JKL",
    "6": "MNO",
    "7": "PQRS",
    "8": "TUV",
    "9": "WXYZ",
    "*": "",
    "0": "+",
    "#": ""
})

export {DIAL_PAD_COLOR, KEYBOARD_SIGNS_MAP}